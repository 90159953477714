import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import userss from '../Images/profile.png'
import { User } from "react-feather"
const Map = ({ callback = (f) => f, setCurrent = (f) => f, form = {}, setForm = (f) => f, polygons = {}, points = [], zoom = 5, height = `70vh` }) => {
  const geom = polygons.features && polygons.features.length ? polygons.features : []

  const features = geom.map((p, idx) =>
    p.geometry.coordinates.map(c =>
      c.map(c => ({ lat: c[1], lng: c[0], color: p.color, index: idx }))
    )
  )

  const GoogleMapReact = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDf-yIqxErTkbWzKhLox7nAANnrfDIY190",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(() => <>
    {/* {JSON.stringify({ polygons })} */}
    <GoogleMap defaultZoom={zoom} defaultCenter={{ lat: parseFloat(form.latitude), lng: parseFloat(form.longitude) }}>
      {features.map((feature, idx) => <Polygon
        onClick={() => { setCurrent(polygons?.features[feature[0][0]?.index]); callback(); }}
        paths={feature}
        key={idx}
        options={{
          fillOpacity: 0.4,
          strokeColor: feature[0][0]?.color ? feature[0][0]?.color : "#F00",
          strokeOpacity: 1,
          strokeWeight: 1
        }} />)}
      {points && points.length > 0 ? points.map((point, i) =>
        <Marker
          key={i}
          icon={{src:<User />  }}
          onClick={() => { setCurrent(point); callback(); }}
          position={{ lat: point.latitude?parseFloat(point.latitude):8.54677, lng: point.longitude?parseFloat(point.longitude):12.7555 }}
        />) : null}
    </GoogleMap>
  </>
  )
  return <GoogleMapReact />
}
export default Map;