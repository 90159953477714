import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaArrowAltCircleLeft, FaCalendar, FaMoneyBill, FaPhone, FaTrash, FaUser } from 'react-icons/fa';
import { MdMapsHomeWork } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Card, Col, Row, Table } from 'reactstrap'
import { apiURL, _postApi } from '../helpers/helper';
import { CustomButton } from '../UI';
import CustomScrollbar from '../UI/CustomScrollbar';

function HazardComing() {
  const [general, setGeneral] = useState([])
  const getGeneral = () => {
    _postApi(
      `/general?query_type=select`,
      // form,
      {},
      (data) => {
        if (data.success) {
          setGeneral(data.results)
        }
      },
    )
    // )
  };
  useEffect(() => {

    getGeneral()
  }, []);
  const navigate = useNavigate()
  return (
    <div className='mt-4'>
      <CustomButton onClick={() => navigate(-1)}><FaArrowAltCircleLeft /></CustomButton>
      <Card className='admin-card p-3'>
        <h4>Anonymous Report</h4>
        <CustomScrollbar>

          <Table bordered>
            <thead>
              <tr>
                <td>S/N</td>
                <td>Location Longitude</td>
                <td>Location Latitude</td>
                <td>Report Type</td>
                <td>Description</td>
                <td>Date</td>
              </tr>
            </thead>
            <tbody>
              {
                general && general.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.longitude}</td>
                    <td>{item.latitude}</td>
                    <td>{item.disaster_type}</td>
                    <td>{item.description}</td>
                    <td>{item.created_at}</td>
                  </tr>
                ))
              }</tbody>
          </Table>
        </CustomScrollbar>

      </Card>
    </div>
  );
}

export default HazardComing