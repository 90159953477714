export class DynamicSwitchRenderer {
    constructor(options, broadcastData = false) {
        this.options = options;
        this.broadcastData = broadcastData;
        this.map = null;
        this.gl = null;
        this.currentOption = null;
        this.dataOperations = {
            add: (element) => {
                if (this.broadcastData) {
                    for (const option of this.options) {
                        option.renderer.dataOperations.add(element);
                    }
                }
                else if (this.currentOption != null) {
                    this.currentOption.renderer.dataOperations.add(element);
                }
                this.handleDataChange();
            },
            removeFirst: () => {
                let removed = null;
                if (this.broadcastData) {
                    for (const option of this.options) {
                        removed = option.renderer.dataOperations.removeFirst();
                    }
                }
                else if (this.currentOption != null) {
                    removed = this.currentOption.renderer.dataOperations.removeFirst();
                }
                this.handleDataChange();
                return removed;
            },
            removeLast: () => {
                let removed = null;
                if (this.broadcastData) {
                    for (const option of this.options) {
                        removed = option.renderer.dataOperations.removeLast();
                    }
                }
                else if (this.currentOption != null) {
                    removed = this.currentOption.renderer.dataOperations.removeLast();
                }
                this.handleDataChange();
                return removed;
            },
            clear: () => {
                if (this.broadcastData) {
                    for (const option of this.options) {
                        option.renderer.dataOperations.clear();
                    }
                }
                else if (this.currentOption != null) {
                    this.currentOption.renderer.dataOperations.clear();
                }
                this.handleDataChange();
            },
            getArray: () => {
                if (this.currentOption != null) {
                    return this.currentOption.renderer.dataOperations.getArray();
                }
                return [];
            },
            addAll: (elements) => {
                if (this.broadcastData) {
                    for (const option of this.options) {
                        option.renderer.dataOperations.addAll(elements);
                    }
                }
                else if (this.currentOption != null) {
                    this.currentOption.renderer.dataOperations.addAll(elements);
                }
                this.handleDataChange();
            },
            removeNFirst: (n) => {
                let removed = null;
                if (this.broadcastData) {
                    for (const option of this.options) {
                        removed = option.renderer.dataOperations.removeNFirst(n);
                    }
                }
                else if (this.currentOption != null) {
                    removed = this.currentOption.renderer.dataOperations.removeNFirst(n);
                }
                this.handleDataChange();
                return removed != null ? removed : [];
            },
            removeNLast: (n) => {
                let removed = null;
                if (this.broadcastData) {
                    for (const option of this.options) {
                        removed = option.renderer.dataOperations.removeNLast(n);
                    }
                }
                else if (this.currentOption != null) {
                    removed = this.currentOption.renderer.dataOperations.removeNLast(n);
                }
                this.handleDataChange();
                return removed != null ? removed : [];
            }
        };
        this.handleDataChange();
    }
    setStyle(styleOption) {
        for (const option of this.options) {
            option.renderer.setStyle(styleOption);
        }
    }
    initialise(map, gl) {
        if (this.currentOption != null) {
            this.currentOption.renderer.initialise(map, gl);
        }
        this.map = map;
        this.gl = gl;
    }
    dispose(map, gl) {
        this.gl = null;
        this.map = null;
        if (this.currentOption != null) {
            this.currentOption.renderer.dispose(map, gl);
        }
    }
    prerender(gl, matrix) {
        if (this.currentOption != null) {
            this.currentOption.renderer.prerender(gl, matrix);
        }
    }
    render(gl, matrix) {
        if (this.currentOption != null) {
            this.currentOption.renderer.render(gl, matrix);
        }
    }
    handleDataChange() {
        const currentOption = this.currentOption;
        if (currentOption != null && currentOption.condition(currentOption.renderer.dataOperations)) {
            return;
        }
        const newOption = this.options.find(option => option.condition(option.renderer.dataOperations));
        if (newOption === currentOption) {
            return;
        }
        if (this.map != null && this.gl != null) {
            if (currentOption != null) {
                currentOption.renderer.dispose(this.map, this.gl);
            }
            if (newOption != null) {
                newOption.renderer.initialise(this.map, this.gl);
            }
        }
        this.currentOption = newOption != null ? newOption : null;
    }
}
