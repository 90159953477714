import React, { useEffect, useState } from 'react'
import { BiChevronRight } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import { Row, Col } from 'reactstrap'
import { login } from '../../redux/actions/auth'
import { useDispatch } from "react-redux";
import { createUser } from "../../redux/actions/auth";
// import { _post } from '../utils/Helper'
import { _fetchApi, _postApi } from '../../helpers/helper'
import './Register.css'

export default function Registration() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [signUp, setSignup] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    org_type: "",
    org_name: ""
  });
  const [roles, setRoles] = useState([]);
  const handleChange = ({ target: { name, value } }) => {
    setSignup((p) => ({ ...p, [name]: value }));
  };
  const dispatch = useDispatch();

  const _getRoles = () => {
    _fetchApi(
      `/users/get-roles`,
      (s) => {
        setRoles(s.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleSubmit = () => {
    setLoading(true)
    dispatch(
      createUser(
        signUp,
        () => {
          setLoading(false)
          navigate("/app/dashboard");
          // alert("success");
        },
        (e) => {
          setLoading(false)
          console.log(e.error);
          alert("An Error Occured");
        }
      )
    );
  };
  useEffect(() => {
    _getRoles();
  });

  return (
    <div>
      <div className="sign-in-body">
        <div >
          {/* {JSON.stringify(errors)} */}
          <>
            <Row>
              {/* <Col md={1}></Col> */}

              <Col md={6}>
                <div className="">
                  <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                    {/* {errors.name} */}
                  </p>
                  <label className='label_'>Organization Name</label>
                  <input
                    className="mb-4 input_field p-2"
                    type="text"
                    id="org_name"
                    name="org_name"
                    value={signUp.org_name}
                    onChange={handleChange}
                  // placeholder="Organization Name"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="">
                  <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                    {/* {errors.phone1} */}
                  </p>
                  <label className='label_'>Organization Type</label>
                  <select
                    className="mb-4 input_field p-2"

                    name="org_type"
                    value={signUp.org_type}
                    onChange={handleChange}
                  >
                    <option>--select--</option>
                    <option value={'Govemental Org'}>Govt. Organization</option>
                    <option value={'NGO'}>NGO</option>
                    <option value={'Private'}>Private Organization</option>
                    {/* <option>--select--</option> */}
                  </select>
                </div>
              </Col>

              {/* <div className="">
                    <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                    
                    </p>
                    <label className='label_'>Role</label>
                    <select
                      className="mb-4 input_field p-2"

                      name="role"
                      value={signUp.role}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {roles.map((item, id) => (
                        <option key={id}>{item.name}</option>
                      ))}
                    </select>
                  </div> */}
              <Col md={6}>

                <div className="">
                  <p style={{ color: 'red', fontSize: 12, margin: 0 }}>
                    {/* {errors.password} */}
                  </p>
                  <label className='label_'>Organization Email</label>
                  <input
                    type="email"
                    className="mb-4 input_field p-2"
                    // placeholder="Enter email"

                    name="email"
                    value={signUp.email}
                    onChange={handleChange}
                  />

                </div>
              </Col>
              <Col md={6}>


                <div className="">
                  <label className='label_'>Organization Phone Number</label>
                  <input
                    type="text"
                    className="mb-4 input_field p-2"
                    // placeholder="Enter number"
                    name="phone"
                    value={signUp.phone}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md={6}>

                <div className="">
                  <label className='label_'>Organization Address</label>
                  <input
                    type="text-area"
                    className="mb-4 input_field p-2"
                    // placeholder="Enter Address"
                    name="address"
                    value={signUp.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="">
                  <label className='label_'>Organization Username</label>
                  <input
                    type="username"
                    className="mb-4 input_field p-2"
                    // placeholder="Username"
                    name="username"
                    value={signUp.username}
                    onChange={handleChange}
                  />
                </div>

              </Col>
              <Col md={6}>
                <div className="">
                  <label className='label_'>Organization Password</label>
                  <input
                    type="password"
                    className="mb-4 input_field p-2"
                    // placeholder="Enter Address"
                    name="password"
                    value={signUp.password}
                    onChange={handleChange}
                  />
                </div>

              </Col>


              <div>
                <button
                  type="submit"
                  className="primary_button"
                  style={{ width: '100%' }}
                  onClick={handleSubmit}
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <span>Loading...</span>
                  ) : (
                    <span>
                      Register
                      <BiChevronRight size={20} />
                    </span>
                  )}
                </button>
              </div>

            </Row>
          </>
        </div>
      </div>
    </div>
  )
}
