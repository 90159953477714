import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Table } from 'reactstrap'
import { HiOutlinePencil, HiDownload } from 'react-icons/hi'
import { MdSend, MdCancelScheduleSend } from 'react-icons/md'
import { Modal } from 'reactstrap'
import { BsCheckLg, BsX } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { CSVLink } from "react-csv";

import moment from 'moment'
import { _fetchApi } from '../helpers/helper'
import { ArrowLeft } from 'react-feather'
export default function SentMessages() {
    const [open, setOpen] = useState(false)
    const navigate=useNavigate()
    const toggle = () => {
        setOpen(!open)
    }
    const { user } = useSelector((state) => state.auth)
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchMessage, setSearchMessage] = useState('')

    const goto = useNavigate()
    const messageCard = [
        {
            title: 'Sent Messages',
        },
        {
            title: 'Delivered Messages',
        },
        {
            title: 'Failed Messages',
        },
    ]
    const [showTable, setShowTable] = useState(false)
    const [logse, setLogse] = useState([])


    const getLogs = useCallback(() => {
        _fetchApi(`/messages/getlogs?org_id=${user?.org_id}`, (resp) => {
            setLogse(resp.results)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        getLogs()

    }, [user?.org_id])
    
    const faileds = logse?.filter((i)=>i.message_status === 'failed')
    const delivered = logse?.filter((i)=>i.message_status === 'delivered')

    const search = []
        delivered&&
            delivered.forEach((item) => {
                if (item.msg_name.toLowerCase().indexOf(searchMessage.toLowerCase()) === -1 
                || item.msg_body.toLowerCase().indexOf(searchMessage.toLowerCase()) === -1 )
                    return;
                    search.push(item)
            })
    
    return (
        <div>
            {/* {JSON.stringify(faileds)}
            {JSON.stringify(delivered)} */}
            <Card className="dashboard_card m-3 shadow-sm p-4">
                <Row>
                    <Col md={6}>
                        <h4 className="card_title mb-4"><ArrowLeft className='back_' onClick={()=>navigate(-1)}/>{' '}Sent Messages</h4>
                    </Col>
                    <Col md={6}>
                        {/* <button
              className="primary_button"
              onClick={() => goto('/send-message')}
              style={{ float: 'right' }}
            >
              Create message
            </button> */}
                    </Col>
                </Row>
                <div className="buttons_div">
                    <button
                        style={{ marginRight: 10 }}
                        className="message_button"
                        onClick={() => goto('/app/create-message')}
                    >
                        <HiOutlinePencil size="1.2rem" /> Compose message
                    </button>
                    <button
                        style={{ marginRight: 10, float: 'right' }}
                        className="message_button"
                    >
                        <CSVLink
                            data={delivered}
                            className='csv_link'
                            filename={"Delivered Messages Data"}
                        >
                            <HiDownload size="1.2rem" /> Download messages
                        </CSVLink>
                    </button>
                    {/* <button
                        style={{ marginRight: 10 }}
                        className="message_button"

                    >
                        <BsCheckLg size="1.2rem" /> Total Sent Messages <span style={{ fontWeight: 'bold' }}>(2)</span>
                    </button>
                    <button
                        className="message_button"

                    >
                        <BsX size="1.2rem" /> Total Failed Messages <span style={{ fontWeight: 'bold' }}>(2)</span>
                    </button> */}
                </div>
                <input 
                    type='search' 
                    className='input_field p-2 mt-3' 
                    placeholder='Search message'
                    value={searchMessage}
                    onChange= {(e) => setSearchMessage(e.target.value)}
                />
                <Row className="mt-3">
                    {!showTable ? (
                        <Table striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Date/Time</th>
                                    <th>Title</th>
                                    <th>Message Body</th>
                                    {/* <th>Total Subscribers</th> */}
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {loading ? (
                                <sapn style={{fontSize: 22}}>Loading messages...</sapn>
                            ) : (
                                <tbody>
                                    {search.map((item, index) => (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{moment(item.date).format('LLL')}</td>
                                            <td>{item.msg_name}</td>
                                            <td>{item.msg_body}</td>
                                            {/* <td>{item.count}</td> */}
                                            <td>Delivered</td>
                                            <td><a onClick={toggle}>view</a></td>
                                            {/* <td>Table cell</td> */}

                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </Table>
                    ) : (
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                </Row>

                <Modal isOpen={open} toggle={toggle}>
                    <audio controls>
                        <source src={'audio'} type="audio/mpeg" />
                    </audio>
                    <video controls>
                        <source src={'video'} style={{ Height: "30px", width: '30%' }} />
                    </video>
                </Modal>
            </Card>
        </div>
    )
}
