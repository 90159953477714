import { useEffect, useCallback, useRef, useState, memo } from "react";

import ReactMapboxGl, { ZoomControl, ScaleControl, Marker } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { MdLocationPin } from 'react-icons/md'
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import './blink.css'
import { PolygonLayer } from "react-mapbox-gl-performance-layers";
// import TooltipItem from "../UI/TooltipItem";
import CustomModal from "../UI/CustomModal";
import { Col, Row } from "reactstrap";

const dataLayer = {
  id: 'data',
  type: 'border',
  paint: {
    'border-color': '#ff0000',
    'fill-opacity': 0.8
  }
}

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiaWJhZ3dhaTkiLCJhIjoiY2tweTMxODZ5MWNoZzJxcDl0ODI0dXJtdyJ9.-igEXVpIFBVzefkT89oscQ'
});

function ReactMap({ form = {}, setCurrent = (f) => f, setForm = (f) => f, polygons = {}, points = [], height = '70vh' }) {
  const [area, setArea] = useState(null)
  const [zoom, setZoom] = useState([5.5])
  const [feature, setFeature] = useState({})

  const onDrawCreate = ({ features }) => {
    let k = features[0].geometry.coordinates;
    setArea(k)
  };

  const mapRef = useRef()

  const onDrawUpdate = ({ features }) => {
    console.log(features);
  };

  const onControlClick = (e, zoomDiff) => {
    console.log('onControlClick', e);
    setZoom([e.getZoom() + zoomDiff, 8]);
  }

  const chageArea = useCallback(() => {
    let str = [];
    if (area && area.length && area[0].length) {
      str = area[0][0].length ?
        area[0].map((data) => [
          data.toString().replace(",", " "),
        ]) : area.map((data) => [
          data.toString().replace(",", " "),
        ]);
      setForm((p) => ({ ...p, geometry: [str.join(",")] }))
    } else {
      if (area && area.length) {
        console.error({ area });
        setForm((p) => ({ ...p, geometry: area.join(",").split(',') }))
      }
    }
    setArea(area)
  }, [area])

  useEffect(() => {
    chageArea()
  }, [chageArea])

  const selectFeature = useCallback((idx) => {
    const dt = polygons.features[idx];
    console.error(dt);
    setFeature(dt);
    toggle()
  })
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <div>
      {/* {JSON.stringify(polygons)} */}
      <Map
        id="map"
        ref={mapRef}
        style="mapbox://styles/mapbox/streets-v11"
        containerStyle={{
          height,
          width: '100%',
        }}
        center={[form.latitude, form.longitude]}
        zoom={form.zoom ? form.zoom : zoom}
      >
        {polygons.features && polygons.features.length ? polygons.features.map((ft, idx) => <>
          <CustomModal
            size='sm'
            placement='top'
            id={`tip-${idx}`}
            isOpen={isOpen}
            toggle={toggle}
          >
            {feature.properties && Object.keys(feature.properties).map((key, i) => <Row key={i}><Col>{key}:</Col> <Col style={{ textAlign: 'right' }}>{feature.properties[key]}</Col></Row>)}
          </CustomModal>
          <span id={`tip-${idx}`}>
            <PolygonLayer
              onClick={() => { selectFeature(idx); setCurrent(ft) }}
              data={{ features: [ft] }}
              style={dataLayer}
            /></span>
        </>) : null}
        <ZoomControl showCompass={true} onControlClick={onControlClick} position='bottom-right' />
        {form.edit_map ? <DrawControl
          position='top-right'
          onDrawCreate={onDrawCreate} onDrawUpdate={onDrawUpdate} /> : null}
        <ScaleControl position='bottom-left' />
        {points.map((i, idx) => (
          <>{
            i.longitude ? <Marker
              key={idx}
              coordinates={[i.longitude, i.latitude]}
              anchor="bottom"
            >
              <span className="blink">
                <MdLocationPin size={30} color="#f00" />
              </span>
            </Marker> : null
          }</>
        ))}
      </Map>
    </div>
  );
}
export default memo(ReactMap)