import {
  LOGIN,
  LOADING,
  ERROR,
  LOGOUT,
  CREATING_USER,
  ERROR_CREATING_USER,
  GET_ROLES,
  GET_USERS,
} from "../types";
// import { persistentDocumentReducer } from 'redux-pouchdb';

// import { db } from '../store';

const defaultState = {
  authenticated: false,
  user: {},
  token: null,
  error: "",
  ids: [],
  loadingAvatar: false,
  creatingUser: false,
  createUserError: "",
  gettingDoctors: false,
  roles: [],
  users: [],
  approvedDoctors: [],
  loadingApprovedDoc: false,
  fullPageLoading: false,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN: {
      return Object.assign({}, state, {
        authenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        error: "",
      });
    }

    case LOGOUT: {
      return {
        ...state,
        authenticated: false,
        user: {},
        token: null,
      };
    }

    case ERROR: {
      const { payload } = action;
      return Object.assign({}, state, {
        error: payload,
      });
    }

    case LOADING: {
      return Object.assign({}, state, {
        loading: !state.loading,
      });
    }

    case CREATING_USER:
      return {
        ...state,
        creatingUser: !state.creatingUser,
      };

    case ERROR_CREATING_USER:
      return {
        ...state,
        creatingUser: false,
        createUserError: action.payload,
      };

    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
// const reducerName = 'auth';

// export default persistentDocumentReducer(db, reducerName)(authReducer);
