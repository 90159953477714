import vertexSource from './fancy-point.vert';
import fragmentSource from './fancy-point.frag';
export class FancyPointShader {
    constructor(interpolation = 1.8) {
        this.interpolation = interpolation;
        this.vertexSource = vertexSource;
        this.fragmentSource = fragmentSource;
    }
    configureAttributes(gl, program) {
        const position = gl.getAttribLocation(program, 'a_position');
        const size = gl.getAttribLocation(program, 'a_size');
        const color = gl.getAttribLocation(program, 'a_color');
        const outlineSize = gl.getAttribLocation(program, 'a_outlineSize');
        const outlineColor = gl.getAttribLocation(program, 'a_outlineColor');
        const vertexSize = 12 * Float32Array.BYTES_PER_ELEMENT;
        gl.vertexAttribPointer(position, 2, gl.FLOAT, false, vertexSize, 0);
        gl.vertexAttribPointer(size, 1, gl.FLOAT, false, vertexSize, 2 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(color, 4, gl.FLOAT, false, vertexSize, 3 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(outlineSize, 1, gl.FLOAT, false, vertexSize, 7 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(outlineColor, 4, gl.FLOAT, false, vertexSize, 8 * Float32Array.BYTES_PER_ELEMENT);
        gl.enableVertexAttribArray(position);
        gl.enableVertexAttribArray(size);
        gl.enableVertexAttribArray(color);
        gl.enableVertexAttribArray(outlineSize);
        gl.enableVertexAttribArray(outlineColor);
        return () => {
            gl.disableVertexAttribArray(position);
            gl.disableVertexAttribArray(size);
            gl.disableVertexAttribArray(color);
            gl.disableVertexAttribArray(outlineSize);
            gl.disableVertexAttribArray(outlineColor);
        };
    }
    setUniforms(gl, program, matrix) {
        gl.uniformMatrix4fv(gl.getUniformLocation(program, 'u_matrix'), false, matrix);
        gl.uniform1f(gl.getUniformLocation(program, 'u_interpolation'), this.interpolation);
    }
    getArrayBufferElementsPerVertex() {
        return 12;
    }
    getPrimitiveType(gl) {
        return gl.POINTS;
    }
}
