// import { Alert } from '@coreui/coreui'
import React from 'react'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { Card, CardBody, CardHeader, Container, Table, Alert } from 'reactstrap'
import { CustomButton } from '../UI'
import CustomScrollbar from '../UI/CustomScrollbar'

export default function Other() {
    const navigate = useNavigate()
    return (
        <div>
            <CustomButton onClick={()=>navigate(-1)}><FaArrowAltCircleLeft /></CustomButton>
            <Card className='admin-card p-3 mt-3'>
                <h4>Other</h4>
                <CustomScrollbar>

                    <Table bordered>
                        <thead>
                            <tr>
                                <td>S/N</td>
                                <td>Location Longitude</td>
                                <td>Location Latitude</td>
                                <td>Report Type</td>
                                <td>Description</td>
                                <td>Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>kano</td>
                                <td>naibawa</td>
                                <td>flood</td>
                                <td>the first</td>
                                <td>12/2/2021</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>kano</td>
                                <td>naibawa</td>
                                <td>flood</td>
                                <td>the first</td>
                                <td>12/2/2021</td>
                            </tr>
                        </tbody>
                    </Table>
                    {/* <center><Alert>No Data here</Alert></center> */}
                </CustomScrollbar>

            </Card>
        </div>
    )
}
