import { findFeatureCollectionBounds } from '../../shared/geometry-functions';
import { TileRenderer } from '../../shared/tile/tile-renderer';
export class TiledRenderer {
    constructor(renderer, options) {
        this.renderer = renderer;
        this.dataBounds = {
            minX: Infinity,
            minY: Infinity,
            maxX: -Infinity,
            maxY: -Infinity
        };
        this.tileRenderer = new TileRenderer(renderer, options);
    }
    setDataAndStyle(data, styleOption) {
        findFeatureCollectionBounds(this.dataBounds, data);
        this.renderer.setDataAndStyle(data, styleOption);
        this.tileRenderer.markAllTilesOutdated();
    }
    clearData() {
        this.renderer.clearData();
        this.tileRenderer.markAllTilesOutdated();
    }
    initialise(map, gl) {
        this.tileRenderer.initialise(map, gl);
    }
    dispose(map, gl) {
        this.tileRenderer.dispose(map, gl);
    }
    prerender() {
    }
    render(gl, matrix) {
        this.tileRenderer.render(gl, matrix, this.dataBounds);
    }
}
