import { createShaderProgram } from '../../shared/shader/shader';
export class ShaderRenderer {
    constructor(shader, vertexDataMapper) {
        this.shader = shader;
        this.vertexDataMapper = vertexDataMapper;
        this.program = null;
        this.arrayBuffer = null;
        this.elementArrayBuffer = null;
        this.array = new Float32Array([]);
        this.elementArray = null;
    }
    setDataAndStyle(data, styleOption) {
        const arrays = this.vertexDataMapper(data, styleOption);
        this.array = arrays.array;
        this.elementArray = arrays.elementArray;
    }
    clearData() {
        this.array = new Float32Array([]);
        this.elementArray = null;
    }
    initialise(map, gl) {
        this.program = createShaderProgram(gl, this.shader.vertexSource, this.shader.fragmentSource);
        this.arrayBuffer = gl.createBuffer();
        this.elementArrayBuffer = gl.createBuffer();
    }
    dispose(map, gl) {
        gl.deleteBuffer(this.elementArrayBuffer);
        gl.deleteBuffer(this.arrayBuffer);
        gl.deleteProgram(this.program);
    }
    prerender() {
    }
    render(gl, matrix) {
        if (this.program == null) {
            throw Error('ShaderRenderer can not render before it is initialised.');
        }
        gl.enable(gl.BLEND);
        gl.blendEquation(gl.FUNC_ADD);
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.useProgram(this.program);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.arrayBuffer);
        const cleanAttributes = this.shader.configureAttributes(gl, this.program);
        gl.bufferData(gl.ARRAY_BUFFER, this.array, gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);
        this.shader.setUniforms(gl, this.program, matrix);
        if (this.elementArray == null) {
            gl.drawArrays(this.shader.getPrimitiveType(gl), 0, this.array.length / this.shader.getArrayBufferElementsPerVertex());
        }
        else {
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.elementArrayBuffer);
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.elementArray, gl.STATIC_DRAW);
            gl.drawElements(this.shader.getPrimitiveType(gl), this.elementArray.length, gl.UNSIGNED_INT, 0);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
        }
        cleanAttributes();
    }
}
