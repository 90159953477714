/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";

import { MdKeyboardVoice } from "react-icons/md";
import { Row, Card, Col } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import "./navbar.css";
import { primaryColor } from "./colors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { apiURL, _fetchApi, _postApi, unflatten } from "./helpers/helper";
import _customNotification from "./UI/CustomNotification";
import Crops from "./Crops";
import Locations from "./Locations";
import Sizes from "./Sizes";
import LocationForm from "./LocationForm";
import { RxText } from "react-icons/rx";
import { BiVideo } from "react-icons/bi";
import { BsRecordBtn } from "react-icons/bs";
import MessageType, {
  RecordMessage,
  VideoMessage,
  VioceMessage,
} from "./MessageType/MessageType";

export default function CreateMessage() {
  // const [messageType, setMessageType] = useState(false)
  const [selectCrop, setSelectCrop] = useState([]);

  const [lgas, setLgas] = useState([]);
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectSize, setSelectSize] = useState([]);
  const [matchedFarmers, setMatchedFarmers] = useState(0);

  const [form, setForm] = useState({
    entry_type: "",
    location_name: "",
    longitude: "",
    latitude: "",
    target: "",
    severity: "",
    coordinates: "",
    edit_map: true,
    camps: [],
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const navigates = useNavigate();
  const { addToast } = useToasts();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const audioValue = useSelector((state) => state.app.audio);
  const [tab, setTab] = useState("text");

  const modals = () => {
    toggle();
  };
  const [message, setMessage] = useState({});

  const [hazard, setHazard] = useState({
    hazard_group: "",
    disaster_type: "",
    causes: "",
    projected_event_date: "",
    event_duration_year: "",
    event_duration_month: "",
    event_duration_hours: "",
    event_duration_minute: "",
  });

  const handleReset = () => {
    setForm((p) => ({
      ...p,
      entry_type: "",
      location_name: "",
      longitude: "",
      latitude: "",
      severity: "",
    }));

    setHazard((p) => ({
      ...p,
      hazard_group: "",
      disaster_type: "",
      causes: "",
      projected_event_date: "",
      event_duration_year: "",
      event_duration_month: "",
      event_duration_hours: "",
      event_duration_minute: "",
    }));

    setMessage({});
  };

  let geoId;
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("user_location"));
    const user_location = cache ? cache : null;
    if (user_location && user_location.latitude) {
      setForm((p) => ({
        ...p,
        latitude: user_location.latitude,
        longitude: user_location.longitude,
      }));
    } else {
      geoId = window.navigator.geolocation.watchPosition((position) => {
        setForm((p) => ({
          ...p,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }));
        localStorage.setItem(
          "user_location",
          JSON.stringify({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      });

      return () => {
        navigator.geolocation.clearWatch(geoId);
      };
    }
  }, []);

  const { authenticated, user } = useSelector((s) => s.auth);
  const userDetail = useSelector((d) => d.auth);
  let userId = user.org_id;
  // const navigate = useNavigate()
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    setLoading(true);
    const data = new FormData();
    data.append("image", image);

    Object.keys(form).forEach((i) => data.append(i, form[i]));
    fetch(`${apiURL}/messages/dispatch?org_id=${userId}`, {
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        navigates(-1);
      })
      .catch((err) => {
        console.log("Bad request");
        console.log(err);
        alert("An Error Occured");
        setLoading(false);
      });
    // _postApi(`/messages/dispatch?org_id=${userId}`,
    // formData,
    //   () => {
    //     _customNotification(addToast, 'Submitted');
    //     // setLoading(false)
    //     // setForm(_form)
    //     // getSubscribers()
    //   },
    //   () => {

    //   })
    // fetch(`${apiURL}/messages/dispatch?org_id=${userId}`, {
    //   method: "POST",
    //   body: form,
    //   'Content-Type':'application/json'
    // })
    //   .then((raw) => raw.json())
    //   .then((data) => {
    //     console.log(data);
    //     modals();
    //   })
    //   .catch((e) => console.log(e));
    // _customNotification(addToast, 'Warning created')
    // navigate(-1)
  };

  const [video, setVideo] = useState();
  const addFileVideo = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files[0]) {
      setVideo(URL.createObjectURL(e.target.files[0]));
    }
  };
  const [audio, setAudio] = useState();
  const addFileAudio = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files[0]) {
      setAudio(URL.createObjectURL(e.target.files[0]));
    }
  };
  const [farmers, setFarmers] = useState([]);
  useEffect(() => {
    _postApi(
      `/messages/getFarmers1?org_id=${userId}`,
      { lga: lgas && lgas?.join(","), scale: selectSize && selectSize?.join(","),crops:selectCrop&&selectCrop?.join(',') },
      (res) => {
        setFarmers(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
    setForm((p) => ({
      ...p,
      lga: lgas && lgas?.join(","),
      scale: selectSize && selectSize?.join(","),
      crops:selectCrop&&selectCrop?.join(',')
    }));
  }, [lgas, selectSize]);
  const [data,setData]=useState([])
  const getFilters = ()=>{
    _fetchApi (`/messages/getGroupingbYID?query_type=select&org_id=${user?.org_id}`,
    (res)=>{
      setData(res.results)   
      console.log(res)
    },(err)=>{
console.log(err)
    }
    ) 
  }
  const [group,setGroup]=useState([])
  const getGrouping = ()=>{
    _fetchApi (`/messages/getGrouping?query_type=select&org_id=${user?.org_id}`,
    (res)=>{
      const flat =unflatten(res.results)
      // setFilters(res.results)   
      setGroup(flat)   
      console.log(res)
    },(err)=>{
console.log(err)
    }
    ) 
  }
  useEffect(()=>{
    getFilters()
    getGrouping()
  },[])
  const [newData,setNewData]=useState([])
  
 let fGroup =  group.map(item=>item.children)
 const [semis,setSemis]=useState([])
 const semi = (group_name)=>{
  // alert(JSON.stringify(group_name))
  setSemis(fGroup[0]?.filter(i=>i.group_name === group_name))
 }

//  let semiFinal = fGroup[0]?.filter(i=>i.group_name === '')
  return (
    <div>
      {/* {JSON.stringify(semis[0]?.children.map(i=>i.group_name))} */}
      <Card className="dashboard_card m-3 shadow-sm p-4">
        <Row>
          <Col md={6}>
            <h4 className="card_title mb-4">Compose and Send Message</h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              <button
                className={
                  tab === "text" ? "message_button1" : "message_button"
                }
                onClick={() => setTab("text")}
                // style={{ backgroundColor: !messageType ? primaryColor : null }}
              >
                <RxText size="1.6rem" style={{ paddingRight: 10 }} />
                Text Message
              </button>
              <button
                className={
                  tab === "vioce" ? "message_button1" : "message_button"
                }
                onClick={() => setTab("vioce")}
                // style={{ backgroundColor: !messageType ? primaryColor : null }}
              >
                <MdKeyboardVoice size="1.6rem" style={{ paddingRight: 10 }} />
                Voice Message
              </button>
              <button
                className={
                  tab === "video" ? "message_button1" : "message_button"
                }
                onClick={() => setTab("video")}
                // style={{ backgroundColor: !messageType ? primaryColor : null }}
              >
                <BiVideo size="1.6rem" style={{ paddingRight: 10 }} />
                Video Message
              </button>
              <button
                className={
                  tab === "record" ? "message_button1" : "message_button"
                }
                onClick={() => setTab("record")}
                // style={{ backgroundColor: !messageType ? primaryColor : null }}
              >
                <BsRecordBtn size="1.6rem" style={{ paddingRight: 10 }} />
                Record Message
              </button>
            </div>
          </Col>
        </Row>
        {/* {JSON.stringify(form)} */}
        {/* <div className="buttons_div" style={{}}>
          <button
            className="message_button"
            onClick={() => setMessageType(false)}
            style={{ backgroundColor: !messageType ? primaryColor : null, marginRight: 10 }}
          >
            <RxText size="1.2rem" /> Text Message
          </button>
          <button
            className="message_button"
            style={{ backgroundColor: messageType ? primaryColor : null }}
            onClick={() => setMessageType(true)}
          >
            <MdKeyboardVoice size="1.2rem" /> Voice Message

          </button>
        </div> */}
        <Row className="">
          <Col md={6}>
            <label className="label_">Message Filter</label>
            <select
              className="input_field p-2"
              name="target"
              value={form.target}
              onChange={handleChange}
            >
              <option>Target locations, crops, sizes, and language...</option>
{data&&data.map((item)=><option onClick={()=>semi(item.group_name)} value={item.group_name}>{item.group_name}</option>)}
              {/* <option>Sizes</option>
              <option>Crops</option>
              <option>Locations</option>
              <option>Geographical Location</option> */}
            </select>
            <input
              type="text"
              className="input_field p-2 mt-3"
              placeholder="Title"
              name="title"
              value={form.title}
              onChange={handleChange}
            />
            <div className="message_zone  mt-3">
              <Row>
                <Col md={12}>
                  {tab === "text" ? (
                    <Row>
                      <Col md={12}>
                        <textarea
                          name="description"
                          className="text_area_input input_field mt-3"
                          rows="13"
                          maxLength={160}
                          placeholder="Write message"
                          value={message.description}
                          onChange={handleChange}
                          type="textarea"
                        />
                      </Col>
                    </Row>
                  ) : tab === "vioce" ? (
                    <Row>
                      <Col md={12}>
                        <div className="input_field_message">
                          <input
                            type="file"
                            accept="audio/mp3"
                            onChange={addFileAudio}
                            placeholder="Audio"
                          />
                          {/* <VoiceRecord /> */}
                          {/* {file && <p>Selected file: {file}</p>} */}
                        </div>
                        {audio && (
                          <audio controls>
                            <source src={audio} type="audio/mpeg" />
                          </audio>
                        )}
                      </Col>
                    </Row>
                  ) : tab === "video" ? (
                    <Row>
                      <Col md={12}>
                        <div className="input_field_message">
                          <input
                            type="file"
                            accept="video/mp4"
                            onChange={addFileVideo}
                          />
                        </div>
                        {video && (
                          <video controls>
                            <source
                              src={video}
                              style={{ height: "20%", width: "30%" }}
                            />
                          </video>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <RecordMessage />
                  )}
                </Col>
                {/* <Col md={2}>
                  <MdKeyboardVoice className="mic mt-3" size="3rem" onClick={''} />
                  <BsStopFill className="mic mt-3" size="3rem" onClick={''} />
                </Col> */}
              </Row>
              {/* <VoiceRecord /> */}
              {/* <div className="mt-3">

                <Dropzone
                  classNames='input_field'
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  height={50}
                  accept="image/,audio/,video/*"
                  />
                </div> */}
            </div>

            <button className="new_button mt-3" onClick={handleSubmit}>
              {loading ? <span>Loading...</span> : <span>Send Message</span>}
            </button>
          </Col>

          <Col md={6} className="">
            {/* {JSON.stringify(selectSize)} */}
            {/* {JSON.stringify(selectCrop)} */}
            <div className="message_zone msg_z2  mt-3 p-3">
              {form.target === "sizes" ? (
                <Sizes multiSelections={selectSize} onChange={setSelectSize} data={semis[0]?.children.map(i=>i.group_name)} />
              ) : form.target === "crops" ? (
                <Crops multiSelections={selectCrop} onChange={setSelectCrop} data={semis[0]?.children.map(i=>i.group_name)}/>
              ) : form.target === "Geographical Location" ? (
                <LocationForm
                  form={form}
                  setForm={setForm}
                  handleChange={handleChange}
                />
              ) : form.target === "locations" ? (
                <Locations multiSelections={lgas} onChange={setLgas} />
              ) : null}
              {form.target === "Geographical Location" ? (
                ""
              ) : (
                <p
                  style={{ fontWeight: "bold", color: primaryColor }}
                  className=""
                >
                  Active Filters
                </p>
              )}
              {lgas.length > 0 ? (
                <p>
                  Locations:{" "}
                  {lgas.map((l) => (
                    <span className="filter_items">{l}</span>
                  ))}
                </p>
              ) : null}
              {selectCrop.length > 0 ? (
                <p>
                  Crops:{" "}
                  {selectCrop.map((c) => (
                    <span className="filter_items">{c}</span>
                  ))}
                </p>
              ) : null}
              {selectSize.length > 0 ? (
                <p>
                  Scales:{" "}
                  {selectSize.map((s) => (
                    <span className="filter_items">{s}</span>
                  ))}
                </p>
              ) : null}
              <p>
                Target Farmer:{" "}
                <span className="filter_items">{farmers?.length}</span>
              </p>
              {/* {selectLocations.length > 0 ? (
              <p>
                Locations:{' '}
                {selectLocations.map((s) => (
                  <span className="filter_items">{s}</span>
                ))}
              </p>
            ) : null} */}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
