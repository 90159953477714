
// import { useSelector } from 'react-redux'
import { useRef } from 'react'
import { MdDateRange } from 'react-icons/md'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'

function DaterangeSelector({ mini = false, from, to, handleChange }) {
  // const activeBusiness = useSelector((state) => state.auth.activeBusiness)
  const _toRef = useRef()
  const _fromRef = useRef()

  const focusFrom = e => {
    e.preventDefault()
    _fromRef.current?.focus();
    _fromRef.current?.change();
  }
  const focusTo = e => {
    e.preventDefault()
    _toRef.current?.focus();
    _toRef.current?.change();
  }
  return (
    <Row >
      {/* {mini ? <Col className='text-right' md={2}>Form:  </Col> : null} */}
      <Col md={6}>
        <FormGroup>
          {/* {mini ? null : <Label style={{ fontWeight: 'italic' }}> From (date):</Label>} */}
        <label className='label_'>From</label>
          <input
          className='input_field p-2'
            type="date"
            value={from}
            innerRef={_fromRef}
            name="from"
            onChange={handleChange}
            style={{
              borderWidth: 1,
              // borderColor: activeBusiness.primary_color,
            }}
            />
        </FormGroup>
      </Col>

      {/* <Col></Col> */}
      {/* {mini ? <Col md={2}>To: </Col> : null} */}
      <Col md={6}>
        <FormGroup>
          {/* {mini ? null : <Label style={{ fontWeight: 'italic' }}>To (date): </Label>} */}
          <label className='label_'>To</label>
          <input
          className='input_field p-2'
            type="date"
            value={to}
            name="to"
            innerRef={_toRef}
            onChange={handleChange}
            style={{
              borderWidth: 1,
              // borderColor: activeBusiness.primary_color,
            }}
          />
        </FormGroup>
      </Col>
    </Row>
  )
}

export default DaterangeSelector
