import { lineRenderer } from '../renderer-preset/line-renderer';
import { StaticDataLayer } from '../static-data-layer';
import { lineToResultsClickHandler } from '../../shared/click-handler/line-click-handler';
import { RBushClickProvider } from '../click-provider/r-bush-click-provider';
export function lineLayer(options) {
    return new StaticDataLayer({
        id: options.id,
        renderer: lineRenderer(options),
        clickProvider: options.onClick != null ?
            new RBushClickProvider(lineToResultsClickHandler(options.onClick), options.clickSize) : undefined
    });
}
