import React, { useState } from "react";

import MicRecorder from "mic-recorder-to-mp3";
import { Button, Col, Row } from "reactstrap";
import { FaPlay, FaStop } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AUDIO_VALUE } from "./redux/types";
import { BsStopFill } from 'react-icons/bs'
import { MdKeyboardVoice } from 'react-icons/md';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function VoiceRecord() {
  const [state, setState] = useState({
    isRecording: false,
    blobURL: "",
    isBlocked: false,
    buffer: null,
    blob: null,
  });

  const dispatch = useDispatch();

  const start = () => {
    if (state.isBlocked) {
      alert("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setState((p) => ({ ...p, isRecording: true }));
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setState((p) => ({ ...p, blobURL, isRecording: false, blob }));
        const file = new File(buffer, "mp3.mp3", {
          type: state.blob?.type,
        });
        console.log(blob, "File: ", file);
        dispatch({ type: AUDIO_VALUE, payload: file });
      })
      .catch((e) => console.log(e));
  };
  // const save = (e) => {
  //   e.preventDefault();
  //   const file = new File(this.state.buffer, "recording.mp3", {
  //     type: this.state.blob.type,
  //   });
  //   const url = "http://localhost:8000/record";
  //   const data = new FormData();
  //   data.append("audio", this.state.blob, "recording.mp3");
  //   data.append("audio", file);

  //   axios.post(url, data).then((e) => {
  //     console.log("success");
  //   });

  //   alert("audio uploaded successfully");
  // };

  // const _audioValue = useSelector((state) => state.app.audio);

  return (
    <div className="mt-4">
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <audio controls src={state.blobURL} />
          {!state.isRecording ?
            <MdKeyboardVoice className="mic" style={{ marginLeft: 10 }} size="3rem" onClick={start}/>
            :
            <BsStopFill className="mic" style={{ marginLeft: 10 }} size="3rem" onClick={stop}/>
          }
        </Col>
        <Col>
          {/* <div>
            <div className="d-flex">
              {!state.isRecording ? (
                <Button className="m-1" color="success" onClick={start}>
                  <FaPlay />
                </Button>
              ) : (
                <Button onClick={stop} className="m-1" color="danger">
                  <FaStop />
                </Button>
              )}
              <p style={{ marginTop: 8 }} className="m-2">
                <b>{state.isRecording ? "Recording" : "Please start ecord"}</b>
              </p>
            </div>
          </div> */}
        </Col>
      </Row>
    </div>
  );
}
