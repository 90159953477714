import { generateID } from 'react-mapbox-gl/lib/util/uid';
import React from 'react';
import { polygonLayer } from 'mapbox-gl-performance-layers';
import { StaticDataLayerComponent } from './static-data-layer-component';
import { useClickProxy } from '../shared/use-click-proxy';
export function PolygonLayer(props) {
    const clickProxy = useClickProxy(props.onClick);
    const layerOptions = Object.assign(Object.assign({ id: `static-data-polygon-${generateID()}` }, props), { onClick: clickProxy });
    return (React.createElement(StaticDataLayerComponent, Object.assign({ layerConstructor: () => polygonLayer(layerOptions) }, props)));
}
