import { closestPointOnLine, pointToPointDistanceSqr } from '../geometry-functions';
export function lineToResultsClickHandler(onClick) {
    return function (x, y, size, results, e) {
        let closestResult = results[0];
        let minDistanceSqr = Infinity;
        const closestPoint = { x: 0, y: 0 };
        const point = { x: 0, y: 0 };
        for (const result of results) {
            closestPointOnLine(point, x, y, result.feature.geometry);
            const distanceSqr = pointToPointDistanceSqr(x, y, point.x, point.y);
            if (distanceSqr < minDistanceSqr) {
                closestResult = result;
                minDistanceSqr = distanceSqr;
                closestPoint.x = point.x;
                closestPoint.y = point.y;
            }
        }
        const clickDistanceSqr = 0.25 * (size * size);
        if (minDistanceSqr <= clickDistanceSqr) {
            onClick(closestResult.feature, e, closestPoint);
        }
    };
}
