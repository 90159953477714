import { resolveVisibility } from '../shared/visibility';
import { DataOperationsComposer } from './data-operations';
export class DynamicDataLayer {
    constructor(options) {
        this.options = options;
        this.map = null;
        this.visibility = true;
        this.dataOperations = new DataOperationsComposer(this.options.clickProvider != null ?
            [this.options.renderer.dataOperations, this.options.clickProvider.dataOperations] :
            [this.options.renderer.dataOperations], () => {
            if (this.map != null) {
                this.map.triggerRepaint();
            }
        });
    }
    get id() {
        return this.options.id;
    }
    get renderingMode() {
        if (this.options.renderingMode == null) {
            return '2d';
        }
        return this.options.renderingMode;
    }
    get type() {
        return 'custom';
    }
    setStyle(styleOption) {
        this.options.renderer.setStyle(styleOption);
    }
    setVisibility(visibility) {
        this.visibility = visibility;
        if (this.options.clickProvider != null) {
            this.options.clickProvider.setVisibility(visibility);
        }
        if (this.map != null) {
            this.map.triggerRepaint();
        }
    }
    isVisible() {
        return resolveVisibility(this.visibility, this.map);
    }
    onAdd(map, gl) {
        this.map = map;
        this.options.renderer.initialise(map, gl);
        if (this.options.clickProvider != null) {
            this.options.clickProvider.initialise(map);
        }
    }
    onRemove(map, gl) {
        this.map = null;
        this.options.renderer.dispose(map, gl);
        if (this.options.clickProvider != null) {
            this.options.clickProvider.dispose(map);
        }
    }
    prerender(gl, matrix) {
        if (this.isVisible()) {
            this.options.renderer.prerender(gl, matrix);
        }
    }
    render(gl, matrix) {
        if (this.isVisible()) {
            this.options.renderer.render(gl, matrix);
        }
    }
}
