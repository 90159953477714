import RBush from 'rbush';
import { packFeature } from '../../shared/geometry-functions';
import { resolveVisibility } from '../../shared/visibility';
export class DynamicRBrushClickProvider {
    constructor(resultsHandler, clickSize) {
        this.resultsHandler = resultsHandler;
        this.clickSize = clickSize;
        this.packedFeatures = [];
        this.tree = new RBush();
        this.map = null;
        this.visibility = true;
        this.currentIndex = 0;
        this.dataOperations = {
            add: (feature) => {
                const packed = packFeature(feature, this.currentIndex);
                this.currentIndex++;
                this.packedFeatures.push(packed);
                this.tree.insert(packed);
            },
            removeFirst: () => {
                const packed = this.packedFeatures.shift();
                if (packed != null) {
                    this.tree.remove(packed);
                }
                return packed != null ? packed.feature : null;
            },
            removeLast: () => {
                const packed = this.packedFeatures.pop();
                if (packed != null) {
                    this.tree.remove(packed);
                }
                return packed != null ? packed.feature : null;
            },
            clear: () => {
                this.packedFeatures.length = 0;
                this.tree.clear();
            },
            getArray: () => {
                return this.packedFeatures.map(p => p.feature);
            },
            addAll: (features) => {
                const packed = features.map((f, index) => packFeature(f, this.currentIndex + index));
                this.currentIndex += features.length;
                this.packedFeatures.push(...packed);
                this.tree.load(packed);
            },
            removeNFirst: (n) => {
                let removed = [];
                for (let i = 0; i < n; i++) {
                    const r = this.dataOperations.removeFirst();
                    if (r != null) {
                        removed.push(r);
                    }
                }
                return removed;
            },
            removeNLast: (n) => {
                let removed = [];
                for (let i = 0; i < n; i++) {
                    const r = this.dataOperations.removeLast();
                    if (r != null) {
                        removed.unshift(r);
                    }
                }
                return removed;
            }
        };
        this.clickHandler = (e) => {
            if (this.map == null || this.tree == null) {
                return;
            }
            if (!resolveVisibility(this.visibility, this.map)) {
                return;
            }
            const bounds = this.map.getBounds();
            const canvas = this.map.getCanvas();
            const clickSize = this.clickSize != null ? this.clickSize : 16;
            const x = e.lngLat.lng;
            const y = e.lngLat.lat;
            const w = clickSize * (bounds.getEast() - bounds.getWest()) / canvas.width;
            const h = clickSize * (bounds.getNorth() - bounds.getSouth()) / canvas.height;
            const results = this.tree.search({
                minX: x - 0.5 * w,
                minY: y - 0.5 * h,
                maxX: x + 0.5 * w,
                maxY: y + 0.5 * h
            });
            this.resultsHandler(x, y, Math.max(w, h), results, e);
        };
    }
    initialise(map) {
        this.map = map;
        map.on('click', this.clickHandler);
    }
    dispose(map) {
        this.map = null;
        map.off('click', this.clickHandler);
    }
    setVisibility(visibility) {
        this.visibility = visibility;
    }
}
