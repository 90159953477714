export class BaseDataOperationsComposer {
    constructor(sources, onDataChange) {
        this.sources = sources;
        this.onDataChange = onDataChange;
    }
    add(element) {
        for (const source of this.sources) {
            source.add(element);
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
    }
    removeFirst() {
        let removed = null;
        for (const source of this.sources) {
            removed = source.removeFirst();
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
        return removed;
    }
    removeLast() {
        let removed = null;
        for (const source of this.sources) {
            removed = source.removeLast();
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
        return removed;
    }
    clear() {
        for (const source of this.sources) {
            source.clear();
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
    }
    getArray() {
        if (this.sources.length == 0) {
            throw Error('No data sources provided.');
        }
        return this.sources[0].getArray();
    }
}
export class DataOperationsComposer extends BaseDataOperationsComposer {
    constructor(sources, onDataChange) {
        super(sources, onDataChange);
        this.sources = sources;
    }
    addAll(elements) {
        for (const source of this.sources) {
            source.addAll(elements);
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
    }
    removeNFirst(n) {
        let removed = null;
        for (const source of this.sources) {
            removed = source.removeNFirst(n);
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
        return removed != null ? removed : [];
    }
    removeNLast(n) {
        let removed = null;
        for (const source of this.sources) {
            removed = source.removeNLast(n);
        }
        if (this.onDataChange != null) {
            this.onDataChange();
        }
        return removed != null ? removed : [];
    }
}
export class DataOperationsExtender {
    constructor(source) {
        this.source = source;
    }
    add(element) {
        this.source.add(element);
    }
    removeFirst() {
        return this.source.removeFirst();
    }
    removeLast() {
        return this.source.removeLast();
    }
    clear() {
        this.source.clear();
    }
    getArray() {
        return this.source.getArray();
    }
    addAll(elements) {
        for (const element of elements) {
            this.source.add(element);
        }
    }
    removeNFirst(n) {
        let removed = [];
        for (let i = 0; i < n; i++) {
            const r = this.source.removeFirst();
            if (r != null) {
                removed.push(r);
            }
        }
        return removed;
    }
    removeNLast(n) {
        let removed = [];
        for (let i = 0; i < n; i++) {
            const r = this.source.removeLast();
            if (r != null) {
                removed.unshift(r);
            }
        }
        return removed;
    }
}
