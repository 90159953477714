export class SwitchRenderer {
    constructor(options) {
        this.options = options;
        this.map = null;
        this.gl = null;
        this.currentOption = null;
    }
    setDataAndStyle(data, styleOption) {
        const currentOption = this.currentOption;
        if (currentOption == null || !currentOption.condition(data)) {
            const newOption = this.options.find(option => option.condition(data));
            if (newOption !== currentOption) {
                if (currentOption != null) {
                    currentOption.renderer.clearData();
                }
                if (this.map != null && this.gl != null) {
                    if (currentOption != null) {
                        currentOption.renderer.dispose(this.map, this.gl);
                    }
                    if (newOption != null) {
                        newOption.renderer.initialise(this.map, this.gl);
                    }
                }
                this.currentOption = newOption != null ? newOption : null;
            }
        }
        if (this.currentOption != null) {
            this.currentOption.renderer.setDataAndStyle(data, styleOption);
        }
    }
    clearData() {
        if (this.currentOption != null) {
            this.currentOption.renderer.clearData();
        }
    }
    initialise(map, gl) {
        if (this.currentOption != null) {
            this.currentOption.renderer.initialise(map, gl);
        }
        this.map = map;
        this.gl = gl;
    }
    dispose(map, gl) {
        this.gl = null;
        this.map = null;
        if (this.currentOption != null) {
            this.currentOption.renderer.dispose(map, gl);
        }
    }
    prerender(gl, matrix) {
        if (this.currentOption != null) {
            this.currentOption.renderer.prerender(gl, matrix);
        }
    }
    render(gl, matrix) {
        if (this.currentOption != null) {
            this.currentOption.renderer.render(gl, matrix);
        }
    }
}
