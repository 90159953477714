import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { apiURL, _fetchApi, _postApi } from "../helpers/helper";
import Nav from "./Nav";
import { Card, Row } from "reactstrap";
import earth from "../../src/assets/images/warning.png";
import { Col } from "react-bootstrap";
// import "./signup.css";
import { LogIn } from "react-feather";
import { useDispatch } from "react-redux";
import { createUser } from "../redux/actions/auth";

export default function Signup() {
  const navigate = useNavigate();
  const [signUp, setSignup] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    address: "",
    role: "",
    org_type: "",
  });
  const [roles, setRoles] = useState([]);
  const handleChange = ({ target: { name, value } }) => {
    setSignup((p) => ({ ...p, [name]: value }));
  };
  const dispatch = useDispatch();

  const _getRoles = () => {
    _fetchApi(
      `/users/get-roles`,
      (s) => {
        setRoles(s.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleSubmit = () => {
    dispatch(
      createUser(
        signUp,
        () => {
          navigate("/app/dashboard");
          alert("success");
        },
        (e) => {
          console.log(e.error);
        }
      )
    );
  };
  useEffect(() => {
    _getRoles();
  });
  return (
    <div className="">
      {/* {JSON.stringify(roles)} */}
      <Row className="m-0 p-0">
        <Col md={12}>
          <Card className="registration_card shadow m-0">
            <Row className="m-0">
              <Col lg={6} md={6} sm={12} xs={12} className="p-4">
                <Nav />
                <Row className="m-0">
                  <Col md={6} className="m-0 p-0">
                    {" "}
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>
                        Organization Name
                      </label>
                      <input
                        type="text"
                        className="form-control  mx-5   "
                        placeholder="Organization Name"
                        style={{ width: "90%" }}
                        name="org_name"
                        value={signUp.org_name}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>
                        Type Of Organization
                      </label>
                      <select
                        className="form-control  mx-5  "
                        style={{ width: "90%" }}
                        name="org_type"
                        value={signUp.org_type}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        <option value={'Govemental Org'}>Govt. Organization</option>
                        <option value={'NGO'}>NGO</option>
                        <option value={'Private'}>Private Organization</option>
                        {/* <option>--select--</option> */}
                      </select>
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>Category</label>
                      <select
                        className="form-control  mx-5  "
                        style={{ width: "90%" }}
                        name="role"
                        value={signUp.role}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {roles.map((item, id) => (
                          <option key={id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>
                        Organization Email
                      </label>
                      <input
                        type="email"
                        className="form-control  mx-5  "
                        placeholder="Enter email"
                        style={{ width: "90%" }}
                        name="email"
                        value={signUp.email}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                 

                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>Phone Number</label>
                      <input
                        type="text"
                        className="form-control  mx-5  "
                        placeholder="Enter number"
                        style={{ width: "90%" }}
                        name="phone"
                        value={signUp.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}> Address</label>
                      <input
                        type="text-area"
                        className="form-control  mx-5  "
                        placeholder="Enter Address"
                        style={{ width: "90%" }}
                        name="address"
                        value={signUp.address}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>Username</label>
                      <input
                        type="username"
                        className="form-control  mx-5  "
                        placeholder="Username"
                        style={{ width: "90%" }}
                        name="username"
                        value={signUp.username}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="m-0 p-0">
                    <div className="mb-3">
                      <label style={{ marginLeft: "50px" }}>Password</label>
                      <input
                        type="password"
                        className="form-control  mx-5  "
                        placeholder="Enter password"
                        style={{ width: "90%" }}
                        value={signUp.password}
                        name="password"
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="d-grid">
                  <center>
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary px-5"
                    >
                      <LogIn /> Sign Up
                    </button>
                  </center>
                </div>
                <p className="forgot-password text-right">
                  Already registered <a href="/sign-in">sign in?</a>
                </p>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12} className="register_image m-0">
                <img
                  src={earth}
                  className="register_image fluid-img"
                  alt="warning img"
                  style={{ width: "100%", height: "100%" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
