import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap'
import { apiURL, _postApi } from '../helpers/helper';
import AppNav from './AppNav';
import CustomButton from './CustomButton';
import Charts from './Graph';

export const options = {
  title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
};
export default function GeneralTable() {
  const [general, setGeneral] = useState([])
  const getGeneral = () => {
    _postApi(
      `/general?query_type=select`,
      // form,
      {},
      (data) => {
        if (data.success) {
          setGeneral(data.results)


        }
      },
    )
    // )
  };
  useEffect(() => {

    getGeneral()
  }, []);

  // var data = new google.visualization.DataTable();
  // general.forEach(function (row, indexRow) {
  //   if (indexRow === 0) {
  //     row.forEach(function (column, indexCol) {
  //       if (indexCol === 0) {
  //         data.addColumn('string', column);
  //       } else {
  //         data.addColumn('number', column);
  //       }
  //     });
  //   } else {
  //     data.addRow(row);
  //   }
  // });

  let newChart = []
  // let newArr = general.filter(i => i.latitude !=='' || i.description !=='')
  general.forEach((it, idx) => newChart.push([it.description, parseFloat(it.loc_log), parseFloat(it.latitude)]));
  const navigate = useNavigate()

  return (
    <div>
      <AppNav />
      {/* {JSON.stringify(newArr)} */}
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Card className='shadow'>
            <CardHeader className='d-flex justify-content-between'><h4>Citizen Request</h4> <CustomButton onClick={() => navigate(-1)}><FaArrowAltCircleLeft /></CustomButton></CardHeader>
            <CardBody>
              <Row className="p-0 m-0">
                <Col md={8}>
                  <Charts
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={[["Des", "lag", "log"], ...newChart]}
                    options={options}
                  />
                </Col>
                <Col md={4}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4036125.075550925!2d6.356172710371973!3d8.923358659835968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0baf7da48d0d%3A0x99a8fe4168c50bc8!2sNigeria!5e0!3m2!1sen!2sng!4v1658747155423!5m2!1sen!2sng"
                    width="300"
                    height="350"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Col>
              </Row>

              <Table bordered>
                <thead>
                  <tr>
                    <th>Location Longitude</th>
                    <th>Location Latitude</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Report Type</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {general && general.map((item) => (

                    <tr>
                      <td>{item.latitude}</td>
                      <td>{item.longitude}</td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>
                      <td>{item.disaster_type}</td>
                      <td>{item.description}</td>
                      <td>{item.created_at}</td>

                    </tr>
                  ))}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={1}></Col>
      </Row>


    </div>
  )
}
