import React from 'react'
import Avatar from 'react-avatar';
import { LogOut } from 'react-feather';

import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap';

// import Login from './components/login.component'
// import SignUp from './components/signup.component'

function AppNavs() {
  const navigate = useNavigate()
  const userDetail = JSON.parse(localStorage.getItem("@@__details"))

  return (
    // <Router>
    <header className="header" style={{ borderBottom: "2px solid #f1f1f1", marginBottom: "5px" }}>
      <div className="container">
        {/* {JSON.stringify(userDetail)} */}
        <nav className="nav">
          <div className="nav-logo">
            {/* <Link to="/" className="nav-logo__link" title="logo">Early Warning</Link> */}
            <h4 onClick={() => navigate("/")}>KNOWTIFY</h4>
          </div>
          <div className="nav-menu">
            <ul className="nav-menu__item">
              {/* <li><a href="#" className="nav-menu__link">Home</a></li>
            <li><a href="#" className="nav-menu__link">About</a></li>
            <li><Link to="/sign-up" className="nav-menu__link">Sign up</Link></li>
            <li><Link to="/sign-in" className="nav-menu__link">Login</Link></li> */}
            </ul>
          </div>
          <div>
            <Avatar name={userDetail.firstname + " " + userDetail.lastname} size="60" round={true} />
            <span style={{ color: "white" }}>hhhj</span>
            <Button color='primary' className='ml-5' onClick={() => navigate("/")}><LogOut /> Logout</Button>
          </div>
        </nav>

      </div>
    </header>
    // </Router>
  )
}
export default AppNavs