import { useCallback, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useToaster } from "react-hot-toast";
import { BsAlarm, BsAlignTop, BsArrowBarRight, BsAsterisk, BsAwardFill, BsBasket2, BsBell, BsBellSlash, BsCalendar2Date, BsCheck2, BsEmojiExpressionlessFill, BsPlus, BsPlusCircle } from "react-icons/bs";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Card, Row, Col, CardBody, Table, Input, FormGroup } from "reactstrap";
import { primaryColor } from "../colors";
import { _fetchApi, _postApi } from "../helpers/helper";
import { CustomButton, CustomForm } from "../UI";
import _customNotification from "../UI/CustomNotification";
import Scrollbar from "../UI/Scrollbar";

export default function Subscribers() {
  const [multiSelections, setMultiSelections] = useState([]);
  const [multiSelections1, setMultiSelections1] = useState([]);
  const [multiSelections2, setMultiSelections2] = useState([]);
    const [searchMessage, setSearchMessage] = useState('')
  const _form = {
    query_type: 'create',
    email: '',
    phones: '',
    status: 'subscribed',
    farming_type: multiSelections.join(","),
    crops: multiSelections1.join(","),
    scale: multiSelections2.join(","),
  }
  const navigate = useNavigate()
  const [form, setForm] = useState(_form)
  const [subscribers, setSubsribers] = useState([])
  const [show_form, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [users, setUsers] = useState([])
  const [all, setAll] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  }
  const { addToast } = useToasts()
  const fields = [{
    type: 'email',
    name: 'email',
    label: 'Email',
    value: form.email,
    col: 12,
  },
  {
    type: 'number',
    name: 'phone',
    label: 'Phone',
    value: form.phone,
    col: 12,
  },
  {
    type: 'number',
    name: 'latitude',
    label: 'Latitude',
    value: form.latitude,
    col: 12,
  },
  {
    type: 'number',
    name: 'longitude',
    label: 'Longitude',
    value: form.longitude,
    col: 12,
  },
  {
    type: 'custom',
    component: () => <Row>
      <Col md={3} className="mt-2 text-center">
        <CustomButton loading={loading} outline block color='primary' className='mt-4' onClick={handleSubmit}>Submit</CustomButton>
      </Col>
    </Row>,
    col: 12,
  }]

  const handleSubscibe = () => {
    setLoading1(true)
    _postApi('/messages/bulk-update-subs',
      {
        ids: users,
        status: 'subscribed'
      },
      () => {
        _customNotification(addToast, 'Submitted');
        setLoading1(false)
        setForm(_form)
        getSubscribers()
      },
      () => {
        setLoading1(false)
        _customNotification(addToast, 'Failed to submit', 'danger');
      })
  }

  const handleUnsubscibe = () => {
    setLoading2(true)
    _postApi('/messages/bulk-update-subs',
      {
        ids: users,
        status: 'un-subscribed'
      },
      () => {
        _customNotification(addToast, 'Submitted');
        setLoading2(false)
        setForm(_form)
        getSubscribers()
      },
      () => {
        setLoading2(false)
        _customNotification(addToast, 'Failed to submit', 'danger');
      })
  }
  // const [loading, setLoading] = useState(false)
  let org_id = user && user.org_id
  const handleSubmit = () => {
    setLoading(true)
    _postApi(`/messages/subscribers?org_id=${org_id}`,
      form,
      () => {
        _customNotification(addToast, 'Submitted');
        setLoading(false)
        setForm(_form)
        getSubscribers()
      },
      () => {
        setLoading(false)
        _customNotification(addToast, 'Failed to submit', 'danger');
      })
  }

  const getSubscribers = useCallback(() => {
    setLoading(true)
    _fetchApi(`/messages/subscribers?org_id=${org_id}`, 
    (resp) => {
      setLoading(false)
      setSubsribers(resp.results)
    })
  }, [])

  let index = 0;
  useEffect(() => {
    getSubscribers()
    setForm((p) => ({
      ...p,

      farming_type: multiSelections.join(","),
      crops: multiSelections1.join(","),
      scale: multiSelections2.join(","),
      phone: '234' + form.phones.replace('0', '')
    }));
  }, [multiSelections, multiSelections1, multiSelections2])

  useEffect(() => {
    getSubscribers()

  }, [])

  const search = []
        subscribers&&
            subscribers.forEach((item) => {
                if (item.phone.toLowerCase().indexOf(searchMessage.toLowerCase()) === -1) 
                    return;
                    search.push(item)
            })

  return (
    <div>
      {/* {JSON.stringify(form)} */}
      <Card className="dashboard_card m-3 shadow-sm p-4">

        <Row className='m-0 p-0'>
          <Col md={6}>
            <button className="new_button" onClick={() => navigate('/app/create-subscribers')}>Add subscriber</button>
          </Col>
          <Col md={6}>
            <div>
              <div className="d-flex justify-content-end">
                <button style={{ backgroundColor: primaryColor, marginRight: 10 }} className="message_button" loading={loading1} onClick={handleSubscibe} color='primary' outline>Subscribe All</button>
                <button style={{ backgroundColor: primaryColor }} className="message_button" loading={loading2} onClick={handleUnsubscibe} color='primary' outline>UnSubscribe All</button>
              </div>
            </div>
          </Col>
        </Row>
        <input 
          type='search' 
          className='input_field p-2 mt-3' 
          placeholder='Search message'
          value={searchMessage}
          onChange= {(e) => setSearchMessage(e.target.value)}
        />

        <Row className="mt-3">

          <Col md={12}>

            <Table striped size="sm" responsive>
              <thead>
                <tr>
                  <th width={'5%'}>S/N</th>
                  <th>Farmers Name</th>
                  <th>Phone</th>
                  <th>Farming Scale</th>
                  <th>Farming Type</th>
                  {/* <th><FormGroup switch>
                    <Input
                      checked={all}
                      onClick={() => {
                        setAll(!all)
                        all ?
                          setUsers([])
                          :
                          subscribers.forEach(user => {
                            setUsers((p) => ([...p, user.id]))
                          })
                      }} type='checkbox' />
                  </FormGroup> </th> */}
                  {/* <th><div style={{ float: 'right' }}>Subscrb/Unsubcrb</div></th> */}
                </tr>
              </thead>
              {loading?<span>Loading subscribers...</span>:
              <tbody style={{ width: '100vw' }}>
                {search?.map((subsriber, k) => (
                  <tr>
                    <td>{k + 1}</td>
                    <td>{subsriber.name}</td>
                    <td>{subsriber.phone}</td>
                    <td>{subsriber.scale}</td>
                    <td>{subsriber.farming_type}</td>
                    {/* <td className="">
                      <div style={{ float: 'right' }}>

                        <FormGroup switch>
                          <Input
                            type='switch'
                            role='switch'
                            checked={users.includes(subsriber.id) || subsriber.status === 'subscribed'}
                            onChange={() => {
                              users.includes(subsriber.id) ? setUsers(users.filter(id => id !== subsriber.id)) : setUsers((p) => ([...p, subsriber.id]))
                            }} />
                        </FormGroup>
                      </div>
                    </td> */}
                    {/* <td> {subsriber.status === 'subscribed' ? <BsBell /> : <BsBellSlash />} {' '}
                    </td> */}
                  </tr>
                ))}
              </tbody>}
            </Table>
          </Col>
        </Row>
        <Row>
          {/* {show_form && <Col>
            <CardBody>
              <CustomForm fields={fields} handleSubmit={handleSubmit} handleChange={handleChange} />
            </CardBody>
          </Col>} */}


        </Row>

      </Card>
    </div >)
}