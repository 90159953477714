// import logo from './logo.svg';
import "./App.css";
// import Massaege from "./Massaege.js";
// import PopulationAtRisk from "./PopulationAtRisk";
import React, { useCallback, useEffect } from "react";
import AppNavigation from "./routes/AppNavigatio";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { init, logout } from "./redux/actions/auth";

function App() {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();
  const navigateUser =() => {
   
      dispatch(
        init(
          () => {
           
          },
          () => {
            navigate('/')
          }
        )
      );
  }

  useEffect(() => {
    navigateUser();
  }, []);
  return (
    <div>
      {/* {JSON.stringify({ user, authenticated })} */}
      <AppNavigation />
    </div>
  );
}

export default App;
