import { TileRenderer } from '../../shared/tile/tile-renderer';
import { findFeatureBounds, findFeaturesBounds } from '../../shared/geometry-functions';
export class DynamicTiledRenderer {
    constructor(renderer, options) {
        this.renderer = renderer;
        this.dataBounds = {
            minX: Infinity,
            minY: Infinity,
            maxX: -Infinity,
            maxY: -Infinity
        };
        this.tempBounds = {
            minX: Infinity,
            minY: Infinity,
            maxX: -Infinity,
            maxY: -Infinity
        };
        this.dataOperations = {
            add: (feature) => {
                this.renderer.dataOperations.add(feature);
                this.handleDataChange(feature);
            },
            removeFirst: () => {
                const feature = this.renderer.dataOperations.removeFirst();
                if (feature != null) {
                    this.handleDataChange(feature);
                }
                return feature;
            },
            removeLast: () => {
                const feature = this.renderer.dataOperations.removeLast();
                if (feature != null) {
                    this.handleDataChange(feature);
                }
                return feature;
            },
            clear: () => {
                this.renderer.dataOperations.clear();
                this.tileRenderer.markAllTilesOutdated();
            },
            getArray: () => {
                return this.renderer.dataOperations.getArray();
            },
            addAll: (features) => {
                this.renderer.dataOperations.addAll(features);
                for (const feature of features) {
                    this.handleDataChange(feature);
                }
            },
            removeNFirst: (n) => {
                const features = this.renderer.dataOperations.removeNFirst(n);
                for (const feature of features) {
                    this.handleDataChange(feature);
                }
                return features;
            },
            removeNLast: (n) => {
                const features = this.renderer.dataOperations.removeNLast(n);
                for (const feature of features) {
                    this.handleDataChange(feature);
                }
                return features;
            }
        };
        this.tileRenderer = new TileRenderer(renderer, options);
    }
    setStyle(styleOption) {
        this.renderer.setStyle(styleOption);
        this.tileRenderer.markAllTilesOutdated();
    }
    initialise(map, gl) {
        this.tileRenderer.initialise(map, gl);
    }
    dispose(map, gl) {
        this.tileRenderer.dispose(map, gl);
    }
    prerender() {
    }
    render(gl, matrix) {
        this.tileRenderer.render(gl, matrix, this.dataBounds);
    }
    handleDataChange(feature) {
        const bounds = this.tempBounds;
        findFeatureBounds(bounds, feature);
        this.tileRenderer.markOutdatedTiles(bounds);
        const dataBoundsChanged = this.dataBounds == null ||
            bounds.minX <= this.dataBounds.minX ||
            bounds.minY <= this.dataBounds.minY ||
            bounds.maxX >= this.dataBounds.maxX ||
            bounds.maxY >= this.dataBounds.maxY;
        if (dataBoundsChanged) {
            findFeaturesBounds(this.dataBounds, this.dataOperations.getArray());
        }
    }
}
