import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Navbar from "../dashboard/Menu.js/Navbar";
import Sidebar from "../dashboard/Menu.js/Sidebar";

export default function AppIndex() {
  const toggle = useSelector((state) => state.app.toggle);
  return (
    <div>
      <Row className="m-0 p-0">
        <div style={{ width: !toggle ? "15%" : "4%", backgroundColor: '#001F43', color: 'white' }} className="sidebarr">
          <Sidebar />
        </div>
        <div className="" style={{ width: !toggle ? "85%" : "95%" }}>
          <Navbar />
          <Outlet />
        </div>
      </Row>
    </div>
  );
}
