import React from 'react';
import { generateID } from 'react-mapbox-gl/lib/util/uid';
import { dynamicPointLayer } from 'mapbox-gl-performance-layers';
import { DynamicDataLayerComponent } from './dynamic-data-layer-component';
import { useClickProxy } from '../shared/use-click-proxy';
export function DynamicPointLayer(props) {
    const clickProxy = useClickProxy(props.onClick);
    const layerOptions = Object.assign(Object.assign({ id: `dynamic-data-point-${generateID()}` }, props), { onClick: clickProxy });
    return (React.createElement(DynamicDataLayerComponent, Object.assign({ layerConstructor: () => dynamicPointLayer(layerOptions) }, props)));
}
