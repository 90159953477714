import { TileManager } from './tile-manager';
import { TextureDrawer } from '../shader/texture-drawer/texture-drawer';
import { TileGenerator } from './tile-generator';
import { findViewBounds } from '../geometry-functions';
export class TileRenderer {
    constructor(renderer, options) {
        this.renderer = renderer;
        this.textureDrawer = new TextureDrawer();
        this.map = null;
        this.viewBounds = {
            minX: Infinity,
            minY: Infinity,
            maxX: -Infinity,
            maxY: -Infinity
        };
        const numberOfTiles = options.numberOfTiles != null ? options.numberOfTiles : 16;
        this.tileWidth = options.tileWidth != null ? options.tileWidth : 2048;
        this.tileHeight = options.tileHeight != null ? options.tileHeight : 2048;
        this.manager = new TileManager(new TileGenerator(renderer), numberOfTiles, this.tileWidth, this.tileHeight);
    }
    markAllTilesOutdated() {
        this.manager.markAllTilesOutdated();
    }
    markOutdatedTiles(bounds) {
        this.manager.markOutdatedTiles(bounds);
    }
    initialise(map, gl) {
        this.manager.initialise(map, gl);
        this.textureDrawer.initialise(gl);
        this.map = map;
    }
    dispose(map, gl) {
        this.map = null;
        this.textureDrawer.dispose(gl);
        this.manager.dispose(map, gl);
    }
    render(gl, matrix, dataBounds) {
        if (this.map == null) {
            throw Error('TiledRenderer can not render without being initialised.');
        }
        const viewportArray = gl.getParameter(gl.VIEWPORT);
        const viewport = {
            x: viewportArray[0],
            y: viewportArray[1],
            w: viewportArray[2],
            h: viewportArray[3]
        };
        const bounds = this.viewBounds;
        findViewBounds(bounds, this.map);
        const equationFactor = Math.min(this.tileWidth * (bounds.maxX - bounds.minX) / viewport.w, this.tileHeight * (bounds.maxY - bounds.minY) / viewport.h);
        const zoom = Math.ceil(-Math.log2(equationFactor));
        const size = Math.pow(2, -zoom);
        for (let x = Math.floor(bounds.minX / size); x * size < bounds.maxX; x++) {
            for (let y = Math.floor(bounds.minY / size); y * size < bounds.maxY; y++) {
                const texture = this.manager.getTileTexture(gl, x, y, zoom, dataBounds);
                if (texture != null) {
                    this.drawTile(gl, texture, matrix, x, y, zoom);
                }
            }
        }
        this.manager.incrementAge();
    }
    drawTile(gl, texture, matrix, x, y, zoom) {
        const size = Math.pow(2, -zoom);
        this.textureDrawer.draw(gl, texture, matrix, x * size, y * size, size, size);
    }
}
