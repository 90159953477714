import React, { useState } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { primaryColor } from '../../colors'
import Button from '../Button'
import Agromet from './Agromet'
import Nasa from './NASA'
import Undirr from './Undirr'
import Unspider from './Unspider'

export default function NasaInfo() {
    const [currentTab, setCurrentTab] = useState('1')
    const [tab, setTab] = useState('1')
    // const [messageType, setMessageType] = useState(false)

    return (
        <div>
            <Container className='mt-3'>
                <Card className='p-3 admin-card' style={{ overflowY: 'auto' }}>
                    {/* <CardTitle className='mt-3'>Global and Reagional warning Platforms</CardTitle> */}
                    <Row>
                        <Col md={5}>
                            <h5>Global and Regional Warning Platform</h5>
                        </Col>
                        <Col md={7}>
                            <div className='mt-3' style={{ float: 'right' }}>
                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('1')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '1' ? primaryColor : null, marginRight: 10 }}
                                >
                                    NASA
                                </button>

                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('2')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '2' ? primaryColor : null, marginRight: 10 }}
                                >
                                    UNDRR
                                </button>
                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('3')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '3' ? primaryColor : null, marginRight: 10 }}
                                >
                                    UN SPIDER
                                </button>
                                {/* <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('4')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '4' ? primaryColor : null, marginRight: 10 }}
                                >
                                    AGREEMENT
                                </button> */}
                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('5')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '5' ? primaryColor : null, marginRight: 10 }}
                                >
                                    META BOX
                                </button>
                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('6')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '6' ? primaryColor : null, marginRight: 10 }}
                                >
                                    Plant Wise Plus
                                </button>
                                <button
                                    className="message_button"
                                    onClick={() => {
                                        setTab('7')

                                    }}
                                    style={{ padding: '5px 20px', backgroundColor: tab === '7' ? primaryColor : null, marginRight: 10 }}
                                >
                                    NIMET
                                </button>
                                
                                {/* <button onClick={() => setTab('1')}>NASA</button>
                            <button onClick={() => setTab('2')}>UNDRR</button>
                            <button onClick={() => setTab('3')}>UN SPIDER</button>
                            <button onClick={() => setTab('4')}>AGREEMENT</button>
                            <button onClick={() => setTab('5')}>META BOX</button> */}


                                {/* <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={currentTab === '1' ? "active" : ""}
                                    onClick={() => setCurrentTab('1')}
                                >
                                    NASA
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={currentTab === '2' ? "active" : ""}
                                    onClick={() => setCurrentTab('2')}
                                >
                                    UNDRR
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={currentTab === '3' ? "active" : ""}
                                    onClick={() => setCurrentTab('3')}
                                >
                                    UN SPIDER
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={currentTab === '4' ? "active" : ""}
                                    onClick={() => setCurrentTab('4')}
                                >
                                    AGRIMENT
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={currentTab === '5' ? "active" : ""}
                                    onClick={() => setCurrentTab('5')}
                                >
                                    METABOX
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={currentTab}>
                            <TabPane tabId="1">
                                <Nasa />
                            </TabPane>
                            <TabPane tabId="2">
                                <Undirr />
                            </TabPane>
                            <TabPane tabId="3">
                                <Unspider />
                            </TabPane>
                            <TabPane tabId="4">
                                <iframe src="https://worldview.earthdata.nasa.gov/?v=-113.916603515625,45.652296875,-111.029396484375,47.093703125&e=EONET_6249,2022-09-11&efs=true&efa=false&efd=2022-07-29,2022-11-26&efc=dustHaze,manmade,seaLakeIce,severeStorms,snow,volcanoes,waterColor,wildfires&l=Reference_Labels_15m,Reference_Features_15m,Coastlines_15m(hidden),VIIRS_SNPP_Thermal_Anomalies_375m_Night(hidden),VIIRS_SNPP_Thermal_Anomalies_375m_Day(hidden),VIIRS_NOAA20_Thermal_Anomalies_375m_Night(hidden),VIIRS_NOAA20_Thermal_Anomalies_375m_Day(hidden),MODIS_Aqua_Thermal_Anomalies_Night(hidden),MODIS_Aqua_Thermal_Anomalies_Day(hidden),MODIS_Terra_Thermal_Anomalies_Night,MODIS_Terra_Thermal_Anomalies_Day,OMPS_Aerosol_Index(hidden),OMPS_Aerosol_Index_PyroCumuloNimbus(hidden),VIIRS_SNPP_DayNightBand_ENCC(hidden),VIIRS_SNPP_DayNightBand_At_Sensor_Radiance(hidden),VIIRS_SNPP_DayNightBand_AtSensor_M15(hidden),GMI_Rain_Rate_Asc(hidden),VIIRS_SNPP_CorrectedReflectance_BandsM11-I2-I1(hidden),VIIRS_NOAA20_CorrectedReflectance_BandsM11-I2-I1(hidden),MODIS_Aqua_CorrectedReflectance_Bands721(hidden),MODIS_Terra_CorrectedReflectance_Bands721(hidden),BlueMarble_NextGeneration(hidden),VIIRS_NOAA20_CorrectedReflectance_TrueColor(hidden),VIIRS_SNPP_CorrectedReflectance_TrueColor(hidden),MODIS_Aqua_CorrectedReflectance_TrueColor(hidden),MODIS_Terra_CorrectedReflectance_TrueColor&lg=true&t=2022-11-28-T02%3A00%3A00Z&em=true" role="application" sandbox="allow-modals allow-scripts allow-same-origin allow-forms allow-popups" width="100%" height="100%" allow="fullscreen; autoplay;" loading="lazy"></iframe>
                            </TabPane>
                            <TabPane tabId="5">
                                <Container>
                                    <Row>
                                        <Col md={12} >
                                            <embed itemprop="image" src="https://www.meteobox.com/api-svg/nigeria/a/api-com.svg?refresh=1h" type="image/svg+xml" width="300" alt="Weather Api" />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent> */}
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <p>{tab === '1' ? <Nasa /> : ''}</p>
                        <p>{tab === '2' ? <iframe src="https://www.undrr.org/" title="UNDRR" width='100%' height='500'></iframe> : ''}</p>

                        <p>{tab === '3' ? <iframe src="https://www.un-spider.org//" title="UNSpider" width='100%' height='500'></iframe> : ''}</p>

                        {/* <p>{tab === '4' ? <iframe src="https://worldview.earthdata.nasa.gov/?v=-113.916603515625,45.652296875,-111.029396484375,47.093703125&e=EONET_6249,2022-09-11&efs=true&efa=false&efd=2022-07-29,2022-11-26&efc=dustHaze,manmade,seaLakeIce,severeStorms,snow,volcanoes,waterColor,wildfires&l=Reference_Labels_15m,Reference_Features_15m,Coastlines_15m(hidden),VIIRS_SNPP_Thermal_Anomalies_375m_Night(hidden),VIIRS_SNPP_Thermal_Anomalies_375m_Day(hidden),VIIRS_NOAA20_Thermal_Anomalies_375m_Night(hidden),VIIRS_NOAA20_Thermal_Anomalies_375m_Day(hidden),MODIS_Aqua_Thermal_Anomalies_Night(hidden),MODIS_Aqua_Thermal_Anomalies_Day(hidden),MODIS_Terra_Thermal_Anomalies_Night,MODIS_Terra_Thermal_Anomalies_Day,OMPS_Aerosol_Index(hidden),OMPS_Aerosol_Index_PyroCumuloNimbus(hidden),VIIRS_SNPP_DayNightBand_ENCC(hidden),VIIRS_SNPP_DayNightBand_At_Sensor_Radiance(hidden),VIIRS_SNPP_DayNightBand_AtSensor_M15(hidden),GMI_Rain_Rate_Asc(hidden),VIIRS_SNPP_CorrectedReflectance_BandsM11-I2-I1(hidden),VIIRS_NOAA20_CorrectedReflectance_BandsM11-I2-I1(hidden),MODIS_Aqua_CorrectedReflectance_Bands721(hidden),MODIS_Terra_CorrectedReflectance_Bands721(hidden),BlueMarble_NextGeneration(hidden),VIIRS_NOAA20_CorrectedReflectance_TrueColor(hidden),VIIRS_SNPP_CorrectedReflectance_TrueColor(hidden),MODIS_Aqua_CorrectedReflectance_TrueColor(hidden),MODIS_Terra_CorrectedReflectance_TrueColor&lg=true&t=2022-11-28-T02%3A00%3A00Z&em=true" role="application" sandbox="allow-modals allow-scripts allow-same-origin allow-forms allow-popups" width="100%" height="100%" allow="fullscreen; autoplay;" loading="lazy"></iframe>
                            : ''}</p> */}
                        <p>{tab === '5' ? <embed itemprop="image" src="https://www.meteobox.com/api-svg/nigeria/a/api-com.svg?refresh=1h" type="image/svg+xml" width="300" alt="Weather Api" />
                            : ''}</p>
                        <p>{tab === '6' ? <iframe src="https://www.plantwise.org/" title="Plant Wise Plus" width='100%' height='500'></iframe> : ''}</p>
                        <p>{tab === '7' ? <iframe src="https://nimet.gov.ng/" title="nimet" width='100%' height='500'></iframe> : ''}</p>

                    </div>
                </Card>
            </Container>

        </div>
    )
}
