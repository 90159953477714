/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label, Row, Button, Card, Form } from 'reactstrap'
import { HiMapPin } from 'react-icons/hi'
import { apiURL, _postApi } from './helpers/helper';
import ReactMap from './Component/ReactMap';
import CustomButton from './UI/CustomButton'

export default function LocationForm({ form = {}, setForm = f => f, handleChange = (f) => f, geometry = {}, points = [] }) {

  return (
    <div>

      <div className="container">


        <form>

          <div className='d-flex align-items-center'>
          <button
            disabled
            className="message_button mb-3" style={{ borderRadius: 0, backgroundColor: '#F2F2F2', marginRight:10, color: 'black', padding: '5px 20px' }}>
            Set Location By
          </button>
            <FormGroup style={{ marginRight: 100 }}>
              <Label >
                <Input type='radio' name='order' value={'coordinates'} selected onChange={handleChange} />
                &nbsp;  Gis
              </Label>
            </FormGroup>
            <FormGroup >
              <Label >
                <Input type='radio' name='order' value={'gis'} onChange={handleChange} />
                &nbsp; Coordinate
              </Label>
            </FormGroup>
          </div>

          <Row>
            {/*  form.order === 'gis' ? <FormGroup >
              <Label >Coordinates</Label>
              <input className='inputs' type="text" name="geometry" disabled value={form.geometry} />
            </FormGroup>
              : <FormGroup>
                <Label htmlFor="fulladdressText">Full address</Label>
                <div className="input-group">
                  <input type="text" name="location_name"
                    value={form.location_name}
                    onChange={handleChange} className="inputs" placeholder="Enter full address" />
                  <span className="input-group-btn">
                    {/* <Button id="geolocateButton" color={'secondary'} data-toggle="tooltip" data-placement="top" title="Use your current location" data-title-clear="Clear address field" data-title-geolocate="Use your current location" data-title-loading="Please wait, finding your loaction...">N</Button> *
          </span>
      </div>
    </FormGroup>
    **/}
          </Row >
          <Col md={12} >
            {form.order === 'gis' ? null: <div id="map-container">
              {/* {JSON.stringify(form)} */}
              <ReactMap setForm={setForm} height='70vh' form={form} geometry={geometry} points={points} />
            </div>}
          </Col>
          {form.order === 'gis' ? <Row>
            <Col md={6}>
              <FormGroup >
                <label className='label_'>Latitude</label>
                <input className='input_field p-2' disabled={form.order === 'gis'} type="number" onChange={handleChange} name="latitude" value={form.latitude} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup >
                <label className='label_'>Longitude</label>
                <input className='input_field p-2' disabled={form.order === 'gis'} type="number" onChange={handleChange} name="longitude" value={form.longitude} />
              </FormGroup>
            </Col>
          </Row> : ""}
          
        </form >

      </div >
    </div >
  )
}

export function LocationMessage({ latitude, longitude }) {
  const _form = {
    entry_type: "",
    location_name: "",
    longitude: "",
    latitude: "",
    severity: "",
    description: '',
    disaster_type: '',
    geometry: '',
    latitude,
    longitude,
    status: 'new-report'
  }
  const [form, setForm] = useState(_form)
  const [loading, setLoading] = useState(false)
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    console.log(form);
    _postApi(
      `/public-reports?query_type=create`,
      form,
      (data) => {
        if (data.success) {
          alert("Submitted")
          setLoading(false)
          setForm(_form)
        }
      },
      () => {
        alert("Error: failse to submit")
        setLoading(false)
      }
    )
  }

  const types = ['Flooding', 'Pests', 'Diseases', 'Rust', 'Maize diseases', 'Rice siseases', 'Tomato diseases']
  return (
    <div>
      {/* <Card body > */}
      <Row>
        {/* {JSON.stringify(form)} */}
        <Form onSubmit={handleSubmit}>
          <Col md={12} >
            <FormGroup>
              {/* <Label htmlFor="nameText">Location Name</Label> */}
              <Input type="text" className="form-control" id="nameText" name='lat' hidden onChange={handleChange} value={form.latitude} />
              <Input type="text" className="form-control" id="nameText" hidden name='long ' onChange={handleChange} value={form.longitude} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="reprot_type">Report Type</Label>
              <Input required={true} type="select" className="form-control" id="disaster_type" name='disaster_type' value={form.disaster_type} onChange={handleChange}>
                <option value=''>Select Disaster Type</option>
                {types.map(t => <option>{t}</option>)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Message">Message</Label>
              <Input type="textarea" className="form-control" id="Message" placeholder="Enter your message" name='description' value={form.description} onChange={handleChange} />
            </FormGroup>
            <center><CustomButton loading={loading} onClick={handleSubmit} type='submit' color='primary' className='p-2 btn btn-sm'>Submit</CustomButton></center>
          </Col>
        </Form>
      </Row>
      {/* </Card> */}
    </div>
  )
}