import { ADD_GEOJSON, AUDIO_VALUE, SELECT_GEOJSON, TOGGLE_MENU } from "./types";

export const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

export const initialState = {
  selectedLayer: null,
  layers: JSON.parse(localStorage.getItem("layers")) || [],
  audio: "",
  toggle: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GEOJSON:
      return { ...state, layers: [...state.layers, action.payload] };
    case SELECT_GEOJSON:
      return { ...state, selectedLayer: action.payload };
    case AUDIO_VALUE:
      return { ...state, audio: action.payload };
    case TOGGLE_MENU:
      return { ...state, toggle: !state.toggle };
    default:
      return state;
  }
};
