export const defaultPointStyle = {
    size: 10,
    color: { r: 0, g: 0, b: 1 },
    opacity: 0.8,
    outlineSize: 0,
    outlineColor: { r: 0, g: 0, b: 0 },
    outlineOpacity: 0.8
};
export const defaultLineStyle = {
    size: 5,
    color: { r: 0, g: 0, b: 1 },
    opacity: 0.8,
    outlineSize: 0,
    outlineColor: { r: 0, g: 0, b: 0 },
    outlineOpacity: 0.8
};
export const defaultPolygonStyle = {
    color: { r: 0, g: 0, b: 1 },
    opacity: 0.5,
    outlineSize: 0,
    outlineColor: { r: 0, g: 0, b: 0 },
    outlineOpacity: 0.8
};
export function resolveStyle(output, feature, styleOption, defaultStyle) {
    // @ts-ignore
    Object.keys(defaultStyle).forEach(key => output[key] = defaultStyle[key]);
    if (styleOption != null) {
        const partialStyle = typeof styleOption === 'object' ? styleOption : styleOption(feature);
        // @ts-ignore
        Object.keys(partialStyle).forEach(key => output[key] = partialStyle[key]);
    }
}
export function resolvePointStyle(output, feature, styleOption) {
    resolveStyle(output, feature, styleOption, defaultPointStyle);
    if (output.outlineSize <= 0) {
        output.outlineColor = output.color;
        output.outlineOpacity = output.opacity;
    }
}
export function resolveLineStyle(output, feature, styleOption) {
    resolveStyle(output, feature, styleOption, defaultLineStyle);
    if (output.outlineSize <= 0) {
        output.outlineColor = output.color;
        output.outlineOpacity = output.opacity;
    }
}
export function resolvePolygonStyle(output, feature, styleOption) {
    resolveStyle(output, feature, styleOption, defaultPolygonStyle);
    if (output.outlineSize <= 0) {
        output.outlineColor = output.color;
        output.outlineOpacity = output.opacity;
    }
}
