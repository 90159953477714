import { useRef } from 'react';
import { useEffect } from 'react';
export const notInitialised = Symbol('notInitialised');
export function useLazyRef(constructor) {
    const ref = useRef(notInitialised);
    if (ref.current === notInitialised) {
        ref.current = constructor();
    }
    return ref;
}
export function usePrevious(value) {
    const ref = useRef(notInitialised);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
export function useValueAsRef(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
}
