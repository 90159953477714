import React from 'react'
import './Register.css'
import { BiChevronRight } from 'react-icons/bi'
import { Row, Col } from 'reactstrap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../../redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from "react-toast-notifications";
import _customNotification from '../../UI/CustomNotification'
export default function SignIn() {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [display, setDisplay] = useState(false)
  const [form, setLogin] = useState({
    email: '',
    password: '',
  })

  const handleChange = ({ target: { name, value } }) => {
    setLogin((prev) => ({ ...prev, [name]: value }))
  }
  const { addToast } = useToasts()
  
  const handleSubmit = () => {
    setLoading(true)
    console.log(form)
    dispatch(
      login(
        form,
        () => {

          // setLoading(false)
          // navigate('/app/dashboard')

          console.log(form)
        },
        (e) => {
          setLoading(false)
          // alert(JSON.stringify(e))
          console.log(e.error)
        },(datas)=>{
          // alert(JSON.stringify(datas))
          setLoading(false)
          datas.password? addToast(datas?.password, { appearance: 'error' }): addToast(datas?.email, { appearance: 'error' })
          // datas.email? addToast(datas?.email, { appearance: 'error' }):""
          if(datas?.success){
                 setLoading(false)
          navigate('/app/dashboard')
          }
          ;
          addToast(datas?.email, { appearance: 'error' });;
         
        }
      ),
    )
  }
  return (
    <div>
      <div className="signin-main">
        <div className="sign-in-body">
          {/* {JSON.stringify({ loading })} */}
          <div >
            <>
              <Row>
                {/* <Col md={1}></Col> */}
                <Col md={12}>
                  <div className="form-row">
                    <p style={{ color: 'red', margin: 0, fontSize: 12 }}>
                      {/* {errors.email} */}
                    </p>
                    <label className='label_'>Email</label>
                    <input
                      className="mb-4 input_field p-2"
                      id="username"
                      type="text"
                      // placeholder="Email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <p style={{ color: 'red', margin: 0, fontSize: 12 }}>
                      {/* {errors.password} */}
                    </p>
                    <label className='label_'>Password</label>
                    <input
                      className="mb-4 input_field p-2"
                      id="password"
                      type='password'
                      // placeholder="password "
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                    />
               
                    <div className="text-center mt-3">
                      <p className="auth_info p-0">
                        Forget password? | Click here to reset.
                      </p>
                    </div>
                    <button
                      className="primary_button"
                      style={{ width: '100%' }}
                      onClick={()=>handleSubmit()}
                    >
                      {loading ? (
                        <span>Loading...</span>
                      ) : (
                        <span>
                          Go <BiChevronRight size={20} />
                        </span>
                      )}
                    </button>
                  </div>
                </Col>
                {/* <Col md={1}></Col> */}
              </Row>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}
