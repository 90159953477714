import React, { useState } from "react";
import { FaAlignJustify, FaPowerOff } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_MENU } from "../../redux/types";
import { logout } from "../../redux/actions/auth";
import user_pic from "../Images/profile.jpg";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function Navbar() {
  const { authenticated, user } = useSelector(s => s.auth)

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  // const _toggle = () => {
  //   dispatch({ type: TOGGLE_MENU });
  // };

  const toogleShow = () => {
    setShow(!show);
  };

  const _logout = () => {
    dispatch(
      logout(() => {
        navigate("/");
        console.log("Loging out");
      })
    );
    toogleShow();
  };

  return (
    <div className="navb  d-flex justify-content-between">
      <div>
        {/* <FaAlignJustify
          size={20}
          onClick={_toggle}
          style={{ cursor: "pointer" }}
        /> */}
        {/* {JSON.stringify(nee)} */}
      </div>
      <div
        className="relative text-center d-flex align-items-center"
        style={{ cursor: "pointer" }}>

        <p className="username" style={{ color: "black", fontWeight: 'bold', fontSize: 30 }}>
          {/* {JSON.stringify(user)} */}
          {/* Kano state Association of Farmers */}
                    {user?.org_name}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          height: "10px",
        }}
      >
        <div
          className="relative"
          style={{ cursor: "pointer" }}>

          <img src={user_pic} style={{ width: 30, height: 30, marginRight: 15 }} alt="user" />
        </div>
        {" "}
        <div
          className="relative"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/operator/notifications")}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span className="absolute">1</span>
            </div>
          </div>
          <BsBell size="1.2em" style={{ zIndex: 1, marginRight: "25px" }} />
        </div>
        <div
          className="relative"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/operator/notifications")}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span className="absolute">1</span>
            </div>
          </div>
          <AiOutlineMail
            size="1.1em"
            style={{ zIndex: 1, marginRight: "25px" }}
          />
        </div>
        <FaPowerOff
          size="1em"
          className="text-muted"
          onClick={toogleShow}
          style={{
            zIndex: 1,
            marginRight: "25px",
            marginTop: "3px",
            cursor: "pointer",
          }}
        />
        <div
          className="bell_user_icon_div"
          onClick={() => {
            location.pathname.includes("operator")
              ? navigate("/operator/profile")
              : navigate("admin/profile");
          }}
        ></div>
      </div>
      <Modal isOpen={show} toggle={toogleShow}>
        <ModalHeader toggle={toogleShow} className="text-danger">
          Are you sure?
        </ModalHeader>
        {/* <ModalBody>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </ModalBody> */}
        <ModalFooter>
          <Button color="primary" onClick={toogleShow}>
            Cancel
          </Button>
          <Button color="danger" onClick={() => _logout()}>
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
