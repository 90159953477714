import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Table } from 'reactstrap'
import { HiOutlinePencil, HiDownload } from 'react-icons/hi'
import { MdSend, MdCancelScheduleSend } from 'react-icons/md'
import { Modal } from 'reactstrap'
import { primaryColor } from './colors'
import { BsCheckLg, BsX } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { CSVLink } from "react-csv";
import { _fetchApi } from './helpers/helper'
import { ArrowLeft } from 'react-feather'
export default function Message() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const toggle = () => {
        setOpen(!open)
    }
    const { user } = useSelector((state) => state.auth)
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchMessage, setSearchMessage] = useState('')

   

    const goto = useNavigate()
    const messageCard = [
        {
            title: 'Sent Messages',
        },
        {
            title: 'Delivered Messages',
        },
        {
            title: 'Failed Messages',
        },
    ]
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        setLoading(true)
        _fetchApi(
            `/messages/getAllmessages?org_id=${user.org_id}`,
            // {org_id:user.org_id},
            (response) => {
                setLoading(false)
                if (response.success) setMessages(response.results)
                console.log({ response })
            },
            (error) => {
                setLoading(false)
                console.error(error)
            },
        )
    }, [user.org_id])

const [link,setLink]=useState()
const vidUrl = 'https://yge.wvi.mybluehost.me/test/ews/uploads'
    const search = []
        messages&&
            messages.forEach((item) => {
                if (item.msg_name.toLowerCase().indexOf(searchMessage.toLowerCase()) === -1) 
                    return;
                    search.push(item)
            })

    return (
        <div>
            {/* {JSON.stringify(search)} */}
            <Card className="dashboard_card m-3 shadow-sm p-4">
                <Row>
                    <Col md={6}>
                        <h4 className="card_title mb-4"><ArrowLeft className='back_' onClick={() => navigate(-1)} />{' '}Messages</h4>
                    </Col>
                    <Col md={6}>
                        {/* <button
              className="primary_button"
              onClick={() => goto('/send-message')}
              style={{ float: 'right' }}
            >
              Create message
            </button> */}
                    </Col>
                </Row>
                <div className="buttons_div">
                    <button
                        style={{ marginRight: 10 }}
                        className="message_button"
                        onClick={() => goto('/app/create-message')}
                    >
                        <HiOutlinePencil size="1.2rem" /> Compose message
                    </button>
                    <button
                        style={{ marginRight: 10, float: 'right' }}
                        className="message_button"
                    >
                        <CSVLink
                            data={messages}
                            className='csv_link'
                            filename={"Message Data"}
                        >
                            <HiDownload size="1.2rem" /> Download messages
                        </CSVLink>
                    </button>
                    {/* <button
                        style={{ marginRight: 10 }}
                        className="message_button"

                    >
                        <BsCheckLg size="1.2rem" /> Total Sent Messages <span style={{ fontWeight: 'bold' }}>(2)</span>
                    </button>
                    <button
                        className="message_button"

                    >
                        <BsX size="1.2rem" /> Total Failed Messages <span style={{ fontWeight: 'bold' }}>(2)</span>
                    </button> */}
                </div>
                <input 
                    type='search' 
                    className='input_field p-2 mt-3' 
                    placeholder='Search message'
                    value={searchMessage}
                    onChange= {(e) => setSearchMessage(e.target.value)}
                />
                <Row className="mt-3">
                    {!showTable ? (
                        <Table striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Date/Time</th>
                                    <th>Title</th>
                                    <th>Message Body</th>
                                    <th>Total Subscribers</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {loading ? (
                                <sapn style={{fontSize: 22}}>Loading messages...</sapn>
                            ) : (
                                <tbody>
                                    {search.map((item, index) => (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{moment(item.date).format('LLL')}</td>
                                            <td>{item.msg_name}</td>
                                            <td>{item.msg_body}</td>
                                            <td>{item.count}</td>
                                            <td>Delivered</td>
                                            <td><a onClick={()=>{toggle();setLink(item.video)}}>{}view</a></td>
                                            {/* <td>Table cell</td> */}

                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </Table>
                    ) : (
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                    <th>Table failed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                </Row>

                <Modal isOpen={open} toggle={toggle}>
                    {/* {JSON.stringify(link)} */}
                    <audio controls>
                        <source src={`${vidUrl}/${link}`} type="audio/mpeg" />
                    </audio>
                    <video controls>
                        <source src={`${vidUrl}/${link}`} style={{ Height: "30px", width: '30%' }} />
                    </video>
                </Modal>
            </Card>
        </div>
    )
}
