(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("earcut"), require("gl-matrix"), require("kdbush"), require("rbush"));
	else if(typeof define === 'function' && define.amd)
		define(["earcut", "gl-matrix", "kdbush", "rbush"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("earcut"), require("gl-matrix"), require("kdbush"), require("rbush")) : factory(root["earcut"], root["gl-matrix"], root["kdbush"], root["rbush"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE_earcut__, __WEBPACK_EXTERNAL_MODULE_gl_matrix__, __WEBPACK_EXTERNAL_MODULE_kdbush__, __WEBPACK_EXTERNAL_MODULE_rbush__) {
return 