import { resolvePointStyle } from '../../shared/styles';
import { transformX, transformY } from '../../shared/geometry-functions';
const style = {};
export function simplePointToVertexArray(feature, styleOption) {
    const array = [];
    resolvePointStyle(style, feature, styleOption);
    function processSinglePoint(coords) {
        array.push(transformX(coords[0]), transformY(coords[1]), style.size, style.color.r, style.color.g, style.color.b, style.opacity);
    }
    if (feature.geometry.type === 'Point') {
        const geometry = feature.geometry;
        processSinglePoint(geometry.coordinates);
    }
    else if (feature.geometry.type === 'MultiPoint') {
        const geometry = feature.geometry;
        for (const coords of geometry.coordinates) {
            processSinglePoint(coords);
        }
    }
    return array;
}
