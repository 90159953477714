import React from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./Login";
import Signup from "./SignUp";
// import Login from './components/login.component'
// import SignUp from './components/signup.component'
import "./nav.css";
function Nav() {
  return (
    // <Router>
    <header className="header">
      <div className="container m-0 p-0">
        <nav className="nav m-0 p-0">
          <div className="nav-logo">
            <Link to="/" className="nav-logo__link" title="logo">
              <img src={require("../assets/images/logo.png")} style={{width:150, height:80}} alt="ew-logo" />
            </Link>
          </div>
          {/* <div className="nav-menu">
            <ul className="nav-menu__item">
              <li>
                <a href={() => false} className="nav-menu__link">
                  Home
                </a>
              </li>
              <li>
                <a href={() => false} className="nav-menu__link">
                  About
                </a>
              </li>
              <li>
                <Link to="/sign-in" className="nav-menu__link">
                  Admin
                </Link>
              </li>
              {/* <li><Link to="/sign-in" className="nav-menu__link">Login</Link></li> */}
          {/* </ul>
          </div> */}
        </nav>
      </div>
    </header>
    // </Router>
  );
}
export default Nav;

export function Navi() {
  return (
    <div className="mb-5">
      <nav id="navbar">
        <div className="nav-wrapper">
          <div className="logo">
            <a href="/">
              <i className="fas fa-chess-knight"></i>{" "}
              <img
                src={require("../assets/images/logo.png")}
                alt="ew-logo"
                style={{ width: "20%" }}
              />
            </a>
          </div>

          <ul id="menu">
            <li>
              <Link to={"/"}>Home </Link>
            </li>
            <li>
              <Link to={"#about"}>About </Link>
            </li>
            <li>
              <Link to={"/sign-in"}>Sign In </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="menuIcon">
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>

      <div className="overlay-menu">
        <ul id="menu">
          <li>
            <Link to={""}>Home </Link>
          </li>
          <li>
            <Link to={""}>About </Link>
          </li>
          <li>
            <Link to={"/sign-in"}>Admin </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
