import { pointToMultiPointDistanceSqr } from '../geometry-functions';
export function pointToResultsClickHandler(onClick) {
    return function (x, y, size, results, e) {
        let closestResult = results[0];
        let minDistanceSqr = Infinity;
        for (const result of results) {
            const distanceSqr = pointToMultiPointDistanceSqr(x, y, result.feature.geometry);
            if (distanceSqr < minDistanceSqr) {
                closestResult = result;
                minDistanceSqr = distanceSqr;
            }
        }
        const clickDistanceSqr = 0.25 * (size * size);
        if (minDistanceSqr <= clickDistanceSqr) {
            onClick(closestResult.feature, e);
        }
    };
}
