import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import VoiceRecord from '../VoiceRecord'

export default function MessageType() {
  return (
    <Row>
        <Col md={12}>
            <textarea
                name="description"
                className="text_area_input input_field mt-3"
                rows='13'
                placeholder="Write message"
                // value={message.description}
                // onChange={handleChange}
                type="textarea"
                maxLength='160'
            />
        </Col>
    </Row>
  )
}

export function VideoMessage(){
    const [video, setVideo] = useState()
    const addFileVideo = (e) => {
        if (e.target.files[0]) {
        setVideo(URL.createObjectURL(e.target.files[0]));
        }
    };
    return(
        <>
            <Row>
                <Col md= {12}>
                    <div className='input_field_message'>
                        <input 
                        type= 'file'
                        accept="video/mp4"
                        onChange={addFileVideo}
                        />
                    </div>
                        {video && <video controls>
                            <source src={video} style={{Height: "100px"}}/>
                        </video>}
                </Col>
            </Row>
        </>
    )
}

export function VioceMessage(){
    const [audio, setAudio] = useState()
    const addFileAudio = (e) => {
        if (e.target.files[0]) {
        setAudio(URL.createObjectURL(e.target.files[0]));
        }
    };

    return(
        <>
            <Row>
                <Col md= {12}>
                    <div className='input_field_message'>
                        <input 
                        type= 'file'
                        accept="audio/mp3"
                        onChange={addFileAudio}
                        placeholder= 'Audio'
                        />
                        {/* <VoiceRecord /> */}
                        {/* {file && <p>Selected file: {file}</p>} */}
                    </div>
                        {audio && <audio controls>
                            <source src={audio} type="audio/mpeg" />
                        </audio>}
                </Col>
            </Row>
        </>
    )
}

export function RecordMessage(){
    return(
        <Row>
            <Col md= {12}>
                <div className='input_field_message'>
                    <VoiceRecord />
                </div>
            </Col>
        </Row>
    )
}