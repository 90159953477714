export const ADD_GEOJSON = "GEOJSON/ADD";
export const DELETE_GEOJSON = "GEOJSON/DELETE";
export const UPDATE_GEOJSON = "GEOJSON/UPDATE";
export const SELECT_GEOJSON = "GEOJSON/SELECT";
export const AUDIO_VALUE = "AUDIO_VALUE";
export const TOGGLE_MENU = "TOGGLE_MENU";

// user
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const LOADING_AVATAR = "LOADING_AVATAR";
export const LOAD_AVATAR = "LOAD_AVATAR";
export const CREATING_USER = "CREATING_USER";
export const ERROR_CREATING_USER = "ERROR_CREATING_USER";
export const GET_ROLES = "GET_ROLES";
export const GET_USERS = "GET_USERS";
