import "./styles.css";
import { TwitterTimelineEmbed,  } from 'react-twitter-embed';

export default function TwitterFeeds() {
  return (
    <div className="">
       <TwitterTimelineEmbed
        sourceType="profile"
        screenName="AlertEarly"
        options={{height: ''}}
      />
      {/* <TwitterTweetEmbed
        tweetId={'933354946111705097'}
      /> */}
      {/* <TwitterHashtagButton
        tag={'Nema'}
      />
      <TwitterFollowButton
        screenName={'AlertEarly'}
      />
       <TwitterMentionButton
        screenName={'AlertEarly'}
      /> */}
    </div>
  );
}