import { SimplePointShader } from '../../shared/shader/point/simple-point-shader';
import { FancyPointShader } from '../../shared/shader/point/fancy-point-shader';
import { simplePointToVertexArray } from '../vertex-data-mapper/simple-point-to-vertex-array';
import { fancyPointToVertexArray } from '../vertex-data-mapper/fancy-point-to-vertex-array';
import { DynamicShaderRenderer } from '../renderer/dynamic-shader-renderer';
import { DynamicSwitchRenderer } from '../renderer/dynamic-switch-renderer';
import { DynamicTiledRenderer } from '../renderer/dynamic-tiled-renderer';
export function dynamicPointRenderer(options) {
    const isSimple = options.simpleRendering != null && options.simpleRendering;
    const shaderRenderer = isSimple ?
        new DynamicShaderRenderer(new SimplePointShader(options.interpolation), simplePointToVertexArray) :
        new DynamicShaderRenderer(new FancyPointShader(options.interpolation), fancyPointToVertexArray);
    const threshold = options.tileThreshold != null ? options.tileThreshold : 100000;
    return new DynamicSwitchRenderer([
        {
            renderer: shaderRenderer,
            condition: o => o.getArray().length < threshold
        },
        {
            renderer: new DynamicTiledRenderer(shaderRenderer, options),
            condition: o => o.getArray().length >= threshold
        }
    ]);
}
