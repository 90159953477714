import React, { useEffect, useState } from "react";
import { Card, Col, Label, Row, Table, Modal } from "reactstrap";
import { _fetchApi, _postApi, unflatten } from "../helpers/helper";
import { useSelector } from "react-redux";
import SortableTree from "react-sortable-tree";
import Tree from "./tree";
import StructureTree from "./Construction";

export default function SetUp() {
  const [filterForm, setFilterForm] = useState({
    category_name: "",
    description: "",
    group_name: "",
  });
  const [groupForm, setGroupForm] = useState({
    group_name: "",
    description: "",
    group_id:""
  });

  const handleChange1 = ({ target: { name, value } }) => {
    setGroupForm((p) => ({ ...p, [name]: value }));
  };
  const handleChange = ({ target: { name, value } }) => {
    setFilterForm((p) => ({ ...p, [name]: value }));
  };
  const { user } = useSelector((state) => state.auth)
  const handleSubmit = () => {
    _postApi(`/messages/groupings?query_type=create&org_id=${user?.org_id}`,filterForm,
    (res)=>{
      if(res.success){
        alert('success')
        // getFilters()
        getGrouping();
        setFilterForm({ group_name: "",
        description: "",
        group_id:""})
      }
   console.log(res)
    },(err)=>{
console.log(err)
    }
    ) 
  };
  const postGroup =()=>{
    _postApi(`/messages/groupings?query_type=create&&org_id=${user?.org_id}`,groupForm,
    (res)=>{
      // alert(JSON.stringify(res))
      getGrouping()
      setGroupForm({ group_name: "",
      description: "",})
    },(err)=>{
      console.log(err)
    }
    )
  }
  const [filters,setFilters]=useState([])
  const [data,setData]=useState([])
  const getFilters = ()=>{
    _fetchApi (`/messages/getGroupingbYID?query_type=select&org_id=${user?.org_id}`,
    (res)=>{
      setData(res.results)   
      console.log(res)
    },(err)=>{
console.log(err)
    }
    ) 
  }
  const [group,setGroup]=useState([])
  const getGrouping = ()=>{
    _fetchApi (`/messages/getGrouping?query_type=select&org_id=${user?.org_id}`,
    (res)=>{
      const flat =unflatten(res.results)
      setFilters(res.results)   
      setGroup(flat)   
      console.log(res)
    },(err)=>{
console.log(err)
    }
    ) 
  }
  useEffect(()=>{
    getGrouping()
    getFilters()
  },[])
  return (
    <div>
      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(group&&group)} */}
      <Card className="dashboard_card m-3 shadow-sm p-4">
        <h4 className="card_title mb-4">Setup Filters</h4>

        <Row className="">
          <Col md={6} style={{ borderRight: "1px solid grey" }}>
            <h5 className="card_title">Add Filters</h5>
            <label className="label_">Group Head</label>
            <select
              className="input_field p-2"
              type="select"
              name="category_name"
              value={filterForm.category_name}
              onChange={handleChange}
            >
              <option>select</option>
              {
                filters?.map((item)=>(<option onClick={()=>setFilterForm((p)=>({...p,group_id:item.id}))}>{item.group_name}</option>))
              }
              
              </select>{" "}
            <label className="label_">Group Subhead</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              value={filterForm.group_id}
              onChange={handleChange}
            />{" "}
            <label className="label_">Description/label</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_name"
              value={filterForm.group_name}
              onChange={handleChange}
            />
            <button className="new_button mt-3" onClick={handleSubmit}>Adds</button>
            <Table bordered responsive className="mt-5">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Filters</th>
                  
                </tr>
              </thead>
              {data?.map((item, index) => (
                <tbody>
                  <tr>
                  <td>{index + 1}</td>
                  <td>{item.group_name}</td>
                  </tr>
                </tbody>
              ))} 
            </Table>
          </Col>
          <div className="col-md-6" >
               <center>
          <StructureTree
            treeData={group}
            addChild={node=>setFilterForm((p)=>({...p,category_name:node.group_name,group_id:node.id}))}
          /></center>
       {/* <Tree /> */}
       {/* <Tree /> */}
      </div>

          {/* <Col md={6}>
            <h5 className="card_title">Add Filter Types</h5>
            <label className="label_">Category Name</label>
            <select
              className="input_field p-2"
              type="text"
              name="filter"
              value={groupForm.filter}
              onChange={handleChange1}
            >
             {filters?.map(i=><option onClick={()=>setGroupForm((p)=>({...p,class_id:i.id}))}>{i.category_name}</option>)}
              
              
            </select>{" "}
            <label className="label_">Name</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_name"
              value={groupForm.group_name}
              onChange={handleChange1}
            />
            <button className="new_button mt-3" onClick={postGroup}>
              Add
            </button>
            {/* </div> */}
            {/* <Table responsive className="mt-5">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Group Name</th>
                  <th>Description</th>
                </tr>
              </thead>

             {group?.map((item,index)=> <tbody>
                  <td>{index + 1}</td>
                  <td>{item.group_name}</td>
                  <td>{item.id}</td>
                </tbody>)}
            </Table>
          // </Col> */} 
        </Row>
      </Card>
    </div>
  );
}
