import { createStore, applyMiddleware } from "redux";
// import PouchDB from 'pouchdb-browser';
// import { persistStore } from 'redux-pouchdb';
import thunk from "redux-thunk";
import rootReducer from ".";
// import logger from "redux-logger";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(rootReducer);
// persistStore(store);

export default store;
