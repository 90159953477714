import { Typeahead } from 'react-bootstrap-typeahead'
import { Col, Row } from 'reactstrap'
import { localGovs } from './pages/lgas'
import LocalGovernment from './pages/LocalGovernment';
import { useEffect, useState } from 'react';
export default function Locations({
  options,
  // onClick,
  // onInputChange,
  onChange,
  multiSelections,
}) {
  const [lga,setLga]=useState([])
  const getSelect = ()=>{
    let selected = LocalGovernment.find((a) => a.state === 'Kano');
    setLga(selected?.lgas);
  }
  useEffect(
    ()=>{ 
      getSelect()
    },[]
)
  return (
    <div>
      {/* {JSON.stringify(lga&&lga)} */}
      <Row className="" style={{ display: 'flex', alignItems: 'center' }}>
        <Col md={12}>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="name"
            multiple
            // onChange={setMultiSelections}
            options={lga&&lga}
            // onClick={onClick}
            onChange={onChange}
            placeholder="Select Locations."
            selected={multiSelections}
            name="farming type"
            className="input_field"
          // onInputChange={}
          />
        </Col>
        {/* <Col md={2}>
          <button className="primary_button">Add</button>
        </Col> */}
      </Row>
    </div>
  )
}
