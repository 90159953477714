import moment from 'moment';
import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Mail } from 'react-feather';
import { FaArrowAltCircleLeft, FaEye } from 'react-icons/fa';
// import { MdMapsHomeWork } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Card, Col, Input, Row, Table } from 'reactstrap'
import { _fetchApi } from '../helpers/helper';
import { CustomButton } from '../UI';
import CustomModal from '../UI/CustomModal';
import GoogleMap from '../Component/GoogleMap'
import CustomScrollbar from '../UI/CustomScrollbar';
import DaterangeSelector from '../UI/DaterangeSelector';

function Departmental() {
  const [reports, setReports] = useState([])
  const [report, setReport] = useState({})
  const [isOpen, setIsOppen] = useState(false)
  const toggle = () => setIsOppen(!isOpen)
  const [polygons, setPolygons] = useState({})
  const to = moment().format('YYYY-MM-DD')
  const change = moment(to, "YYYY-MM-DD").add(-1, 'day')
  const from = moment(change).format('YYYY-MM-DD');

  const [form, setForm] = useState({ from, to, disaster_type: '' })

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const fetchReports = useCallback(() => {
    _fetchApi(
      `/get-public-reports?query_type=select&date_from=${form.from}&date_to=${form.to}&disaster_type=${form.disaster_type}`,
      (resp) => {
        if (resp.success && resp.results) {
          console.log({ results: resp.results });
          setReports(resp.results)
        }
      },
      (error => console.log({ error }))
    )
  }, [form])
  useEffect(() => {
    fetchReports()
  }, [0, fetchReports])
  const getPolygons = useCallback(() => {
    _fetchApi(
      `/get-admin-report-json?query_type=select&id=${report.id}`,
      (data) => {
        if (data.success) {
          setPolygons(data.results);
        }
      }
    );
  }, [report])
  useEffect(() => getPolygons(), [getPolygons]);
  const navigate = useNavigate()
  const types = ['Flooding', 'Earh quake', 'Wild fire', 'Fire outbreak', 'Volcanic eruption', 'Drought', 'Landslide', 'Tsunami', 'Turnado', 'Heat wave',
    'Thunderstorm', 'Fire', 'Extreem weather', 'Explosion', 'Sinkhole', 'Accident', 'Bioterrosim', 'Hazard Material', 'Cold wave', 'Ice storm']

  return (
    <div className='mt-0'>
      {/* {JSON.stringify(form)} */}
      <Card className='admin-card p-3'>
        <Row className='m-0 p-0'>
          <Col md={1}>
          <button className="new_button mt-4" onClick={() => navigate(-1)}>Back</button>
            {/* <CustomButton onClick={() => navigate(-1)}><FaArrowAltCircleLeft size={25} /></CustomButton> */}
          </Col>
          <Col md={3}>
            <h5 className='mt-4'>Public reports <span className='text-danger '>({reports.length})</span></h5>
          </Col>
          <Col md={2}>
            <select value={form.disaster_type} className="input_field mt-4"  name="disaster_type" onChange={handleChange} >
              <option value={''}>Sort</option>
              {types.map(item => <option value={item}>{item} reports</option>)}
            </select>
          </Col>
          <Col>
            <DaterangeSelector mini from={form.from} to={form.to} handleChange={handleChange} />
          </Col>
        </Row>

        {/* {JSON.stringify(message)} */}
        <CustomScrollbar height='85vh'>
          <Table striped className=' shadow-sm p-2 m-1'>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Disaster type</th>
                <th>Description</th>
                <th>status</th>
                <th className='text-center' colSpan={2}>action</th>
              </tr>
            </thead>
            <tbody>
              {
                reports && reports.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.disaster_type}</td>
                    <td>{item.description}</td>
                    <td>{item.status}</td>
                    <td className='text-center'><CustomButton title='View' onClick={() => { setReport(item); toggle() }}><FaEye /></CustomButton></td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </CustomScrollbar>

      </Card>
      <CustomModal
        toggle={toggle}
        isOpen={isOpen}
        size={'lg'}>
        {report.latitude ? <GoogleMap
          form={{ ...report }}
          points={[report]}

        /> : null}
      </CustomModal>
    </div>
  )
}

export default Departmental