import RBush from 'rbush';
import { packFeature } from '../../shared/geometry-functions';
import { resolveVisibility } from '../../shared/visibility';
export class RBushClickProvider {
    constructor(resultsHandler, clickSize) {
        this.resultsHandler = resultsHandler;
        this.clickSize = clickSize;
        this.map = null;
        this.tree = null;
        this.visibility = true;
        this.clickHandler = (e) => {
            if (this.map == null || this.tree == null) {
                return;
            }
            if (!resolveVisibility(this.visibility, this.map)) {
                return;
            }
            const bounds = this.map.getBounds();
            const canvas = this.map.getCanvas();
            const clickSize = this.clickSize != null ? this.clickSize : 16;
            const x = e.lngLat.lng;
            const y = e.lngLat.lat;
            const w = clickSize * (bounds.getEast() - bounds.getWest()) / canvas.width;
            const h = clickSize * (bounds.getNorth() - bounds.getSouth()) / canvas.height;
            const results = this.tree.search({
                minX: x - 0.5 * w,
                minY: y - 0.5 * h,
                maxX: x + 0.5 * w,
                maxY: y + 0.5 * h
            });
            this.resultsHandler(x, y, Math.max(w, h), results, e);
        };
    }
    setData(data) {
        const packedData = data.features.map((feature, index) => packFeature(feature, index));
        this.tree = new RBush();
        this.tree.load(packedData);
    }
    clearData() {
        this.tree = null;
    }
    initialise(map) {
        this.map = map;
        map.on('click', this.clickHandler);
    }
    dispose(map) {
        this.map = null;
        map.off('click', this.clickHandler);
    }
    setVisibility(visibility) {
        this.visibility = visibility;
    }
}
