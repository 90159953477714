
import React from 'react'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { Button, Card, Col, Row } from 'reactstrap'

export default function Subscription() {
  return (
    <div>
       <Card className="dashboard_card m-3 shadow-sm p-4">
       <Row className="">

        <Col md={8}>
       <Row className="">
          <Col md={4} >
          <label className="label_">Card Number</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
            </Col >
            <Col md={4} >
          <label className="label_">Expiration Date</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
            </Col >
            <Col md={4} >
          <label className="label_">CVV</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
            </Col >
            <Col md={4} >
          <label className="label_">Card Holder Name</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
             </Col >
             <Col md={4}>
              <label className="label_">Address</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
             </Col >
             <Col md={4}>
              <label className="label_">City</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
             </Col >
             <Col md={4}>
              <label className="label_">State</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
             </Col >
             <Col md={4}>
              <label className="label_">Zip Code</label>
            <input
              className="input_field p-2"
              type="text"
              name="group_id"
              // value={filterForm.group_id}
              // onChange={handleChange}
            />
            </Col >
            <Col md={4}>
              <FaCcVisa size={40}  className='m-2 mt-4'/>
              <FaCcMastercard size={40} color='orange' className='mt-3'/>
            </Col>
            </Row>
            </Col>
            </Row>
            <Col md={8}><Button className='btn btn-primary'>Checkout</Button></Col>
       </Card>
    </div>
  )
}
