import { isPointInPolygon } from '../geometry-functions';
export function polygonToResultsClickHandler(onClick) {
    return function (x, y, size, results, e) {
        let closestResult = null;
        let closestIndex = -1;
        for (const result of results) {
            if (result.index > closestIndex && isPointInPolygon(x, y, result.feature.geometry)) {
                closestResult = result;
                closestIndex = result.index;
            }
        }
        if (closestResult != null) {
            onClick(closestResult.feature, e);
        }
    };
}
