import vertexSource from './default.vert';
import fragmentSource from './default.frag';
export class DefaultShader {
    constructor() {
        this.vertexSource = vertexSource;
        this.fragmentSource = fragmentSource;
    }
    configureAttributes(gl, program) {
        const position = gl.getAttribLocation(program, 'a_position');
        const color = gl.getAttribLocation(program, 'a_color');
        const vertexSize = 6 * Float32Array.BYTES_PER_ELEMENT;
        gl.vertexAttribPointer(position, 2, gl.FLOAT, false, vertexSize, 0);
        gl.vertexAttribPointer(color, 4, gl.FLOAT, false, vertexSize, 2 * Float32Array.BYTES_PER_ELEMENT);
        gl.enableVertexAttribArray(position);
        gl.enableVertexAttribArray(color);
        return () => {
            gl.disableVertexAttribArray(position);
            gl.disableVertexAttribArray(color);
        };
    }
    getArrayBufferElementsPerVertex() {
        return 6;
    }
    setUniforms(gl, program, matrix) {
        gl.uniformMatrix4fv(gl.getUniformLocation(program, 'u_matrix'), false, matrix);
    }
}
