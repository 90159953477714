import { resolvePointStyle } from '../../shared/styles';
import { transformX, transformY } from '../../shared/geometry-functions';
export function fancyPointsToShaderBuffers(data, styleOption) {
    const array = [];
    function processSinglePoint(coords, style) {
        array.push(transformX(coords[0]), transformY(coords[1]), style.size, style.color.r, style.color.g, style.color.b, style.opacity, style.outlineSize, style.outlineColor.r, style.outlineColor.g, style.outlineColor.b, style.outlineOpacity);
    }
    const style = {};
    for (const feature of data.features) {
        resolvePointStyle(style, feature, styleOption);
        if (feature.geometry.type === 'Point') {
            const geometry = feature.geometry;
            processSinglePoint(geometry.coordinates, style);
        }
        else if (feature.geometry.type === 'MultiPoint') {
            const geometry = feature.geometry;
            for (const coords of geometry.coordinates) {
                processSinglePoint(coords, style);
            }
        }
    }
    return {
        array: new Float32Array(array),
        elementArray: null
    };
}
