import { resolveVisibility } from '../shared/visibility';
export class StaticDataLayer {
    constructor(options) {
        this.options = options;
        this.map = null;
        this.data = null;
        this.styleOption = undefined;
        this.visibility = true;
    }
    get id() {
        return this.options.id;
    }
    get renderingMode() {
        if (this.options.renderingMode == null) {
            return '2d';
        }
        return this.options.renderingMode;
    }
    get type() {
        return 'custom';
    }
    setDataAndStyle(data, styleOption) {
        this.data = data;
        this.styleOption = styleOption;
        this.options.renderer.setDataAndStyle(data, styleOption);
        if (this.options.clickProvider != null) {
            this.options.clickProvider.setData(data);
        }
        if (this.map != null) {
            this.map.triggerRepaint();
        }
    }
    setData(data) {
        this.setDataAndStyle(data, this.styleOption);
    }
    setStyle(styleOption) {
        this.styleOption = styleOption;
        if (this.data == null) {
            return;
        }
        this.options.renderer.setDataAndStyle(this.data, styleOption);
        if (this.map != null) {
            this.map.triggerRepaint();
        }
    }
    clearData() {
        this.data = null;
        this.options.renderer.clearData();
        if (this.options.clickProvider != null) {
            this.options.clickProvider.clearData();
        }
        if (this.map != null) {
            this.map.triggerRepaint();
        }
    }
    setVisibility(visibility) {
        this.visibility = visibility;
        if (this.options.clickProvider != null) {
            this.options.clickProvider.setVisibility(visibility);
        }
        if (this.map != null) {
            this.map.triggerRepaint();
        }
    }
    isVisible() {
        return resolveVisibility(this.visibility, this.map);
    }
    onAdd(map, gl) {
        gl.getExtension('OES_element_index_uint');
        this.map = map;
        this.options.renderer.initialise(map, gl);
        if (this.options.clickProvider != null) {
            this.options.clickProvider.initialise(map);
        }
    }
    onRemove(map, gl) {
        this.map = null;
        this.options.renderer.dispose(map, gl);
        if (this.options.clickProvider != null) {
            this.options.clickProvider.dispose(map);
        }
    }
    prerender(gl, matrix) {
        if (this.isVisible()) {
            this.options.renderer.prerender(gl, matrix);
        }
    }
    render(gl, matrix) {
        if (this.isVisible()) {
            this.options.renderer.render(gl, matrix);
        }
    }
}
