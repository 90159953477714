
// // import { render } from "react-dom";
// import React, { useReducer, useEffect, useRef } from "react";
// import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
// import DrawControl from "react-mapbox-gl-draw";
// import mapboxgl from "mapbox-gl";

// import { addLayer, selectLayer } from "../redux/actions";
// import { initialState, reducer } from "../redux/reducers";
// import GeoJsonModel from "../redux/GeoJsonModel";

// // import "./style2.scss";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

// import 'mapbox-gl/dist/mapbox-gl.css';

// import { Container } from "reactstrap";
// function App() {
//   const accessToken = 'pk.eyJ1IjoiaWJhZ3dhaTkiLCJhIjoiY2tweTMxODZ5MWNoZzJxcDl0ODI0dXJtdyJ9.-igEXVpIFBVzefkT89oscQ';

// mapboxgl.accessToken = accessToken

// const Map = ReactMapboxGl({
//   accessToken

// });

//   const [state, dispatch] = useReducer(reducer, initialState);

//   useEffect(() => {
//     localStorage.setItem("layers", JSON.stringify(state.layers));
//   }, [state.layers]);

//   const onDrawCreate = ({ features }) => {
//     let feature = new GeoJsonModel(features[0]);
//     console.log("feature", feature);
//     dispatch(addLayer(feature));
//   };

//   const onDrawUpdate = ({ features }) => {
//     const feature = new GeoJsonModel(features[0]);
//     console.log("feature", feature.getId());
//   };

//   const onDrawDelete = ({ features }) => {
//     const feature = features[0];
//     console.log("feature", feature);
//   };

//   const onDrawSelectionChange = ({ features }) => {
//     if (features.length > 0) {
//       const feature = new GeoJsonModel(features[0]);
//       dispatch(selectLayer(feature.getId()));
//     } else {
//       dispatch(selectLayer(null));
//     }
//   };

// const mapRef = useRef()

//   return (
//     <Container>
//       {JSON.stringify(state)}
//       <Map
//         id="map"
//         ref={mapRef}
//         style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
//         containerStyle={{
//           height: "30vh",
//           width: "40vw",
//         }}
//         zoom={[5]}
//         center={[8.9757752418518, 11.69144210646147]}
//       >
//         <DrawControl
//           position="top-right"
//           onDrawCreate={onDrawCreate}
//           onDrawUpdate={onDrawUpdate}
//           onDrawDelete={onDrawDelete}
//           onDrawSelectionChange={onDrawSelectionChange}
//         />
//         {state.layers.map((layer, key) => (
//           <GeoJSONLayer key={key} data={layer} />
//         ))}
//       </Map>
//       {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
//     </Container>
//   );
// }
// export default App


import { useCallback, useEffect, useRef, useState } from "react";

import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Card, Col, Row } from "reactstrap";
import { apiURL, _postApi } from "../helpers/helper";
import { useLocation, useNavigate, useParams } from "react-router";
import { CustomButton } from "../UI";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import ReactMap from "./ReactMap";



function App() {
  const navigate = useNavigate()
  const [results, setResults] = useState([])
  const [form, setForm] = useState({})
  const location = useLocation()
  const params = useParams();
  //   alert(params.id)
  let id = params.id;
  const getHazard = useCallback(() => {
    _postApi(
      `/test?id=${id}`,
      // form,
      {},
      (data) => {
        if (data.success) {
          setResults(data.results)
        }
      },
    )
    // )
  }, [params])
  useEffect(() => {
    getHazard();
  }, []);

  return (
    <div className="mt-3">
      <CustomButton onClick={() => navigate(-1)}><FaArrowAltCircleLeft /></CustomButton>
      <Card className='admin-card p-3'>
        {
          location.pathname === "/form/view/" ?
            "" : <Row>
              <Col md={4} className="mb-2">
                <span><b>Date :</b> <span>{results[0] && results[0].map(i => i.projected_event_date)}</span></span>
              </Col>
              <Col md={4} className="mb-2">
                <span><b>Disaster Type :</b> <span>Severe</span></span>
              </Col>
              <Col md={4} className="mb-2">
                <span><b>Hazard Type :</b> <span>{results[0] && results[0].map(i => i.disaster_type)}</span> </span>
              </Col>
              <Col md={4} className="mb-2">
                <span><b>Causes:</b> <span>{results[0] && results[0].map(i => i.causes)}</span></span>
              </Col>
              <Col md={4} className="mb-2">
                <span><b>Project Date :</b> <span>{results[0] && results[0].map(i => i.projected_event_date)}</span></span>
              </Col>
              <Col md={4} className="mb-2">
                <span><b>Period :</b> <span>{results[0] && results[0].map(i => i.event_duration_minute)}</span></span>
              </Col>
              <Col md={4} className="mb-3">
                <span><b>Location:</b> <span>{results[0] && results[0].map(i => i.event_duration_year)}</span></span>
              </Col>
              <Col md={4} className="mb-3">
                <span><b>Latitude:</b> <span>{results[0] && results[0].map(i => i.event_duration_month)}</span></span>
              </Col>
              <Col md={4} className="mb-3">
                <span><b>Longtitude:</b> <span>{results[0] && results[0].map(i => i.event_duration_hours)}</span></span>
              </Col></Row>
        }
        {/* {JSON.stringify(geomToString(area))} */}
        <ReactMap form={form} setForm={setForm} />
      </Card>
    </div>
  );
}
export default App