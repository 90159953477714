import React from "react";
import { useRoutes } from "react-router-dom";
import HazardTable from "../Component/HazarTable";
import Login from "../Component/Login";
import Map from "../Component/Map";
// import Nav from "../Component/Nav";
import Signup from "../Component/SignUp";
import Landing from "../Component/Landing";
import AppIndex from "./AppIndex";
import Master from "./Master";
import GeneralTable from "../Component/GeneralTable";
import Home from "../dashboard/Home";
import Profile from "../dashboard/Profile";
import OperatorNotification from "../dashboard/Operator/OperatorNotification";
import HazardComing from "../dashboard/HazardComing";
import Departmental from "../dashboard/Departmental";
import Nasa from "../nasa/Nasa";
import CitizenRequst from "../dashboard/Images/CitizenRequst";
import Other from "../dashboard/Other";
import NasaInfo from "../dashboard/Menu.js/NasaInfo";
import Facebook from "../dashboard/facebook";
import AgenciesReports from "../dashboard/AgenciesReports";
import CampsMap from "../pages/CampsMap";
import Subscribers from "../pages/Subscribers";
import Register from "../Component/Auth/Regista";
import CreateMessage from "../CreateMessage";
import Message from "../Messages";
import CreateSubscribers from "../pages/CreateSubscribers";
import FailedMessages from "../dashboard/FailedMessages";
import SentMessages from "../dashboard/SentMessages";
import SetUp from "../Component/SetUp";
import Subscription from "../Subscription";

// import Trial from "../Component/Trial"
export default function AppNavigation() {
  const element = useRoutes([
    {
      path: "/",
      element: <Register />,
      children: [{ index: true }],
    },

    {
      path: "/",
      element: <AppIndex />,
      children: [
        {
          path: "/app/create-message",
          element: <CreateMessage />,
        },
        {
          path:'/app/messages',
          element:<Message/>,
        },
        {
          path: "/app/nasa",
          element: <Nasa />,
        },
        {
          path: "admin/profile",
          element: <Profile />,
        },
        {
          path: "operator/notifications",
          element: <OperatorNotification />,
        },
        {
          path: "/app/dashboard",
          element: <Home />,
        },
        {
          path: "/app",
          element: <HazardTable />,
        },
        {
          path: "/app/general",
          element: <HazardComing />,
        },
        {
          path: "/app/departmental",
          element: <Departmental />,
        },
        {
          path: "/app/agencies",
          element: <AgenciesReports />,
        },
        {
          path: "/generals",
          element: <GeneralTable />,
        },
        {
          path: "/form/view/:id",
          element: <Map />,
        },
        {
          path: "/app/citizen-requst",
          element: <CitizenRequst />,
        },
        {
          path: "/app/other",
          element: <Other />,
        },
        {
          path: "/app/waring-platforms",
          element: <NasaInfo />,
        },
        {
          path: "/app/facebook",
          element: <Facebook />,
        },
        {
          path: '/app/evacuation-centers',
          element: <CampsMap />
        },
        {
          path: '/app/failed-messages',
          element: <FailedMessages />
        },
        {
          path: '/app/sent-messages',
          element: <SentMessages />
        },
        {
          path: '/app/subscribers',
          element: <Subscribers />
        },
        {
          path: '/app/create-subscribers',
          element: <CreateSubscribers />
        },
        {
          path: '/app/set-up',
          element: <SetUp />
        },{
          path:'/app/subscription',
          element:<Subscription />

        }

      ],
    },
    {
      path: "/sign-in",
      element: <Register/>,
    },
    {
      path: "/sign-up",
      element: <Signup />,
    },
  ]);
  return element;
}
