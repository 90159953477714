import React, { useState } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Button from './Button'
import TwitterFeeds from './feeds/TwitterFeeds'
import { BsFacebook, BsTwitter, BsTvFill, BsInstagram } from 'react-icons/bs'
import { IoMdRadio } from 'react-icons/io'
export default function Facebook() {
  // return (<> <Row>
  //   <Col md={2}></Col>
  //   <Col md={8}>
  //     <TwitterFeeds />
  //   </Col>
  //   <Col md={2}></Col>
  // </Row>
  // </>
  // )
  const [tab, setTab] = useState(1)

  return <div>
    <Card className="dashboard_card m-3 shadow-sm p-4">
      <Row>
        <Col md={6}>
          <h4 className="card_title mb-4">Media Walls</h4>
        </Col>
        <Col md={6}></Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className='media_card shadow p-3'>
            {/* <div className=''><BsFacebook className='media_icon' /></div> */}
            <div className='media_icon_t'><BsFacebook />{' '}Facebook</div>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcygioapp%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=733364574163630" width="100%" height="500" style={{ border: 'none', overflow: 'hidden' }} frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='media_card shadow p-3'>
            {/* <div className=''><BsTwitter className='media_icon' /></div> */}
            <div className='media_icon_t'>Twitter</div>
            <TwitterFeeds />
          </Card>
        </Col>
        <Col md={4}>
          <Card className='media_card shadow p-3'>
            {/* <div className=''><BsInstagram className='media_icon' /></div> */}
            <div className='media_icon_t'>Instagram</div>
          </Card>
        </Col>

      </Row>
      <Row className='mt-3'>
        <Col md={4}>
          <Card className='media_card shadow p-3'>
            <div className=''><IoMdRadio className='media_icon' /></div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='media_card shadow p-3'>
            <div className=''><BsTvFill className='media_icon' /></div>
          </Card>
        </Col>
      </Row>
    </Card>
    {/* <Nav tabs>
      <NavItem>
        <NavLink
          className="active"
          onClick={() => setTab(1)}
        >
          Facebook
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className=""
          onClick={() => setTab(2)}
        >
          Twitter
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={tab.toString()}>
      <TabPane tabId='1'>
        <Row>
          <Col sm="12">
            <h4>
              Facebook
            </h4>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col md="12">
            <Card body>
              <CardBody>
                <TwitterFeeds />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </TabContent> */}
  </div>
}
