import vertexSource from './fancy-polygon.vert';
import fragmentSource from './fancy-polygon.frag';
export class FancyPolygonShader {
    constructor(interpolation = 1.8) {
        this.interpolation = interpolation;
        this.vertexSource = vertexSource;
        this.fragmentSource = fragmentSource;
    }
    configureAttributes(gl, program) {
        const previousPosition = gl.getAttribLocation(program, 'a_previousPosition');
        const currentPosition = gl.getAttribLocation(program, 'a_currentPosition');
        const nextPosition = gl.getAttribLocation(program, 'a_nextPosition');
        const outlineSize = gl.getAttribLocation(program, 'a_outlineSize');
        const offset = gl.getAttribLocation(program, 'a_offset');
        const color = gl.getAttribLocation(program, 'a_color');
        const outlineColor = gl.getAttribLocation(program, 'a_outlineColor');
        const vertexSize = 16 * Float32Array.BYTES_PER_ELEMENT;
        gl.vertexAttribPointer(previousPosition, 2, gl.FLOAT, false, vertexSize, 0);
        gl.vertexAttribPointer(currentPosition, 2, gl.FLOAT, false, vertexSize, 2 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(nextPosition, 2, gl.FLOAT, false, vertexSize, 4 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(outlineSize, 1, gl.FLOAT, false, vertexSize, 6 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(offset, 1, gl.FLOAT, false, vertexSize, 7 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(color, 4, gl.FLOAT, false, vertexSize, 8 * Float32Array.BYTES_PER_ELEMENT);
        gl.vertexAttribPointer(outlineColor, 4, gl.FLOAT, false, vertexSize, 12 * Float32Array.BYTES_PER_ELEMENT);
        gl.enableVertexAttribArray(previousPosition);
        gl.enableVertexAttribArray(currentPosition);
        gl.enableVertexAttribArray(nextPosition);
        gl.enableVertexAttribArray(outlineSize);
        gl.enableVertexAttribArray(offset);
        gl.enableVertexAttribArray(color);
        gl.enableVertexAttribArray(outlineColor);
        return () => {
            gl.disableVertexAttribArray(previousPosition);
            gl.disableVertexAttribArray(currentPosition);
            gl.disableVertexAttribArray(nextPosition);
            gl.disableVertexAttribArray(outlineSize);
            gl.disableVertexAttribArray(offset);
            gl.disableVertexAttribArray(color);
            gl.disableVertexAttribArray(outlineColor);
        };
    }
    setUniforms(gl, program, matrix) {
        const viewport = gl.getParameter(gl.VIEWPORT);
        gl.uniformMatrix4fv(gl.getUniformLocation(program, 'u_matrix'), false, matrix);
        gl.uniform2f(gl.getUniformLocation(program, 'u_viewPortSize'), viewport[2], viewport[3]);
        gl.uniform1f(gl.getUniformLocation(program, 'u_interpolation'), this.interpolation);
    }
    getArrayBufferElementsPerVertex() {
        return 16;
    }
    getPrimitiveType(gl) {
        return gl.TRIANGLES;
    }
}
