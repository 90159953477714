import react, { useEffect, useState } from "react"
import { FaEye, FaPlus } from "react-icons/fa"
import { Navigate, useNavigate } from "react-router"
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap"
import { apiURL, _postApi } from "../helpers/helper"
import CustomButton from "./CustomButton"
import moment from "moment"
import SearchBar from "./SearchBar"
import AppNav from "./AppNav"

export default function HazardTable() {
  const navigate = useNavigate()
  const [results, setResults] = useState([])
  const userDetail = JSON.parse(localStorage.getItem("@@__details")) || {}
  let userId = userDetail?.facilityId
  const getHazard = () => {
    _postApi(
      `/hazards?query_type=select&userId=${userId}`,
      // form,
      {},
      (data) => {
        if (data.success) {
          setResults(data.results)
        }
      },
    )
    // )
  };
  const [general, setGeneral] = useState([])
  const getGeneral = () => {
    _postApi(
      `/general?query_type=select`,
      // form,
      {},
      (data) => {
        if (data.success) {
          setGeneral(data.results)
        }
      },
    )
    // )
  };
  useEffect(() => {
    getHazard();
    getGeneral()
  }, []);
  // const navigate = useNavigate()




  return (
    <>
      <AppNav />

      <Row className="p-0 m-0">
        <Col md={2}></Col>
        <Col md={9}>

          <Card >
            <CardHeader>
              <CustomButton className="mb-2" onClick={() => navigate("/form")}><FaPlus /> Add New </CustomButton>
            </CardHeader>
            <CardBody>
              {/* {JSON.stringify(general)} */}
              <Row className="p-0 m-0">
                <Col md={4}>
                  <Card className="shadow" style={{ height: "100%", cursor: "pointer" }} onClick={() => navigate("/generals")} >
                    <CardBody>
                      <h1 className="text-center ">{general && general.length}</h1>
                      <h4 class="text-center text-muted">Citizen Request</h4>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="shadow" style={{ height: "130px" }}>
                    <CardBody>
                      <h1 className="text-center ">{0}</h1>
                      <h4 class="text-center text-muted">Departmental Request</h4>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4}>
                  <Card className="shadow" style={{ height: "100%" }}>
                    <CardBody>
                      <h1 className="text-center ">{0}</h1>
                      <h4 class="text-center text-muted">Other Request</h4>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Table bordered className="mt-4">
                <thead>
                  <tr>
                    <th>Hazard Group</th>
                    <th>Risk Type</th>
                    <th>Causes</th>
                    <th>Event Date</th>
                    <th>Event Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {results && results.map((item, index) => (
                    <tr>
                      <td>{item.hazard_group}</td>
                      <td>{item.disaster_type}</td>
                      <td>{item.causes}</td>
                      <td>{moment(item.projected_event_date).fromNow()}</td>
                      <td>{item.event_duration_year}</td>
                      <td><CustomButton color="success" size="sm"><FaEye />View</CustomButton></td>
                    </tr>
                  ))}

                </tbody>

              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={1}></Col>
      </Row>


    </>
  )
}