import { pointRenderer } from '../renderer-preset/point-renderer';
import { StaticDataLayer } from '../static-data-layer';
import { KdBushClickProvider } from '../click-provider/kd-bush-click-provider';
export function pointLayer(options) {
    return new StaticDataLayer({
        id: options.id,
        renderer: pointRenderer(options),
        clickProvider: options.onClick != null ?
            new KdBushClickProvider(options.onClick, options.clickSize) : undefined
    });
}
