import { useEffect } from 'react';
import { useLazyRef } from '../shared/custom-hooks';
import { useMapLayer } from '../shared/use-map-layer';
export function DynamicDataLayerComponent(props) {
    const layer = useLazyRef(props.layerConstructor).current;
    const data = props.data;
    useEffect(() => {
        data(layer.dataOperations);
    }, [layer, data]);
    useEffect(() => {
        layer.setStyle(props.style);
    }, [layer, props.style]);
    useEffect(() => {
        layer.setVisibility(props.visibility);
    }, [layer, props.visibility]);
    useMapLayer(layer, props.before);
    return null;
}
