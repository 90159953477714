import React from "react";
import Chart from "react-google-charts";

export default function Charts(props) {
  const {
    chartType = "PieChart",
    data = [],
    title = "Title",
    width = "500px",
    height = "300px",
    yAxisTitle = "",
    xAxisTitle = "",
    // chartEvents = (f) => f,
  } = props;
  return (
    <div>
      <Chart
        width={width}
        height={height}
        chartType={chartType}
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          title: title,
          hAxis: {
            title: yAxisTitle,
          },
          vAxis: {
            title: xAxisTitle,
          },
        }}
        rootProps={{ "data-testid": "1" }}
        // chartEvents={chartEvents}
        {...props}
      />
    </div>
  );
}
