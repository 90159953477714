import { resolveLineStyle } from '../../shared/styles';
import { transformX, transformY } from '../../shared/geometry-functions';
export function simpleLinesToShaderBuffers(data, styleOption) {
    const array = [];
    const elementsArray = [];
    let currentIndex = 0;
    function processSingleLine(coordinates, style) {
        for (let i = 0; i < coordinates.length; i++) {
            const coords = coordinates[i];
            array.push(transformX(coords[0]), transformY(coords[1]), style.color.r, style.color.g, style.color.b, style.opacity);
            if (i === 0 || i === coordinates.length - 1) {
                elementsArray.push(currentIndex);
            }
            else {
                elementsArray.push(currentIndex, currentIndex);
            }
            currentIndex++;
        }
    }
    const style = {};
    for (const feature of data.features) {
        resolveLineStyle(style, feature, styleOption);
        if (feature.geometry.type === 'LineString') {
            const geometry = feature.geometry;
            processSingleLine(geometry.coordinates, style);
        }
        else if (feature.geometry.type === 'MultiLineString') {
            const geometry = feature.geometry;
            for (const coords of geometry.coordinates) {
                processSingleLine(coords, style);
            }
        }
    }
    return {
        array: new Float32Array(array),
        elementArray: new Int32Array(elementsArray)
    };
}
