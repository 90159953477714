import { combineReducers } from "redux";
import { appReducer } from "./appReducers";
import authReducer from "./reducers/auth";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
});

export default rootReducer;
