import { polygonRenderer } from '../renderer-preset/polygon-renderer';
import { StaticDataLayer } from '../static-data-layer';
import { polygonToResultsClickHandler } from '../../shared/click-handler/polygon-click-handler';
import { RBushClickProvider } from '../click-provider/r-bush-click-provider';
export function polygonLayer(options) {
    return new StaticDataLayer({
        id: options.id,
        renderer: polygonRenderer(options),
        clickProvider: options.onClick != null ?
            new RBushClickProvider(polygonToResultsClickHandler(options.onClick), 0) : undefined
    });
}
