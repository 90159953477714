import React from 'react'
// import { useSelector } from 'react-redux'
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import { themeClass, textClass } from '../variable'
import BackButton from './BackButton'

function CustomCard(props) {
  const { header, footer, back, headerRight } = props
  // const activeBusiness = useSelector((state) => state.app.theme)

  return (
    <Card
      className={`${props.container}`}
      // outline
      style={{
        borderWidth: 1,
        // borderColor: activeBusiness.primary_color,
        borderColor: themeClass,
        borderStyle: 'solid',
      }}
    >
      {header && (
        <CardHeader
          // className={`py-2`}
          style={{
            borderBottom: `1px solid ${themeClass}`,
            backgroundColor: themeClass,
            color: textClass || "black",
            paddingTop: '.4rem',
            paddingBottom: '.4rem',
          }}
          className={back ? 'row m-0 align-items-center' : ''}
        >
          {back && (
            <div className="col-md-3">
              <BackButton text=' Back' />
            </div>
          )}
          <h5 className={back ? 'col-md-6 text-center text-white' : 'text-center text-white'}>{header}</h5>
          {/* <div className={back ? 'col-md-6' : ''}>{header}</div> */}
          {headerRight && <div className="col-md-3">{headerRight}</div>}
        </CardHeader>
      )
      }
      <CardBody className={props.body}>{props.children}</CardBody>
      {
        footer && (
          <CardFooter
            style={{
              borderBottom: `1px solid ${themeClass}`,
              backgroundColor: themeClass,
              color: "black",
              // paddingTop: '.4rem',
              // paddingBottom: '.4rem',
            }}
          >
            {footer}
          </CardFooter>
        )
      }
    </Card >
  )
}

export default CustomCard
