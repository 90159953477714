import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function CustomModal({ header, isOpen = false, toggle = (f) => f, footer, size, footerButton, children }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="border border-primary size-sm"
      size={size ? size : 'lg'}
    >
      {header && (
        <ModalHeader className="h6 py-1 " toggle={toggle}>
          {header}
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>

      <div style={{ borderTop: '1px solid #EEE' }} className='text-right pr-10 mr-10 '>{footerButton}</div>
      {footer && <ModalFooter >{footer}</ModalFooter>}
    </Modal>
  )
}

export default CustomModal


