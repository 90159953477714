import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BsFillPinMapFill, BsPinMapFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { Card, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import CustomButton from "../Component/CustomButton";
import GoogleMap from "../Component/GoogleMap";
import ReactMap from "../Component/ReactMap";
import { capitalizeAllFirstLetter, _fetchApi, _postApi } from "../helpers/helper";
import { CustomForm } from "../UI";
import CustomCard from "../UI/CustomCard";
import CustomModal from "../UI/CustomModal";
const CampsMap = () => {
  const to = moment().format('YYYY-MM-DD')
  const from = moment(moment(to, "YYYY-MM-DD").add(-1, 'days')).format('YYYY-MM-DD');
  const [current, setCurrent] = useState({})
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])
  const [wards, setWards] = useState([])
  const [camps, setCamps] = useState([])
  const [isOpen, setIsOppen] = useState(false)
  const [geojson, setGeojson] = useState({})
  const toggle = () => setIsOppen(!isOpen)

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'state_name') {
      setForm((p) => ({ ...p, type: 'lgas' }));
      if (form.geometry.length > 10) {
        const str = form.geometry.split(',')[0]
        const loc = str.length ? str.split(' ') : []
        setForm((p) => ({
          ...p,
          latitude: loc.length ? loc[0] : form.latitude,
          longitude: loc.length ? loc[1] : form.longitude,
        }))
      }
    } else if (name === 'lga_name') {
      setForm((p) => ({ ...p, type: 'wards' }));
    }
    setForm((p) => ({ ...p, [name]: value }));
  };
  // const navigate = useNavigate()
  const _form = {
    query_type: "create",
    longitude: "",
    latitude: "",
    edit_map: false,
    geometry: '',
    status: 'new-camp',
    facility_name: '',
    facility_address: '',
    state_name: '',
    lga_lga: '',
    type: 'states',
    from,
    to,
    id: ''
  }

  const [form, setForm] = useState(_form)
  // const [center, setCenter] = useState(_form)
  const handleSubmit = () => {
    alert('Submitted');
    _postApi('/post-camp',
      form,
      () => {
        setForm(_form);
        setIsOppen(false)
      }, () => {
        alert('Fail to submit');
      })
  }

  const fields = [{
    type: 'text',
    name: 'facility_name',
    label: 'Facility name',
    value: form.facility_name,
    col: 6,
  },
  {
    type: 'select',
    name: 'state_name',
    label: 'State located',
    options: states.map(s => s.name),
    value: form.state,
    col: 6,
  },
  {
    type: 'select',
    name: 'lga_name',
    label: 'LGA located',
    options: lgas.map(l => l.name),
    value: form.lga,
    col: 6,
  },
  {
    type: 'select',
    name: 'ward_name',
    label: 'Location',
    options: wards.map(w => w.name),
    value: form.lga,
    col: 6,
  },
  {
    type: 'text',
    name: 'facility_address',
    label: 'Facility address',
    value: form.facility_address,
    col: 6,
  },
  {
    type: 'custom',
    component: () => <Col className="mt-4"><CustomButton className='mt-4' block onClick={handleSubmit}>Submit</CustomButton></Col>,
    col: 6,
  }]

  const setEditMap = () => {
    setForm((p) => ({ ...p, edit_map: !p.edit_map }))
    if (form.geometry && form.edit_map) {
      setIsOppen(true)
    }
  }
  let geoId;
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('user_location'))
    const user_location = cache ? cache : null
    if (user_location && user_location.latitude) {
      setForm(p => ({
        ...p,
        latitude: user_location.latitude,
        longitude: user_location.longitude
      }))
    } else {
      geoId = window.navigator.geolocation.watchPosition(position => {
        setForm(p => ({
          ...p,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
        localStorage.setItem('user_location', JSON.stringify({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
      })
      return () => {
        navigator.geolocation.clearWatch(geoId)
      }
    }
  }, [])

  const handleProps = (cur) => {
    setCurrent(cur)
    if (form.geometry && form.geometry.length > 10) {
      toggle()
    }
  }


  const getLocations = useCallback(() => {
    _fetchApi(
      `/get-locations?country_code=NG&state=${form.state_name}&type=${form.type}&lga=${form.lga_name}`,
      (data) => {
        if (data.success) {
          switch (form.type) {
            case 'states':
              setStates(data.results.map(s => ({ name: s.state_name })))
              break;
            case 'lgas':
              setLgas(data.results.map(s => ({ name: s.lga_name })))
              break;
            case 'wards':
              setWards(data.results.map(w => ({ name: w.ward_name })))
              break;
          }
        }
      },
      // error => alert(JSON.stringify(error))
    );
  }, [0, form.state, form.lga, form.type]);

  useEffect(() => getLocations(), [getLocations]);
  const getCamps = useCallback(() => {
    _fetchApi(
      `/get-camps?query_type=select&country_code=${'NG'}&status=${form.status}&id=${form.id}`,
      (data) => {
        if (data.success) {
          setCamps(data.results);
        }
      },
      // error => alert(JSON.stringify(error))
    );
  }, [0, form]);
  useEffect(() => getCamps(), [getCamps]);

  const getCampJson = useCallback(() => {
    _fetchApi(
      `/get-camp-json?query_type=select&country_code=${'NG'}&status=${form.status}`,
      (data) => {
        if (data.success) {
          setGeojson(data.results);
        }
      },
      // error => alert(JSON.stringify(error))
    );
  }, [0, form]);
  useEffect(() => getCampJson(), [getCampJson]);
  const points = camps.map(c => ({
    ...c, latitude: parseFloat(c.geometry.replace('POLYGON((', '').replace('))', '').split(' ')[1]),
    longitude: parseFloat(c.geometry.replace('POLYGON((', '').replace('))', '').split(' ')[0])
  }))
  return (
    <Row>
      {/* {JSON.stringify({ form })} */}
      <Col md={3}>
        <Nav>
          <NavItem>
            <CustomButton onClick={() => setEditMap()}>{form.edit_map ? 'Save camp' : 'Create Camp'}</CustomButton>
          </NavItem>
        </Nav>
        <div style={{ height: '70vh', overflow: 'scroll' }}>
          <Nav>
            <NavItem style={{ cursor: 'pointer' }}>
              {camps.map((itm, k) => <NavLink key={k} onClick={() => {
                // alert(JSON.stringify(itm?.geometry?.replace('POLYGON((', '')?.replace('))', '')))
                setForm((p) => ({
                  ...p,
                  zoom: [11],
                  latitude: itm?.geometry?.replace('POLYGON((', '')?.replace('))', '')?.split(',')[0].split(' ')[0],
                  longitude: itm?.geometry?.replace('POLYGON((', '')?.replace('))', '')?.split(',')[0].split(' ')[1]
                }))
              }}><BsFillPinMapFill /> {itm.facility_name}</NavLink>)}
            </NavItem>
          </Nav>
        </div>
      </Col>
      <Col>
        <ReactMap
          height='88vh'
          form={form}
          setForm={setForm}
          points={points}
          center={current}
          polygons={geojson}
          setCurrent={handleProps}
        />
      </Col>
      <CustomModal
        toggle={toggle}
        isOpen={isOpen}
        size={'md'}>
        {form.geometry ? <CustomCard header={<h4>Create evacuation center</h4>}>
          <CustomForm fields={fields} handleChange={handleChange} />
        </CustomCard> : null}
      </CustomModal>
    </Row >
  )
}
export default CampsMap