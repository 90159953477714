import React, { useCallback, useEffect, useState } from "react";
// import { ImHome, ImUser } from 'react-icons/im'
import { MdMapsHomeWork, MdWarning } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import {
  FaListUl,
  FaMap,
  FaAlignJustify,
  FaPlus,
  FaSourcetree,
  FaUserAlt,
  FaUsers,
  FaUsersCog,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { CreditCard, Facebook, Settings } from "react-feather";
import logo from "../../Images/Knowtify.png";
import { useDispatch, useSelector } from "react-redux";
import { hasRole } from "../../helpers/helper";
import { BsFillFileEarmarkWordFill, BsFillMapFill, BsMapFill, BsPeaceFill, BsPeople, BsPinMapFill } from "react-icons/bs";
import { init } from "../../redux/actions/auth";

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(true)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const { authenticated, user } = useSelector(s => s.auth)
  const toggle = useSelector((state) => state.app.toggle);
  const navigateUser = useCallback(() => {
    if (!user?.role)
      dispatch(
        init(
          () => {
            navigate(`${location.pathname}${location.search}`);
          },
          () => {
            navigate('/')
          }
        )
      );
  }, [user?.role]);

  useEffect(() => {
    navigateUser();
  }, [navigateUser]);

  // const toggle1 = () => setIsOpen(!isOpen)

  // const _toggle = () => {
  //   dispatch({ type: TOGGLE_MENU });
  // };

  return (
    <div>
      {/* {JSON.stringify(user)} */}

      {!toggle ?
        <img src={logo} className="" alt="logo" style={{ width: 150 }} />
        : ''
      }
      {/* {!toggle && (
          <div>
            <p className="username" style={{ color: "black" }}>
              {user?.firstname + " " + user?.lastname}
            </p>
          </div>
        )} */}
      {/* {JSON.stringify(user?.role)} */}
      <div className="mt-4">
        {/* <p className={`sidebar-i ${location.pathname === "/pending-tasks" && "active_sidebar" } text-start`} onClick={() => navigate('/pending-tasks')}><i class="fa-solid fa-list-check"></i>{' '}My Tasks</p> */}
        {user?.role
          ? hasRole(user, ["admin", "media"]) && (
            <p
              className={`list ${location.pathname === "/app/dashboard" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/dashboard")}
            >
              <MdDashboard
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Dashboard"}
            </p>
          )
          : null}

        {user?.role
          ? hasRole(user, ["admin", "media"]) && (
            <p
              className={`list ${location.pathname === "/app/messages" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/messages")}
            >
              <FaPlus
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />{" "}
              {!toggle && "Message"}
            </p>
          )
          : null}

        {/* {user?.role
          ? hasRole(user, ["admin", "media"]) && (
            <p
              className={`list ${location.pathname === "/app/departmental" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/departmental")}
            >
              <FaUsersCog
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />{" "}
              {!toggle && "Reports"}
            </p>
          )
          : null} */}
        {/* {user?.role
          ? hasRole(user, ["admin", "media"]) && (
            <p
              className={`list ${location.pathname === "/app/agencies" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/agencies")}
            >
              <FaMap
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />{" "}
              {!toggle && "Warnings"}
            </p>
          )
          : null} */}
        {user?.role
          ? hasRole(user, ["admin", "media"]) && (
            <p
              className={`list ${location.pathname === "/app/waring-platforms" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/waring-platforms")}
            >
              <FaSourcetree
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Platforms"}
            </p>
          )
          : null}

        {user?.role
          ? hasRole(user, ["admin"]) && (
            <p
              className={`list ${location.pathname === "/app/facebook" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/facebook")}
            >
              <BsFillFileEarmarkWordFill
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Media Walls"}
            </p>
          )
          : null}

        {/* {user?.role
          ? hasRole(user, ["admin", 'rescue']) && (
            <p
              className={`list ${location.pathname === "/app/evacuation-centers" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/evacuation-centers")}
            >
              <BsPinMapFill
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Camps"}
            </p>
          )
          : null} */}

        {user?.role
          ? hasRole(user, ["admin", 'rescue']) && (
            <p
              className={`list ${location.pathname === "/app/subscribers" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/subscribers")}
            >
              <BsPeople
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Subscribers"}
            </p>
          )
          : null}

          
        {user?.role
          ? hasRole(user, ["admin", 'rescue']) && (
            <p
              className={`list ${location.pathname === "/app/setup" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/set-up")}
            >
              <Settings
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "SetUp"}
            </p>
          )
          : null}
           {user?.role
          ? hasRole(user, ["admin", 'rescue']) && (
            <p
              className={`list ${location.pathname === "/app/subscription" && "active_listss"
                } text-start`}
              onClick={() => navigate("/app/subscription")}
            >
              <CreditCard
                size={toggle ? "1.3em" : "1em"}
                style={{ marginRight: "1rem", color:'white' }}
              
              />
              {!toggle && "Subscription"}
            </p>
          )
          : null}
          
          
      </div>
    </div>
  );
}
