import { apiURL, _fetchApi, _postApi } from "../../helpers/helper";
import {
  LOADING,
  LOGIN,
  ERROR,
  LOGOUT,
  ERROR_CREATING_USER,
  GET_ROLES,
  GET_USERS,
  CREATING_USER,
} from "../types";

// const endpoint = "auth";

export function createUser(data = {}, success = (f) => f, error = (f) => f) {
  return (dispatch) => {
    dispatch({ type: CREATING_USER });
    _postApi(
      `/users/create`,
      data,
      (result) => {
        // console.log(result);
        if (result.errors) {
          let err = Object.values(result.errors);
          error(err[0]);
          dispatch({ type: ERROR_CREATING_USER, payload: err[0] });
        } else {
          dispatch({ type: CREATING_USER });
          dispatch({ type: LOGIN, payload: result });
          console.log(result, "LSLSLDLD");
          success();
        }
      },
      (err) => {
        // console.log(err);
        dispatch({ type: ERROR_CREATING_USER, payload: err });
      }
    );
  };
}

export function login({ email, password }, cb, error,datas) {
  return (dispatch) => {
    dispatch(userLogin({ email: email, password }, cb, error,datas));
    cb()
  };
}

export function userLogin(
  { email, password },
  cb = (f) => f,
  error = (f) => f,
  datas = (f) => f
) {
  return async (dispatch) => {
    fetch(`${apiURL}/users/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    })
      .then((raw) => raw.json())
      .then((data) => {
        datas(data);
        // alert()
        if (data.error) {
          error(data.error);
          alert(data.error);
          dispatch({ type: ERROR, payload: data.error });
        } else {
          localStorage.setItem("@@__warning_key", data.token);
          dispatch({ type: LOGIN, payload: data });
          cb();
        }
      })
      .catch((err) => {
        error(err);
        // console.log(err)
        alert(err)
        dispatch({ type: ERROR, payload: err });
      });
  };
}

export function authLoading() {
  return (dispatch) => {
    dispatch({ type: LOADING });
  };
}

export function checkAuthStatus(success, error) {
  return (dispatch) => {
    dispatch(init(success, error));
  };
}

export function logout(cb = (f) => f) {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("@@__warning_key");
    cb();
  };
}

export async function getUserProfile(_token) {
  try {
    // console.log(_token);
    let response = await fetch(`${apiURL}/auth/verify-token`, {
      method: "GET",
      headers: {
        authorization: _token,
      },
    });
    let data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

const navigateBasedOnAccess = (access, history) => {
  if (access && access.length) {
    switch (access[0]) {
      case "Laboratory":
        return history("/me/lab");
      case "Accounts":
        return history("/me/account");
      default:
        return history("/");
    }
  }
};

export function init(callback = (f) => f, error = f => f) {
  return (dispatch) => {
    let token = localStorage.getItem("@@__warning_key");

    if (token) {
      getUserProfile(token)
        .then((data) => {
          console.log(data);
          if (data.success) {
            dispatch({ type: LOGIN, payload: data });
            callback();
            console.log(data);
          } else {
            error();
            localStorage.removeItem("@@__warning_key");
            console.log("Token expired");
            dispatch({ type: LOGOUT });
          }
        })
        .catch((err) => {
          console.log("Token is invalid");
          dispatch({ type: LOGOUT });
          error();
          // localStorage.removeItem("pharm_key_1");
        });
    } else {
      error();
      dispatch({ type: LOGOUT });
    }
  };
}

export function getRoles() {
  return (dispatch) => {
    _fetchApi(
      `${apiURL}/users/roles`,
      ({ results }) => {
        // console.log(results)
        if (results.length) {
          dispatch({ type: GET_ROLES, payload: results });
        }
      },
      (err) => console.log(err)
    );
  };
}

export function getUsers() {
  return (dispatch) => {
    _fetchApi(
      `${apiURL}/users`,
      ({ results }) => {
        dispatch({ type: GET_USERS, payload: results });
      },
      (err) => {
        console.log(err);
        //
      }
    );
  };
}
