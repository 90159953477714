export class DynamicShaderDataCollection {
    constructor(vertexDataMapper, startingBufferSize) {
        this.vertexDataMapper = vertexDataMapper;
        this.styleOption = undefined;
        this.features = [];
        this.startIndex = 0;
        this.endIndex = 0;
        this.buffer = new Float32Array(startingBufferSize);
    }
    add(feature) {
        const mapped = this.vertexDataMapper(feature, this.styleOption);
        if (this.endIndex + mapped.length >= this.buffer.length) {
            this.resetBuffer();
        }
        this.buffer.set(mapped, this.endIndex);
        this.endIndex += mapped.length;
        this.features.push(feature);
    }
    removeFirst() {
        const feature = this.features.shift();
        if (feature != null) {
            const mapped = this.vertexDataMapper(feature, this.styleOption);
            this.startIndex += mapped.length;
        }
        return feature != null ? feature : null;
    }
    removeLast() {
        const feature = this.features.pop();
        if (feature != null) {
            const mapped = this.vertexDataMapper(feature, this.styleOption);
            this.endIndex -= mapped.length;
        }
        return feature != null ? feature : null;
    }
    clear() {
        this.features.length = 0;
        this.startIndex = 0;
        this.endIndex = 0;
    }
    setStyle(styleOption) {
        this.styleOption = styleOption;
        this.startIndex = 0;
        this.endIndex = 0;
        for (const feature of this.features) {
            const mapped = this.vertexDataMapper(feature, this.styleOption);
            this.buffer.set(mapped, this.endIndex);
            this.endIndex += mapped.length;
        }
    }
    resetBuffer() {
        const currentSize = this.endIndex - this.startIndex;
        this.buffer.copyWithin(0, this.startIndex, this.endIndex);
        this.startIndex = 0;
        this.endIndex = currentSize;
        if (this.buffer.length >= 2 * currentSize) {
            return;
        }
        const newBuffer = new Float32Array(2 * this.buffer.length);
        newBuffer.set(this.buffer);
        this.buffer = newBuffer;
    }
    getArray() {
        return this.features;
    }
    getVertexArray() {
        return this.buffer;
    }
    getStartIndex() {
        return this.startIndex;
    }
    getEndIndex() {
        return this.endIndex;
    }
}
