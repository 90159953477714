// import events from '@mapbox/mapbox-gl-draw/src/events'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, NavItem, NavLink, Row } from 'reactstrap'
import { capitalizeAllFirstLetter, _fetchExtApi } from '../../helpers/helper'
// import { CustomButton } from '../../UI'
import GoogleMap from "../../Component/GoogleMap";
import moment from 'moment';
import axios from 'axios';
import CustomModal from '../../UI/CustomModal';

export default function Nasa() {
  const [events, setEvents] = useState([])
  const to = moment().format('YYYY-MM-DD')
  const from = moment(moment(to, "YYYY-MM-DD").add(-1, 'days')).format('YYYY-MM-DD');
  const [current, setCurrent] = useState({})
  const [isOpen, setIsOppen] = useState(false)
  const toggle = () => setIsOppen(!isOpen)

  const _form = {
    disaster_type: "",
    location_name: "",
    longitude: "",
    latitude: "",
    severity: "",
    geometry: '',
    status: 'new-report',
    from, to
  }

  const [form, setForm] = useState(_form)

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let geoId;
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('user_location'))
    const user_location = cache ? cache : null
    if (user_location && user_location.latitude) {
      setForm(p => ({
        ...p,
        latitude: user_location.latitude,
        longitude: user_location.longitude
      }))
    } else {
      geoId = window.navigator.geolocation.watchPosition(position => {
        setForm(p => ({
          ...p,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
        localStorage.setItem('user_location', JSON.stringify({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
      })

      return () => {
        navigator.geolocation.clearWatch(geoId)
      }
    }
  }, [])

  const handleProps = (cur) => {
    setCurrent(cur)
    toggle()
  }

  const fetchWarnings = useCallback(() => {
    const url = 'https://eonet.gsfc.nasa.gov/api/v2.1/events?limit=20&days=200&source=InciWeb'
    // _fetchExtApi('',
    //   data => {
    //     console.log(data);
    //     setEvents(data)
    //   },
    //   error => {
    //     console.error(error);
    //   })
    axios.get(url)
      .then(data => {
        setEvents(data)
      })
      .catch(error => console.error(error))
  }, [0])

  useEffect(() => {
    fetchWarnings()
  }, [fetchWarnings])

  const points = events?.data?.events?.map(x => x?.geometries?.map(g => ({ latitude: g?.coordinates[1], longitude: g?.coordinates[0] }))[0])


  return (
    <div>
      {/* <Container className='mt-3'>
        <Card>

          <CardHeader>Natural events from EONET</CardHeader>
          <CardBody> */}
      <Row className='mt-4'>
        {/* <Col md={3} style={{ height: '90vh', overflow: 'auto' }}>
          <div className=''>
            <p>{events?.data?.events.map(e => <NavItem><NavLink style={{ cursor: 'pointer' }} onClick={() => { setCurrent(e); console.error(e) }}> {e.categories.map(c => <span className='text-secondary'>{c.title}</span>)} ({e.title}) </NavLink>
            </NavItem>)}
            </p>
          </div>
        </Col> */}
        <Col md={2}>
          <p className='mb-2' style={{ fontSize: 12, cursor: 'pointer' }}>
            {events?.data?.events.map(e => <span onClick={() => { setCurrent(e); console.log(e) }}>{e.categories.map(c => <b>{c.title}</b>)}({e.title})<br /></span>)}
          </p>
        </Col>
        <Col md={10}>
          <GoogleMap
            form={form}
            height='90vh'
            setForm={setForm}
            points={points}
            // polygons={{}}
            setCurrent={handleProps}
          />
        </Col>
      </Row>
      {/* </CardBody>
        </Card>
      </Container> */}
      <CustomModal
        toggle={toggle}
        isOpen={isOpen}
        size={'sm'}>
        {current.id ? <div>
          {Object.keys(current).map((obj, idx) => <Row>
            <Col md={5} key={idx} style={{ textAlign: 'left' }}>
              {obj === 'created_at' ? 'Reported' : capitalizeAllFirstLetter(obj.replace('_', ' '))}
            </Col>
            <Col md={1} style={{ textAlign: 'right' }}>:</Col>
            <Col style={{ textAlign: 'right' }}>
              {obj === 'created_at' ? moment(Object.values(current)[idx]).calendar() : capitalizeAllFirstLetter(Object.values(current)[idx])}
            </Col>
          </Row>)}

        </div> : null}
      </CustomModal>
    </div >
  )
}
