import { resolveVisibility } from '../../shared/visibility';
import { pointToPointDistanceSqr } from '../../shared/geometry-functions';
import KDBush from 'kdbush';
export class KdBushClickProvider {
    constructor(onClick, clickSize) {
        this.onClick = onClick;
        this.clickSize = clickSize;
        this.map = null;
        this.data = null;
        this.index = null;
        this.visibility = true;
        this.clickHandler = (e) => {
            if (this.map == null || this.data == null || this.index == null) {
                return;
            }
            if (!resolveVisibility(this.visibility, this.map)) {
                return;
            }
            const bounds = this.map.getBounds();
            const canvas = this.map.getCanvas();
            const clickSize = this.clickSize != null ? this.clickSize : 16;
            const x = e.lngLat.lng;
            const y = e.lngLat.lat;
            const w = clickSize * (bounds.getEast() - bounds.getWest()) / canvas.width;
            const h = clickSize * (bounds.getNorth() - bounds.getSouth()) / canvas.height;
            const indices = this.index.range(x - 0.5 * w, y - 0.5 * h, x + 0.5 * w, y + 0.5 * h);
            if (indices.length === 0) {
                return;
            }
            let closestResultIndex = indices[0];
            let minDistanceSqr = Infinity;
            for (const i of indices) {
                const result = this.data[i];
                const distanceSqr = pointToPointDistanceSqr(x, y, result.x, result.y);
                if (distanceSqr < minDistanceSqr) {
                    closestResultIndex = i;
                    minDistanceSqr = distanceSqr;
                }
            }
            const size = Math.max(w, h);
            const clickDistanceSqr = 0.25 * (size * size);
            if (minDistanceSqr <= clickDistanceSqr) {
                this.onClick(this.data[closestResultIndex].feature, e);
            }
        };
    }
    setData(data) {
        const packedData = [];
        for (const feature of data.features) {
            if (feature.geometry.type === 'Point') {
                const coords = feature.geometry.coordinates;
                const packed = {
                    feature: feature,
                    x: coords[0],
                    y: coords[1]
                };
                packedData.push(packed);
            }
            else if (feature.geometry.type === 'MultiPoint') {
                const multiPoint = feature.geometry;
                for (const coords of multiPoint.coordinates) {
                    const packed = {
                        feature: feature,
                        x: coords[0],
                        y: coords[1]
                    };
                    packedData.push(packed);
                }
            }
        }
        this.data = packedData;
        this.index = new KDBush(packedData, p => p.x, p => p.y, 64, Float64Array);
    }
    clearData() {
        this.index = null;
        this.data = null;
    }
    initialise(map) {
        this.map = map;
        map.on('click', this.clickHandler);
    }
    dispose(map) {
        this.map = null;
        map.off('click', this.clickHandler);
    }
    setVisibility(visibility) {
        this.visibility = visibility;
    }
}
