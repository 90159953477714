import moment from 'moment';
import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Mail } from 'react-feather';
import { FaArrowAltCircleLeft, FaCalendar, FaEye, FaMoneyBill, FaPhone, FaTrash, FaUser } from 'react-icons/fa';
import { MdDateRange, MdMapsHomeWork } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Card, Col, Input, Nav, NavItem, NavLink, Row, Table } from 'reactstrap'
import { apiURL, capitalizeAllFirstLetter, HOME_URL, _fetchApi, _postApi } from '../helpers/helper';
import { CustomButton } from '../UI';
import CustomModal from '../UI/CustomModal';
import CustomScrollbar from '../UI/CustomScrollbar';
import GoogleMap from '../Component/GoogleMap'
import { BsCheck2Circle, BsFillPinMapFill } from 'react-icons/bs';
import _customNotification from '../UI/CustomNotification';
import { useToasts } from 'react-toast-notifications';
import DaterangeSelector from '../UI/DaterangeSelector';
import ReactAudioPlayer from 'react-audio-player';

function AgenciesReports() {
  const { addToast } = useToasts()
  const [warning, setWarning] = useState({})
  const to = moment().format('YYYY-MM-DD')
  const change = moment(to, "YYYY-MM-DD").add(-1, 'day')
  const from = moment(change).format('YYYY-MM-DD');

  const [form, setForm] = useState({ from, to, disaster_type: '' })
  const [camps, setCamps] = useState({})
  const [current, setCurrent] = useState({})
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const [isOpen2, setIsOpen2] = useState(false)
  const toggle2 = () => setIsOpen2(!isOpen2)
  // get-admin-warinings
  const navigate = useNavigate()
  const [warnings, setWarnings] = useState([])
  const [polygons, setPolygons] = useState({})

  const fetchWarnings = useCallback(() => {
    _fetchApi(
      `/get-admin-warnings?query_type=select&date_from=${form.from}&date_to=${form.to}&disaster_type=${form.disaster_type}`,
      (resp) => {
        if (resp.success && resp.results) {
          console.log({ results: resp.results });
          setWarnings(resp.results)
        }
      },
      (error => console.log({ error }))
    )
  }, [0, form])

  useEffect(() => {
    fetchWarnings()
  }, [fetchWarnings])

  const getPolygons = useCallback(() => {
    _fetchApi(
      `/get-admin-warning-json?query_type=select&id=${warning.id}`,
      (data) => {
        if (data.success) {
          setPolygons(data.results);
        }
      }
    );
  }, [warning])
  useEffect(() => getPolygons(), [getPolygons]);

  const getCamps = useCallback(() => {
    if (warning.camps) {
      _fetchApi(
        `/get-camp-json?query_type=select&ids=${warning.camps}`,
        (data) => {
          if (data.success) {
            setCamps(data.results);
          }
        }, () => setCamps({ features: [] })

      );
    } else {
      setCamps({ features: [] })
    }

  }, [warning])

  useEffect(() => getCamps(), [getCamps]);

  const dispatchAlert = warning => {
    _postApi('/messages/dispatch', {
      id: warning.id,
      description: `${warning.description} Visit: ${HOME_URL}?id=${warning.id}`
    }, (resp) => {
      // alert(JSON.stringify(resp))
      _postApi('/admin-warnings?query_type=update-status', {
        id: warning.id,
        status: 'dispatched'
      }, () => {
        _customNotification(addToast, 'Alert dispatched');
        fetchWarnings()
      })
    }, () => {
      _customNotification(addToast, 'Failed to dispatch', 'warning')
    })
  }
  const types = ['Flooding', 'Earhquake', 'Wild fire', 'Fire outbreak', 'Volcanic eruption', 'Drought', 'Landslide', 'Tsunami', 'Turnado', 'Heat wave',
    'Thunderstorm', 'Fire', 'Extreem weather', 'Explosion', 'Sinkhole', 'Accident', 'Bioterrosim', 'Hazard Material', 'Cold wave', 'Ice storm']

  return (
    <div className='mt-0'>
      {/* {JSON.stringify({ WARNING: polygons[0]?.data?.features, CAMPS: camps?.features?.map(itm => ({ ...itm, color: '#0F0' })) })} */}
      {/* {JSON.stringify({ camps })} */}
      <Card className='admin-card p-3'>
        <Row className='m-0 p-0'>
          <Col md={1}>
          <button className="new_button mt-4" onClick={() => navigate(-1)}>Back</button>
          </Col>

          <Col md={3}>
            <h4 className="mt-4">Warnings <small className='text-danger'>({warnings.length})</small></h4>
          </Col>
          <Col md={2} className="ml-3">
            <select className='input_field mt-4 ' value={form.disaster_type} name="disaster_type" onChange={handleChange}>
              <option value={''}>Choose events</option>
              {types.map(item => <option value={item}>{item} reports</option>)}
            </select>
          </Col>
          <Col>
            <DaterangeSelector mini from={form.from} to={form.to} handleChange={handleChange} />
          </Col>
        </Row>
        <CustomScrollbar height='85vh'>
          <Table striped className=' shadow-sm p-2 m-1'>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Disaster type</th>
                <th>Message</th>
                <th>Coordinates</th>
                <th>projected event date</th>
                <th>Event Duration Year</th>
                <th className='text-center' colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                warnings && warnings.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.disaster_type}</td>
                    <td>{item.description} {!item.file_url ?'' : <>
                      <p><a href={`${apiURL}/${item.file_url}`}></a> </p>
                      <ReactAudioPlayer
                        src={`${apiURL}/${item.file_url}`}
                        controls
                      />
                    </>}</td>
                    <td><textarea type='textarea' className="input_field" readOnly value={item?.geometry?.replace('POLYGON((', '').replace('))', '')} /></td>
                    <td>{moment(item.projected_event_date).format('DD/MM/YYYY - Hh:mm:ss:A')}</td>
                    <td>{moment(item.event_duration_year).format('DD/MM/YYYY - Hh:mm:ss:A')}</td>
                    <td>  <button className="edit_b" onClick={() => { setWarning(item); toggle() }}><FaEye /></button></td>
                    <td>{item.status === 'dispatched' ? <Col className='text-center mt-3' onClick={() => { dispatchAlert(item) }}> <BsCheck2Circle size={30} className='text-primary' style={{ fontSize: '5px' }} /></Col> : <CustomButton title='Dispatch' onClick={() => { dispatchAlert(item) }}><Mail /></CustomButton>}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </CustomScrollbar>
      </Card>
      <CustomModal
        toggle={toggle}
        isOpen={isOpen}
        size={'lg'}>
        {polygons.length > 0 && polygons[0] ?
          <Row>
            <Col md={3}>
              <h5>Warning report</h5>
              <Nav>
                <h6>{' '} <BsFillPinMapFill />{' '}Disaster scene</h6>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // alert(JSON.stringify(warning?.geometry.replace('POLYGON((', '').replace('))', '')))
                      setForm((p) => ({
                        ...p,
                        zoom: [5],
                        latitude: parseFloat(warning?.geometry.replace('POLYGON((', '').replace('))', '')?.split(',')[0]?.split(' ')[1]),
                        longitude: parseFloat(warning?.geometry.replace('POLYGON((', '').replace('))', '').split(',')[0]?.split(' ')[0])
                      }))
                    }}>
                    <li style={{ marginLeft: '1em' }}>Type: <span className='text-danger'>{warning.disaster_type}</span></li>
                    <li style={{ marginLeft: '1em' }}>Description: <span className='text-danger'>{warning.description}</span></li>
                  </NavLink>
                </NavItem>
                <h6 className='text-success'>Evacuation centers</h6>
                {camps?.features?.map((ft, idx) =>
                  <NavItem key={idx}>
                    {/* {JSON.stringify(ft?.geometry?.coordinates[0][0]?.toString()?.split(',')[0])} */}
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setForm((p) => ({
                          ...p,
                          zoom: [5],
                          latitude: parseFloat(ft?.geometry?.coordinates[0][0]?.toString()?.split(',')[1]),
                          longitude: parseFloat(ft?.geometry?.coordinates[0][0]?.toString()?.split(',')[0])
                        }))
                      }}>
                      <li><BsFillPinMapFill /> <span className='text-info'>{ft?.properties?.facility_name}</span></li>
                      {/* <li style={{ marginLeft: '.1em' }}>Address: <span className='text-info'>{ft?.properties?.facility_address}</span></li>
                      <li style={{ marginLeft: '.1em' }}>State: <span className='text-info'>{ft?.properties?.state_name}</span></li>
                      <li style={{ marginLeft: '.1em' }}>LGA: <span className='text-info'>{ft?.properties?.lga_name}</span></li>
                      <li style={{ marginLeft: '.1em' }}>Ward: <span className='text-info'>{ft?.properties?.ward_name}</span></li> */}
                    </NavLink>
                  </NavItem>)}
              </Nav>
            </Col>
            <Col>
              <GoogleMap
                callback={toggle2}
                setCurrent={setCurrent}
                form={{
                  ...warning,
                  latitude: polygons[0]?.data?.features[0]?.geometry?.coordinates[0][0][1],
                  longitude: polygons[0]?.data?.features[0]?.geometry?.coordinates[0][0][0],
                  ...form
                }}
                polygons={{
                  features: [...polygons[0]?.data?.features?.map(itm => ({ ...itm, color: '#F00' })),
                  ...camps?.features?.map(itm => ({ ...itm, color: '#0F0' }))
                  ]
                }}
                zoom={14}
              />
            </Col>
          </Row>
          : null}
      </CustomModal>

      <CustomModal
        toggle={toggle2}
        isOpen={isOpen2}
        size={'sm'}>
        {isOpen2 && current?.properties ? <div>
          {/* {JSON.stringify(current?.properties)} */}
          {Object.keys(current?.properties)?.map((obj, idx) => <Row>
            <Col md={5} key={idx} style={{ textAlign: 'left' }}>
              {obj === 'created_at' ? 'Reported' : capitalizeAllFirstLetter(obj.replace('_', ' '))}
            </Col>
            <Col md={1} style={{ textAlign: 'right' }}>:</Col>
            <Col style={{ textAlign: 'right' }}>
              {obj === 'created_at' ? moment(Object.values(current?.properties)[idx]).calendar() :
                capitalizeAllFirstLetter(Object.values(current?.properties)[idx])}
            </Col>
          </Row>)}

        </div> : null}
      </CustomModal>
    </div >
  )
}

export default AgenciesReports