import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Hazards',
    },
  },
};


const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', "August", "September", "October", "November", "December"],
  datasets: [
    {
      label: 'Hazard',
      data: [3, 2, 3, 1, 4, 2, 1, 3, 7, 9, 1, 3],
      backgroundColor: '#032560',
    },
    {
      label: 'Disaster',
      data: [1, 2, 3, 4, 5, 6, 7, 6, 3, 6, 5, 9],
      backgroundColor: '#3991F9',
    },
  ],
  borderColor: [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
  ]
};

export default function BarChart() {
  return (
    <>
      <div style={{ width: '', height: 400 }} >
        <Bar options={options} data={data} />
      </div>
    </>
  )
}