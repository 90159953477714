import { dynamicPointRenderer } from '../renderer-preset/dynamic-point-renderer';
import { DynamicDataLayer } from '../dynamic-data-layer';
import { pointToResultsClickHandler } from '../../shared/click-handler/point-click-handler';
import { DynamicRBrushClickProvider } from '../click-provider/dynamic-r-brush-click-provider';
export function dynamicPointLayer(options) {
    return new DynamicDataLayer({
        id: options.id,
        renderer: dynamicPointRenderer(options),
        clickProvider: options.onClick != null ?
            new DynamicRBrushClickProvider(pointToResultsClickHandler(options.onClick), options.clickSize) : undefined
    });
}
