import { createShaderProgram } from '../../shared/shader/shader';
import { DataOperationsExtender } from '../data-operations';
import { DynamicShaderDataCollection } from './dynamic-shader-data-collection';
export class DynamicShaderRenderer {
    constructor(shader, vertexDataMapper, startingBufferSize = 512) {
        this.shader = shader;
        this.program = null;
        this.arrayBuffer = null;
        this.collection = new DynamicShaderDataCollection(vertexDataMapper, startingBufferSize);
        this.dataOperations = new DataOperationsExtender(this.collection);
    }
    setStyle(styleOption) {
        this.collection.setStyle(styleOption);
    }
    initialise(map, gl) {
        this.program = createShaderProgram(gl, this.shader.vertexSource, this.shader.fragmentSource);
        this.arrayBuffer = gl.createBuffer();
    }
    dispose(map, gl) {
        gl.deleteBuffer(this.arrayBuffer);
        gl.deleteProgram(this.program);
    }
    prerender() {
    }
    render(gl, matrix) {
        if (this.program == null) {
            throw Error('ShaderRenderer can not render before it is initialised.');
        }
        gl.enable(gl.BLEND);
        gl.blendEquation(gl.FUNC_ADD);
        gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.useProgram(this.program);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.arrayBuffer);
        const cleanAttributes = this.shader.configureAttributes(gl, this.program);
        gl.bufferData(gl.ARRAY_BUFFER, this.collection.getVertexArray(), gl.STATIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);
        this.shader.setUniforms(gl, this.program, matrix);
        const startIndex = this.collection.getStartIndex();
        const endIndex = this.collection.getEndIndex();
        const elementsPerVertex = this.shader.getArrayBufferElementsPerVertex();
        gl.drawArrays(this.shader.getPrimitiveType(gl), startIndex / elementsPerVertex, (endIndex - startIndex) / elementsPerVertex);
        cleanAttributes();
    }
}
