import React from "react";
import CustomTree from "./CustomTree";


function StructureTree({
  editNode = (f) => f,
  deleteNode = (f) => f,
  addChild = (f) => f,
  treeData = [],
}) {
  return (
    <CustomTree
          generateNodeProps={({ node, path }) => ({
            title: `${node.id} - ${node.group_name}`,
            buttons: [
              <button
                className="btn btn-primary btn-sm mr-2 ml-2"
                onClick={() => {addChild(node)}
                }
              >
                Add
              </button>,
              <button
                className="btn btn-success btn-sm mr-2 ml-2"
                onClick={() => editNode(node)}
              >
                Edit
              </button>,
              !node.children.length && (
                <button
                  className="btn btn-danger btn-sm ml-2"
                  onClick={() => deleteNode(node)}
                >
                  Delete
                </button>
              ),
            ],
          })}
         
      treeData={treeData}
    />
  );
}

export default StructureTree;
