import React, { useEffect, useState } from 'react'
import "./nasa.css"
export default function Nasa() {
    const [results, setResults] = useState([])
    const handleSearch = () => {
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '03b24337bdmshea0650b3201084ep1a6913jsnc712862db760',
                'X-RapidAPI-Host': 'visual-crossing-weather.p.rapidapi.com',
                // "Content-Type": "application/json"
            }
        };

        fetch('https://visual-crossing-weather.p.rapidapi.com/forecast?aggregateHours=24&location=Kano,nigeria&contentType=csv&unitGroup=us&shortColumnNames=0', options)
            .then(response => response)
            .then(response => { setResults(response); console.log(response.text); })
            .catch(err => console.error(err));
    }
    useEffect(() => {
        handleSearch()
    }, [])
    return (
        <div>
            <h1>wheather app</h1>
            <main>
                {/* {JSON.stringify(results)} */}
            </main>
            <footer>
                Page cre</footer>
        </div>
    )
}
