import React, { useCallback, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button, Input } from "reactstrap";
import { BsClockHistory, BsClock, BsEnvelopeX, BsEnvelopeCheck } from "react-icons/bs";
import { ImWarning } from 'react-icons/im'
import { MdMapsHomeWork } from "react-icons/md";
import { Circle, PieChart, Users } from "react-feather";
import { FaBiohazard } from 'react-icons/fa'
import { ImUsers } from "react-icons/im";
import {GrSend} from 'react-icons/gr'
import Chart from "./Operator/Chart";
import { AiOutlineProfile } from 'react-icons/ai'
import "./Operator/Chart.css";
import DoughnutChart from "./Operator/Doughnut";
import BarChart from "./Operator/BarChart";
import TenantsByAccommodation from "./Operator/TenantsByAccommodation";
// import { FaCalendar, FaUser } from "react-icons/fa";
import { apiURL, capitalizeAllFirstLetter, capitalizeFirstLetter, _fetchApi, _postApi } from "../helpers/helper";
import { useState } from "react";
import { useNavigate } from "react-router";
import Charts from "../Component/Graph";
// import NewMap from "../Component/NewMap";

import GoogleMap from "../Component/GoogleMap";
import CustomButton from "../Component/CustomButton";
import DaterangeSelector from "../UI/DaterangeSelector";
import moment from "moment";
import CustomModal from "../UI/CustomModal";
import { primaryColor } from "../colors";
import { useSelector } from "react-redux";

const alarmTone = new Audio(require("./alarm.wav"));

export const options = {
  title: "Report Per Disaster",
  curveType: "function",
  legend: { position: "bottom" },
};

export default function Home() {
  const [soundPlaying, setSoundPlaying] = useState(false)
  const [reports, setReports] = useState([])
  const to = moment().format('YYYY-MM-DD')
  const from = moment(moment(to, "YYYY-MM-DD").add(-1, 'days')).format('YYYY-MM-DD');
  const [current, setCurrent] = useState({})
  const [isOpen, setIsOppen] = useState(false)
  const toggle = () => setIsOppen(!isOpen)
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const navigate = useNavigate()
  const _form = {
    disaster_type: "",
    location_name: "",
    longitude: "",
    latitude: "",
    severity: "",
    geometry: '',
    status: 'new-report',
    from, to
  }

  const [form, setForm] = useState(_form)
  let geoId;
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('user_location'))
    const user_location = cache ? cache : null
    if (user_location && user_location.latitude) {
      setForm(p => ({
        ...p,
        latitude: user_location.latitude,
        longitude: user_location.longitude
      }))
    } else {
      geoId = window.navigator.geolocation.watchPosition(position => {
        setForm(p => ({
          ...p,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
        localStorage.setItem('user_location', JSON.stringify({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }))
      })

      return () => {
        navigator.geolocation.clearWatch(geoId)
      }
    }
  }, [])

  const getReports = useCallback(() => {
    _fetchApi(
      `/get-public-reports?query_type=select&disaster_type=${form.disaster_type}&date_from=${form.from}&date_to=${form.to}`,
      (data) => {
        if (data.success) {
          setReports(data.results);
        }
      },
      error => console.log(error)
    );
  }, [0, form]);
  useEffect(() => getReports(), [getReports]);

  const playAlarm = () => {
    alarmTone.play();
    setSoundPlaying(true);
  };

  const stopAlarm = () => {
    alarmTone.pause();
    alarmTone.load();
    setSoundPlaying(false);
  };
  const { user } = useSelector((state) => state.auth)
  const [subscribers, setSubsribers] = useState([])
  const getSubscribers = useCallback(() => {
    _fetchApi(`/messages/subscribers?org_id=${user&&user.org_id}`, (resp) => {
      setSubsribers(resp.results)
    })
  }, [])
  const [logse,setLogse]=useState([])
  const getLogs = useCallback(() => {
    _fetchApi(`/messages/getlogs?org_id=${user&&user.org_id}`, (resp) => {
      setLogse(resp.results)
    })
  }, [])
  
  const [messages, setMessages] = useState([])

     useEffect(() => {
      getSubscribers();
      getLogs()
        _fetchApi(
          `/messages/getAllmessages?org_id=${user&&user.org_id}`,
          // {org_id:user.org_id},
          (response) => {
          
            if (response.success) setMessages(response.results)
            console.log({ response })
          },
          (error) => {
          
            console.error(error)
          },
        )
      }, [user&&user.org_id])


  const types = ['Flooding', 'Earh quake', 'Wild fire', 'Fire outbreak', 'Volcanic eruption', 'Drought', 'Landslide', 'Tsunami', 'Turnado', 'Heat wave',
    'Thunderstorm', 'Fire', 'Extreem weather', 'Explosion', 'Sinkhole', 'Accident', 'Bioterrosim', 'Hazard Material', 'Cold wave', 'Ice storm']

  const handleProps = (cur) => {
    setCurrent(cur)
    toggle()
  }
  const faileds = logse?.filter((i)=>i.message_status === 'failed')
  const delivered = logse?.filter((i)=>i.message_status === 'delivered')
  return (
    <div className="mt-4 dashboard_div">
      {/* {JSON.stringify(logse?.filter((i)=>i.message_status === 'failed'))} */}
      <Row>
        <Col lg={3}>
          <Card
            className="p-3 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/app/messages")}
          >
            <Row>
              <Col md={3}>
                <div className="dashboard_icon_div1">
                  <div>
                    <GrSend size="2.5em" className="dashboard_icon" />
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className="dashboard_card_details">
                  <div>
                    <p className="d_count">{messages?.length}</p>
                    <p className="d_text"> Total Sent Messages</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3}>
          <Card
            className="p-3 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/app/failed-messages")}
          >
            <Row>
              <Col md={3}>
                <div className="dashboard_icon_div2">
                  <div>
                    <BsEnvelopeX size="2.5em" className="dashboard_icon" />
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className="dashboard_card_details">
                  <div>
                    <p className="d_count">{faileds?.length}</p>
                    <p className="d_text"> Total Failed Messages</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3}>
          <Card
            className="p-3 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/app/sent-messages")}
          >
            <Row>
              <Col md={3}>
                <div className="dashboard_icon_div3">
                  <div>
                    <BsEnvelopeCheck size="2.5em" className="dashboard_icon" />
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <div className="dashboard_card_details">
                  <div>
                    <p className="d_count">
                      {delivered?.length}
                    </p>
                    <p className="d_text">Total Successful Messages</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3}>
          <Card
            className="p-3 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/app/subscribers")}
          >
            <Row>
              <Col md={3}>
                <div className="dashboard_icon_div4">
                  <div>
                    <ImUsers size="2.5em" className="dashboard_icon" />
                  </div>
                </div>
              </Col>
              <Col md={9}>
                <p className="d_count">  {subscribers && subscribers.length}</p>
                <p className="d_text">Subscribers</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{}}>
          <Card
            className="p-3 mt-4 shadow"

          >
            <p className="card_title_">
              Public reports
            </p>
            <Row className='mt-2'>
              <Col md={6} className='' style={{ display: 'flex', alignItems: 'center' }}>
                <DaterangeSelector mini from={form.from} to={form.to} handleChange={handleChange} />
                {/* <button>fasdfas</button> */}
                <div style={{ float: 'right', marginLeft: 20, marginTop: 10 }}>

                  <button
                  className="message_button" style={{backgroundColor:primaryColor}}
                    size='sm'
                    onClick={soundPlaying ? stopAlarm : playAlarm}
                    color={soundPlaying ? "danger" : "primary"}
                  >
                    {soundPlaying ? "Stop Alarm" : "Play Alarm"}
                  </button>
                </div>
              </Col>
              {/* <Col className="bg-primary" md={2}> */}
              {/* </Col> */}
              <Col md={2}></Col>
              <Col md={4}>
                <div style={{ float: 'right' }}>
                  <label className="label_">Display data by: </label>
                  <select className="input_field" value={form.disaster_type} name="disaster_type" onChange={handleChange}>
                    <option value={''}>Choose event</option>
                    {types.map(item => <option value={item}>{item} reports</option>)}
                  </select>
                </div>

                {/* <Input value={form.disaster_type} name="disaster_type" onChange={handleChange} type="select">
                  <option value={''}>Choose event</option>
                  {types.map(item => <option value={item}>{item} reports</option>)}
                </Input> */}
              </Col>
            </Row>
            {/* {JSON.stringify(subscribers)} */}
            <GoogleMap
              form={form}
              setForm={setForm}
              points={subscribers}
              polygons={{}}
              setCurrent={handleProps}
            />
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <Card className="ch p-3 mt-4 shadow">
            <BarChart />
          </Card>
        </Col>
        <Col md={6}>
          <Card className="ch p-3 mt-4 shadow">
            <Charts
              chartType="LineChart"
              width="100%"
              height="100"
              data={[["Des", "lag", "log"]]}
              options={options}
            />
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row> */}
      <CustomModal
        toggle={toggle}
        isOpen={isOpen}
        size={'sm'}>
        {current.id ? <div>
          {Object.keys(current).map((obj, idx) => <Row>
            <Col md={5} key={idx} style={{ textAlign: 'left' }}>
              {obj === 'created_at' ? 'Reported' : capitalizeAllFirstLetter(obj.replace('_', ' '))}
            </Col>
            <Col md={1} style={{ textAlign: 'right' }}>:</Col>
            <Col style={{ textAlign: 'right' }}>
              {obj === 'created_at' ? moment(Object.values(current)[idx]).calendar() : capitalizeAllFirstLetter(Object.values(current)[idx])}
            </Col>
          </Row>)}

        </div> : null}
      </CustomModal>
    </div>
  );
}
