import { Typeahead } from 'react-bootstrap-typeahead'
import { Col, Row } from 'reactstrap'
import { _fetchApi } from './helpers/helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
export default function Crops({ options,  onChange, multiSelections,data=[] }) {
  const [scales,setscales]=useState([])
  const { user } = useSelector((state) => state.auth);
  let org_id = user && user.org_id;
  const getScale =()=>{
    _fetchApi(`/messages/getGroupingbYID?org_id=${org_id}&class_id=${1}`,
    (res)=>{
      setscales(res.results)
    },(err)=>{
      console.log(err)
    }
    )
  }
  useEffect(
  ()=>{
    
    getScale()
  },[])
  return (
    <div>
      {/* {JSON.stringify(data)} */}
      <Row className="" style={{ display: 'flex', alignItems: 'center' }}>
        <Col md={12}>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="name"
            multiple
            // onChange={setMultiSelections}
            options={data}
          
            onChange={onChange}
            placeholder="Select Crops."
            selected={multiSelections}
            name="farming type"
            className="input_field"
          />
        </Col>
        {/* <Col md={2}>
          <button className="primary_button" onClick={onClick}>Add</button>
        </Col> */}
      </Row>
    </div>
  )
}
