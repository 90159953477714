import * as glMatrix from 'gl-matrix';
export class TileGenerator {
    constructor(renderer) {
        this.renderer = renderer;
        this.frameBuffer = null;
        this.matrix = glMatrix.mat4.create();
    }
    initialise(map, gl) {
        this.frameBuffer = gl.createFramebuffer();
        this.renderer.initialise(map, gl);
    }
    dispose(map, gl) {
        this.renderer.dispose(map, gl);
        gl.deleteFramebuffer(this.frameBuffer);
    }
    generateTile(gl, texture, textureWidth, textureHeight, x, y, zoom) {
        if (this.frameBuffer == null) {
            throw Error('TileGenerator can not generate tiles before it is initialised.');
        }
        const oldFrameBuffer = gl.getParameter(gl.FRAMEBUFFER_BINDING);
        const oldViewport = gl.getParameter(gl.VIEWPORT);
        this.setMatrixValue(x, y, zoom);
        this.renderer.prerender(gl, this.matrix);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.frameBuffer);
        gl.viewport(0, 0, textureWidth, textureHeight);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        this.renderer.render(gl, this.matrix);
        gl.viewport(oldViewport[0], oldViewport[1], oldViewport[2], oldViewport[3]);
        gl.bindFramebuffer(gl.FRAMEBUFFER, oldFrameBuffer);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }
    setMatrixValue(x, y, zoom) {
        const viewportSize = Math.pow(2, -zoom);
        const viewportX = x * viewportSize;
        const viewportY = y * viewportSize;
        glMatrix.mat4.ortho(this.matrix, viewportX, viewportX + viewportSize, viewportY + viewportSize, viewportY, -1, 1);
    }
}
