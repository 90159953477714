import { useCallback, useEffect, useState, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useToaster } from "react-hot-toast";
import {
  BsAlarm,
  BsAlignTop,
  BsArrowBarRight,
  BsAsterisk,
  BsAwardFill,
  BsBasket2,
  BsBell,
  BsBellSlash,
  BsCalendar2Date,
  BsCheck2,
  BsEmojiExpressionlessFill,
  BsPlus,
  BsPlusCircle,
} from "react-icons/bs";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import {
  Card,
  Row,
  Col,
  CardBody,
  Table,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { primaryColor } from "../colors";
import { _fetchApi, _postApi } from "../helpers/helper";
import { CustomButton, CustomForm } from "../UI";
import _customNotification from "../UI/CustomNotification";
import Scrollbar from "../UI/Scrollbar";
import CsvConverter from "./CsvConverter";
import { localGovs } from "./lgas";
import Papa from "papaparse";
import LocalGovernment from "./LocalGovernment";
import LocationForm from "../LocationForm";
import { CornerDownRight } from "react-feather";
export default function CreateSubscribers() {
  const _form = {
    query_type: "create",
    email: "",
    phones: "",
    status: "subscribed",
    farming_type: "",
    crops: "",
    scale: "",
    community: "",
    account_number: "",
    latitude: "8.54677",
    longitude: "12.7555",
    edit_map: true,
    lga: "",
    zoom: [5.5],
    geometry: [],
  };

  const navigate = useNavigate();
  const [form, setForm] = useState(_form);
  const [lgas, setLGAs] = useState([]);

  const _ref = useRef();
  const _ref2 = useRef();
  const _ref3 = useRef();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const { addToast } = useToasts();

  const handleAdd = () => {
    setTableData((p) => [...p, { ...form }]);
    setForm(_form);
    setForm((p) => ({
      ...p,
      name: "",
      lname: "",
      farming_type: "",
      crops: "",
      scale: "",
      phone: "",
      email: "",
      lga: "",
      state: "",
      community: "",
      account_number: "",
    }));
    _ref.current.clear();
    _ref2.current.clear();
    _ref3.current.clear();
    console.log(tableData);
  };

  // const [loading, setLoading] = useState(false)
  let org_id = user && user.org_id;
  const handleSubmit = () => {
    setLoading(true);
    _postApi(
      `/messages/subscribers?org_id=${org_id}`,
      tableData,
      () => {
        _customNotification(addToast, "Submitted");
        setLoading(false);
        setForm(_form);
      },
      () => {
        setLoading(false);
        _customNotification(addToast, "Failed to submit", "danger");
      }
    );
    navigate("/app/subscribers");
    console.log(tableData);
  };
  useEffect(() => {
    setForm((p) => ({ ...p, phone: `234${form.phones}` }));
  }, [form.phones]);
  const changeHandler = (event) => {
    console.log(event.target.files[0]);
  };
  const changeHandlers = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(d);
        });

        setTableData(rowsArray);
      },
    });
  };
  const [gis, setGis] = useState([]);
  const [lats, setLats] = useState();
  const [coordinates, setCoordinates] = useState({
    latitude: form.latitude,
    longitude: form.longitude,
  });
  const dt = useCallback(() => {
    _postApi(
      `/messages/getlga?name=${form.lga}`,
      {},
      (res) => {
        // console.lo
        if (res.results[0] && form.lga.length > 0) {
          let cord = res.results[0];
          if (cord && cord[0]?.point)
            setForm((p) => ({
              ...p,
              latitude: cord[0].point.coordinates[0],
              longitude: cord[0].point.coordinates[1],
              zoom: [10],
            }));
        } else {
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [form.lga]);
  useEffect(() => {
    dt();
  }, [dt]);
  useEffect(() => {
    if (form.geometry.length > 0) {
      setForm((p) => ({
        ...p,
        longitude: form.geometry[0]?.split(",")[0],
        latitude: form.geometry[1]?.split(",")[0],
        zoom: [5],
      }));
    }
  }, [form.geometry]);
  const [crops,setCrops]=useState([])
  const getCrops =()=>{
    _fetchApi(`/messages/getGroupingbYID?org_id=${org_id}&class_id=${1}`,
    (res)=>{
      setCrops(res.results)
    },(err)=>{
      console.log(err)
    }
    )
  }
  const [fType,setFtype]=useState([])
  const getFType =()=>{
    _fetchApi(`/messages/getGroupingbYID?org_id=${org_id}&class_id=${3}`,
    (res)=>{
      setFtype(res.results)
    },(err)=>{
      console.log(err)
    }
    )
  }
  const [scales,setscales]=useState([])
  const getScale =()=>{
    _fetchApi(`/messages/getGroupingbYID?org_id=${org_id}&class_id=${2}`,
    (res)=>{
      setscales(res.results)
    },(err)=>{
      console.log(err)
    }
    )
  }
  useEffect(
  ()=>{
    getFType()
    getCrops()
    getScale()
  },[]
  )

  return (
    <div>
      {/* <CsvConverter /> */}
      {/* {JSON.stringify(form)}
            {JSON.stringify(form.geometry[1]?.split(',')[0])} */}
      <Card className="admin-card p-3">
      {/* {JSON.stringify(scales?.map((i)=>i.group_name))} */}
        <Row className="m-0 p-0">
          <Col md={6}>
            <h4>Create Subscribers</h4>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="Full Name"
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
              />
            </div>
            {/* <div className="mt-3">
                            <input
                                className="input_field p-2"
                                placeholder="Last Name"
                                type="text"
                                name="lname"
                                value={form.lname}
                                onChange={handleChange}
                            />
                        </div> */}
            <div className="mt-3">
              <select
                type="select"
                className="input_field p-2"
                name="state"
                value={form.state}
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, state: value }));
                  let selected = LocalGovernment.find((a) => a.state === value);
                  setLGAs(selected.lgas);
                }}
              >
                <option>Any State</option>
                {LocalGovernment.map((item) => (
                  <option>{item.state}</option>
                ))}
              </select>
            </div>

            <div className="mt-3">
              <select
                type="select"
                className="input_field p-2"
                name="lga"
                value={form.lga}
                onChange={handleChange}
              >
                <option>Any LGA</option>
                {lgas.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="Phone"
                type="number"
                name="phones"
                value={form.phones}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="longitude"
                type="number"
                name="longitude"
                value={form.longitude}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="latitude"
                type="number"
                name="latitude"
                value={form.latitude}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="Community Name"
                type="text"
                name="community"
                value={form.community}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <input
                className="input_field p-2"
                placeholder="Account Number"
                type="number"
                name="account_number"
                value={form.account_number}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <Typeahead
                id="head"
                labelKey="name"
                multiple
                onChange={(val) => {
                  if (val.length) {
                    setForm((p) => ({ ...p, farming_type: val.join(",") }));
                  }
                }}
                options={fType?.map((i)=>i.group_name)}
                placeholder="Farming types"
                name="farming type"
                ref={_ref}
                className="input_field p-2"
              />
            </div>
            <div className="mt-3">
              <Typeahead
                id="head"
                labelKey="name"
                multiple
                onChange={(val) => {
                  if (val.length) {
                    setForm((p) => ({ ...p, crops: val.join(",") }));
                  }
                }}
                options={crops?.map((i)=>i.group_name)}
                placeholder="Crops"
                name="crops"
                ref={_ref3}
                className="input_field p-2"
              />
            </div>
            <div className="mt-3">
              <Typeahead
                id="head"
                labelKey="name"
                multiple
                onChange={(val) => {
                  if (val.length) {
                    setForm((p) => ({ ...p, scale: val.join(",") }));
                  }
                }}
                options={scales?.map((i)=>i.group_name)}
                placeholder="Scale"
                name="scale"
                ref={_ref2}
                className="input_field p-2"
              />
            </div>
            <div className="">
              <input
                type="file"
                className="file_input input_field p-2"
                placeholder="Upload CSV"
                name="file"
                onChange={changeHandlers}
                accept=".csv"
                style={{ display: "block", margin: "10px auto" }}
              />
            </div>
            {/* <div>
                            <button
                                type="submit"
                                className="primary_button mt-4"
                                style={{ width: '100%' }}
                                onClick={handleSubmit}
                            >
                                {loading ? (
                                    <span>Loading...</span>
                                ) : (
                                    <span>
                                        Register
                                    </span>
                                )}
                            </button>
                                </div>*/}
            <button className="new_button" onClick={handleAdd}>
              Add
            </button>
          </Col>
          <Col md={6}>
            <LocationForm
              form={form}
              setForm={setForm}
              handleChange={handleChange}
            />
            <Table striped size="sm" responsive className="mt-3">
              <thead>
                <tr>
                  <th width={"5%"}>S/N</th>
                  <th>Name</th>
                  <th>Phone</th>
                </tr>
              </thead>
              {tableData.map((item, index) => (
                <tbody>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.phone}</td>
                </tbody>
              ))}
            </Table>
            <button
              type="submit"
              className="primary_button mt-4"
              style={{ width: "100%" }}
              onClick={handleSubmit}
            >
              {loading ? <span>Loading...</span> : <span>Register</span>}
            </button>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
